const ChiefReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USER_DATA":
      const user_data = { ...state.user_data, ...action.user_data };
      localStorage.setItem("user_data", JSON.stringify(user_data));
      return {
        ...state,
        user_data: user_data,
      };
    case "UPDATE_ROUTE_DATA":
      const route_data = { ...state.route_data, ...action.route_data };
      localStorage.setItem("route_data", JSON.stringify(route_data));
      return {
        ...state,
        route_data: route_data,
      };
    default:
      return state;
  }
};

export default ChiefReducer;
