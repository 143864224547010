import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import {
  add,
  filterIcon,
  activeGreen,
  inactiveRed,
  plus,
} from "../../../components/assets";
import { useNavigate, Link } from "react-router-dom";
import { Context } from "../../../context/Store";
import { subUserList } from "../../../api/activities";
import Nodata from "../../includes/nodata/Nodata";
import Loader from "../../../components/loader/Loader";
import Swal from "sweetalert2";
import api from "../../../api";

function SubUser() {
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState(true);
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "sub-users",
      },
    });
  }, []);
  useEffect(() => {
    subUserList(config)
      .then((res) => {
        setIsLoading(true);
        if (res.app_data.StatusCode == 6000) {
          setUserList(res.app_data.data);
          setIsLoading(false);
        } else {
          setUserList([]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [render]);
  const confirmDelete = (e, item) => {
    setRender(true);
    Swal.fire({
      title: "Do you want to delete this user?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDelete(e, item);
      }
    });
  };
  const handleDelete = (e, item) => {
    e.preventDefault();
    api.post(`accounts/delete-sub-user/${item.id}/`, {}).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        setRender(false);
      } else {
      }
    });
  };
  return (
    <Container>
      <TopBox>
        <Title>Sub User</Title>
        <Add to="/create-user">
          <Icon>
            <img src={plus} alt="icon" />
          </Icon>
          <span>Add User</span>
        </Add>
      </TopBox>
      <div className="parent-scroll">

      <ListContainer className="scroll">
        <HeadRow>
          <HeadColumnData>Sl No</HeadColumnData>
          <HeadColumnData>Name</HeadColumnData>
          <HeadColumnData>Password</HeadColumnData>
          <HeadColumnData>Privilege</HeadColumnData>
          <HeadColumnData>Online or Offline</HeadColumnData>
          <HeadColumnData></HeadColumnData>
        </HeadRow>
        <BottomContainer>
          {isLoading ? (
            <Loader />
          ) : userList.length == 0 ? (
            <Nodata />
          ) : (
            userList?.map((row, index) => (
              <Row key={index}>
                <ColumnData>{index + 1}</ColumnData>
                <ColumnData>{row.name}</ColumnData>
                <ColumnData>{row.password}</ColumnData>
                <ColumnData>
                  {row.privilages.map((c, index) => (
                    <>
                      <span key={index}>{c}</span>
                      <span>
                        {index != row?.privilages?.length - 1 ? ", " : ""}
                      </span>
                    </>
                  ))}
                </ColumnData>
                {row.is_online ? (
                  <ColumnData className="flex-box">
                    <img src={activeGreen} alt="Active" />
                    <span className="activeColumn">Online</span>
                  </ColumnData>
                ) : (
                  <ColumnData className="flex-box">
                    <img src={inactiveRed} alt="Inactive" />
                    <span className="activeColumn">Offline</span>
                  </ColumnData>
                )}
                <ColumnData
                  className="flex-end"
                  onClick={(e) => confirmDelete(e, row)}
                >
                  <i class="ri-delete-bin-line"></i>
                </ColumnData>
              </Row>
            ))
          )}
        </BottomContainer>
      </ListContainer>
      </div>
    </Container>
  );
}

export default SubUser;

const Container = styled.div`
  padding: 20px;
`;
const TopBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h2`
  color: #0a0a0a;
  /* margin-bottom: 20px; */
  font-size: 26px;
`;

const Add = styled(Link)`
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #2382d4;
  color: #2382d4;
  /* width: 120px; */
  height: 35px;
  display: flex;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
`;
const Icon = styled.div`
  margin-right: 10px;
  img {
    display: block;
  }
`;

const NavButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Button = styled.div`
  /* width:48px;
    height:26px; */
  background: #fff;
  color: #2382d4;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 16px;
  cursor: pointer;

  &.background {
    background-color: #2382d4;
    color: #fff;
    margin-left: 15px;
  }

  img {
    margin-right: 10px;
  }
`;

const BottomContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow-y: scroll;
`;

const ListContainer = styled.div`
  background-color: #fff;
  border: 1px solid #dfe8ed;
  border-radius: 8px;

  h3 {
    color: #747474;
    font-family: "inter_regular";
    font-size: 22px;
  }
`;

const HeadRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: 1px solid #ccc;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: 1px solid #ccc;
`;

const HeadColumnData = styled.div`
  text-align: left;
  color: #747474;
  font-size: 18px;
  font-family: "inter_regular";
  width: 15%;
  /* &:first-child {
    width: 7%;
  }
  &:nth-child(3) {
    width: 25%;
  }
  &:last-child {
    text-align: right;
  } */
`;
const ColumnData = styled.div`
  text-align: left;
  color: #0a0a0a;
  font-size: 18px;
  font-family: "inter_regular";
  width: 15%;

  span {
    font-family: "inter_regular";
  }

  &.flex-box {
    display: flex;
    justify-content: flex-start;
  }
  /* &:first-child {
    width: 7%;
  }
  &:nth-child(3) {
    width: 25%;
  }
  &:last-child {
    text-align: right;
  } */
  &.light {
    font-size: 12px;
    color: #7e7e7e;
  }

  img {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }

  .activeColumn {
    display: inline-block;
  }
  &.flex-end {
    display: flex;
    justify-content: center;
    color: #cb4f4f;
    cursor: pointer;
  }
`;
