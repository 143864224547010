import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { Context } from "../../../context/Store";
import ButtonLoader from "../../../components/button-loader/ButtonLoader";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../api";
import NewTagModal from "./NewTagModal";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import TagHistory from "./Taghistory";
import { getDDMMYY, getYYMMDD } from "../../../utils/functions/function";
function AddInsurance({ setAdd, id, type }) {
  //state
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [isEmiEnabled, setIsEmiEnabled] = useState(false);

  const navigate = useNavigate();

  const handleEmiToggle = () => {
    setIsEmiEnabled(!isEmiEnabled);
  };

  const [general, setGeneral] = useState({
    dateOfInsurance: new Date(),
    tagNo: "",
    identificationMark: "",
    height: "",
    race: "",
    age: "",
  });

  const [getYield, setYield] = useState({
    am: "",
    pm: "",
    pdMonth: "",
    marketValue: "",
    premiumAmount: "",
    claimAmount: "",
    expiryDate: new Date(),
  });

  const [societyId, setSocietyId] = useState();
  const [getSocieties, setSocieties] = useState([]);
  const [getFarmerId, setFarmerId] = useState();
  const [noOfEmi, setNoEmi] = useState("");
  const [getFarmers, setFarmers] = useState([]);
  const [getCows, setCows] = useState([]);
  const [getCowId, setCowId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [active, setActive] = useState(type || "all");
  const [isTagModal, setTagModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const onToggle = () => {
    setIsToggled(!isToggled);
  };
  const ModalTabClick = () => {
    setIsModalOpen(true);
  };

  // get accesstoken from store
  const {
    state: { user_data, route_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const path = window.location.pathname;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const showSuccessModal = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Insurance Created Successfully",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  //payload
  const formData = new FormData();
  formData.append("society_id", societyId);
  formData.append("farmer", getFarmerId);
  formData.append("cow", getCowId);

  formData.append("date_of_insurance", getYYMMDD(general.dateOfInsurance) );
  formData.append("tag_no", general.tagNo);
  formData.append("identification_mark", general.identificationMark);
  formData.append("height", general.height);
  formData.append("race", general.race);
  formData.append("age", general.age);

  formData.append("am_value", getYield.am);
  formData.append("pm_value", getYield.pm);
  formData.append("market_value", getYield.marketValue);
  formData.append("premium_amount", getYield.premiumAmount);
  formData.append("claim_amount", getYield.claimAmount);
  formData.append("pd_month", getYield.pdMonth);
  formData.append("expiry_date_of_insurance",getYYMMDD(getYield.expiryDate) );

  formData.append("is_emi", isEmiEnabled ? "True" : "False");
  if (isEmiEnabled) {
    formData.append("no_of_emi", parseInt(noOfEmi));
  }
  //creating insurance
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    if (societyId == "") {
      setErrorMessage("Please select a Society");
      setIsButtonLoading(false);
    }
    else if (getFarmerId == "") {
      setErrorMessage("Please select a Farmer");
      setIsButtonLoading(false);
    }
    else if (getCowId == "") {
      setErrorMessage("Please select a cow");
      setIsButtonLoading(false);
    }
    else{
      api.post("activities/create-insurance/", formData, {}).then((res) => {
        if (res.app_data.StatusCode == 6000) {
          showSuccessModal();
          setIsButtonLoading(false);
          navigate("/insurance");
        } else {
          setIsButtonLoading(false);
          setErrorMessage(res.app_data.data.message);
        }
      });
    }
  };

  const handleTabClick = () => {
    // Open the modal when the "New Tag" tab is clicked
    setIsModalOpen(true);
  };

  //scoiety listing
  useEffect(() => {
    api
      .get(`accounts/societies/`, {
        params: {
          list_by: "all",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode === 6000) {
          setSocieties(res.app_data.data);
          setSocietyId(res.app_data.data[0].id)
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setSocieties([]);
          setSocietyId("")
        }
      });
  }, []);

  //farmer listing
  useEffect(() => {
    societyId &&
      api
        .get(`accounts/society-farmer-list/${societyId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.app_data.StatusCode === 6000) {
            setFarmers(res.app_data.data);
            setFarmerId(res.app_data.data[0].id)
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setFarmers([]);
            setFarmerId("")
          }
        });
  }, [societyId]);

  //cow listing
  useEffect(() => {
    getFarmerId &&
      api
        .get(`accounts/farmer-cow-list/${getFarmerId}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.app_data.StatusCode === 6000) {
            setCows(res.app_data.data);
            setCowId(res.app_data.data[0].id)
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setCows([]);
            setCowId("")
          }
        });
  }, [getFarmerId]);
  return (
    <>
      <Title>Add Insurance</Title>
      <RouteInfo>
        <CoverInfo>
          {/* <RouteTop>
        <TitleRout>Create Rout</TitleRout>
      </RouteTop> */}
          <Form onSubmit={(e) => handleSubmit(e)}>
            <General>General</General>
            <CoverForm>
              <CoverIn>
                <Label>Select Society*</Label>
                <Select
                  required={!societyId}
                  value={societyId}
                  onChange={(e) => setSocietyId(e.target.value)}
                >
                  {getSocieties.length > 0 ?
                    getSocieties?.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}{" "}
                      </option>
                    ))
                    :
                    <option>No Societies found</option>
                  }
                </Select>
              </CoverIn>
              <CoverIn>
                <Label>Society Farmer*</Label>
                <Select
                  required
                  value={getFarmerId}
                  onChange={(e) => setFarmerId(e.target.value)}
                >
                  {getFarmers.length > 0 ?
                  getFarmers.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))
                  :
                  <option>No Farmers found</option>
                }
                </Select>
              </CoverIn>
            </CoverForm>
            <CoverForm>
              <CoverinForm>
                <Label>Select Cow*</Label>
                <Select
                  required
                  value={getCowId}
                  onChange={(e) => setCowId(e.target.value)}
                >
                  {getCows.length > 0 ? 
                  getCows.map((item, index) => (
                    <option value={item.id}>{item.name}</option>
                  ))
                  :
                  <option>No Cows found</option>
                }
                </Select>
              </CoverinForm>
            </CoverForm>
            <CoverForm>
              <CoverIn>
                <Label>Date of Insurance</Label>
                {/* <Input
                  type="date"
                  required
                  name="date_of_insurance"
                  value={general.dateOfInsurance}
                  onChange={(e) =>
                    setGeneral(() => ({
                      ...general,
                      dateOfInsurance: e.target.value,
                    }))
                  }
                /> */}
                <InputBox>
                  <DatePicker
                    className="insurance"
                    value={
                      general.dateOfInsurance
                        ? getDDMMYY(general.dateOfInsurance)
                        :  "DD-MM-YYYY"
                    }
                    dateFormat="dd-MM-yy"
                    onChange={(date) =>
                      setGeneral(() => ({
                        ...general,
                        dateOfInsurance: date,
                      }))
                    }
                    required
                    name="date_of_insurance"
                    // dateFormat="dd-mm-yy"
                  />
                </InputBox>
              </CoverIn>
              <CoverIn>
                <Label>Tag No</Label>
                <Input
                  type="text"
                  required
                  value={general.tagNo}
                  onChange={(e) =>
                    setGeneral(() => ({
                      ...general,
                      tagNo: e.target.value,
                    }))
                  }
                />
              </CoverIn>
            </CoverForm>
            {getCowId.length > 0 && (
              <Tabs>
                <Tab
                  onClick={() => {
                    setActive("taghistory");
                    setTagModal(true);
                  }}
                  className={active === "taghistory" && "active"}
                >
                  Tag History
                </Tab>
                <Tab
                  to={{
                    pathname: "",
                  }}
                  onClick={handleTabClick}
                  className={active === "newtag" && "active"}
                >
                  New Tag
                </Tab>
              </Tabs>
            )}
            <CoverButton>
              <CoverinButton>
                <Label>Identification Mark</Label>
                <Input
                  required
                  type="text"
                  value={general.identificationMark}
                  onChange={(e) =>
                    setGeneral(() => ({
                      ...general,
                      identificationMark: e.target.value,
                    }))
                  }
                />
              </CoverinButton>
            </CoverButton>
            <CoverForm>
              <CoverIn>
                <Label>Height(CM)</Label>
                <Input
                  type="number"
                  required
                  value={general.height}
                  onChange={(e) =>
                    setGeneral(() => ({
                      ...general,
                      height: e.target.value,
                    }))
                  }
                />
              </CoverIn>
              <CoverIn>
                <Label>Race</Label>
                <Input
                  required
                  type="text"
                  value={general.race}
                  onChange={(e) =>
                    setGeneral(() => ({
                      ...general,
                      race: e.target.value,
                    }))
                  }
                />
              </CoverIn>
            </CoverForm>
            <CoverButton>
              <CoverinButton>
                <Label>Age</Label>
                <Input
                  type="number"
                  required
                  value={general.age}
                  onChange={(e) =>
                    setGeneral(() => ({
                      ...general,
                      age: e.target.value,
                    }))
                  }
                />
              </CoverinButton>
            </CoverButton>
            <General className="veh">Yield </General>
            <CoverForm>
              <CoverIn>
                <Label>AM</Label>
                <Input
                  required
                  type="text"
                  value={getYield.am}
                  onChange={(e) =>
                    setYield(() => ({
                      ...getYield,
                      am: e.target.value,
                    }))
                  }
                />
              </CoverIn>
              <CoverIn>
                <Label>PM</Label>
                <Input
                  type="text"
                  // className="auto"
                  required
                  value={getYield.pm}
                  onChange={(e) =>
                    setYield(() => ({
                      ...getYield,
                      pm: e.target.value,
                    }))
                  }
                />
              </CoverIn>
            </CoverForm>
            <CoverButton>
              <CoverinButton>
                <Label>PD Month</Label>
                <Input
                  type="text"
                  required
                  value={getYield.pdMonth}
                  onChange={(e) =>
                    setYield(() => ({
                      ...getYield,
                      pdMonth: e.target.value,
                    }))
                  }
                />
              </CoverinButton>
            </CoverButton>
            <CoverForm>
              <CoverIn>
                <Label>Market Value(RS)</Label>
                <Input
                  type="number"
                  required
                  value={getYield.marketValue}
                  onChange={(e) =>
                    setYield(() => ({
                      ...getYield,
                      marketValue: e.target.value,
                    }))
                  }
                />
              </CoverIn>
              <CoverIn>
                <Label>Premium Amount (Rs)</Label>
                <Input
                  type="number"
                  // className="auto"
                  required
                  value={getYield.premiumAmount}
                  onChange={(e) =>
                    setYield(() => ({
                      ...getYield,
                      premiumAmount: e.target.value,
                    }))
                  }
                />
              </CoverIn>
            </CoverForm>
            <CoverButton>
              <CoverinButton>
                <Label>Claim Amount (Rs)</Label>
                <Input
                  type="text"
                  required
                  value={getYield.claimAmount}
                  onChange={(e) =>
                    setYield(() => ({
                      ...getYield,
                      claimAmount: e.target.value,
                    }))
                  }
                />
              </CoverinButton>
            </CoverButton>
            <CoverinButton>
              <Label>Expiry Date</Label>
              <InputBox>
                <DatePicker
                  className="insurance"
                  value={
                    getYield.expiryDate
                      ? getDDMMYY(getYield.expiryDate)
                      : "DD-MM-YYYY"
                  }
                  dateFormat="dd-MM-yy"
                  onChange={(date) =>
                    setYield(() => ({
                      ...getYield,
                      expiryDate: date,
                    }))
                  }
                  required
                  // dateFormat="dd-mm-yy"
                />
              </InputBox>
            </CoverinButton>
            <CoverButtonEmi>
              <Emi>
                <h6>Enable EMI Option</h6>
                <ToggleSwitchWrapper>
                  <HiddenCheckbox
                    name="no_of_emi"
                    checked={isEmiEnabled}
                    onChange={handleEmiToggle}
                  />
                  <Switch />
                </ToggleSwitchWrapper>
              </Emi>
            </CoverButtonEmi>
            <EmiEnabled enabled={isEmiEnabled}>
              <InputLabelemi>No of EMI</InputLabelemi>
              <Input
                type="text"
                required={isEmiEnabled}
                name="no_of_emi_text"
                value={noOfEmi}
                onChange={(e) => setNoEmi(e.target.value)}
              />
            </EmiEnabled>
            <CoverForm></CoverForm>{" "}
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <Buttons>
              <Button className="cancel" onClick={() => navigate("/insurance")}>
                Cancel
              </Button>
              {isButtonLoading ? (
                <Button>
                  <ButtonLoader />
                </Button>
              ) : (
                <SubmitButton type="submit" value="Create" />
              )}
            </Buttons>
          </Form>
          <NewTagModal
            isOpen={isModalOpen}
            onClose={setIsModalOpen}
            cowId={getCowId}
          />
          <TagHistory
            isOpen={isTagModal}
            onClose={setTagModal}
            cowId={getCowId}
          />
        </CoverInfo>
      </RouteInfo>
    </>
  );
}
export default AddInsurance;

const RouteInfo = styled.div`
  background-color: #fff;
  margin-top: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  padding: 30px;
  width: 70%;
  margin: 0 auto;
  @media (max-width: 1080px) {
    width: 95%;
  }
`;

const Title = styled.h2`
  color: #0a0a0a;
  margin-bottom: 30px;
  font-size: 26px;
  width: 70%;
  margin: 0 auto;
  margin-top: 34px;
  padding-bottom: 34px;
  @media (max-width: 1080px) {
    width: 95%;
  }
`;
const Button = styled.div`
  width: 170px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.cancel {
    background-color: #fff;
    border: 1px solid #2382d4;
    color: #2382d4;
    margin-right: 20px;
  }
`;
const SubmitButton = styled.input`
  width: 170px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.cancel {
    background-color: #fff;
    border: 1px solid #2382d4;
    color: #2382d4;
    margin-right: 20px;
  }
`;
const InputBox = styled.div`
  background-color: #f4f5f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #dfe8ed;
  position: relative;
  input {
    background-color: transparent;
    width: 100%;
    font-size: 18px;
  }
`;
const Form = styled.form``;
const ErrorMessage = styled.span`
  color: red;
  margin-bottom: 5px;
  text-align: right;
  width: 100%;
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
`;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const CoverInfo = styled.div``;
const General = styled.h3`
  color: #0a0a0a;
  position: relative;
  overflow: hidden;
  font-size: 22px;
  margin-bottom: 30px;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 100px;
    background-color: #ebebeb;
    bottom: 10px;
  }
  &.veh {
    margin-top: 30px;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 260px;
      bottom: 10px;
      background-color: #ebebeb;
    }
  }
  &.bank {
    margin-top: 30px;
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      right: 0px;
      bottom: 10px;
      background-color: #ebebeb;
      left: unset;
      width: 80%;
    }
  }
  &.other {
    margin-top: 30px;
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      right: 0px;
      bottom: 10px;
      background-color: #ebebeb;
      left: unset;
      width: 75%;
    }
  }
`;
const CoverForm = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;
const CoverButton = styled.div``;

const CoverIn = styled.div`
  width: 50%;
  margin-right: 20px;
  margin-bottom: 30px;
  &:nth-child(even) {
    margin-right: 0;
  }
  position: relative;
  &.add {
    width: 48%;
    margin-right: 0;
  }
`;
const CoverinForm = styled.div`
  width: 100%;
  /* margin-right: 20px; */
  margin-bottom: 30px;
  &:nth-child(even) {
    margin-right: 0;
  }
  position: relative;
  &.add {
    width: 48%;
    margin-right: 0;
  }
`;
const Label = styled.h3`
  color: #747474;
  font-family: "inter_regular";
  margin-bottom: 10px;
`;
const Input = styled.input`
  width: 100%;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  height: 60px;
  font-size: 18px;
  padding-left: 20px;
  border-radius: 5px;
  &.auto {
    color: #adadad;
  }
  @media (max-width: 540px) {
    font-size: 14px;
  }
`;
const Select = styled.select`
  width: 100%;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  height: 60px;
  font-size: 18px;
  padding-left: 20px;
  border-radius: 5px;
  outline: none;
  &.auto {
    color: #adadad;
  }
  @media (max-width: 540px) {
    font-size: 14px;
  }
`;
const Icon = styled.div`
  color: #292c2d;
  position: absolute;
  right: 20px;
  top: 53px;
  cursor: pointer;
  @media all and (max-width: 400px) {
    right: 20px;
    top: 38px;
  }
`;
const CoverinButton = styled.div`
  width: 100%;
  margin-right: 20px;
  margin-bottom: 30px;
  &:nth-child(even) {
    margin-right: 0;
  }
  position: relative;
  &.add {
    width: 48%;
    margin-right: 0;
  }
`;
const Tab = styled(Link)`
  border-top: 1px solid #dfe8ed;
  text-decoration: none;
  border-left: 1px solid #dfe8ed;
  border-right: 1px solid #dfe8ed;
  border-bottom: 1px solid #dfe8ed;
  padding: 9px 20px;
  cursor: pointer;
  margin-right: 30px;
  color: #797d82;
  border-radius: 0px 1px 0 0;
  background-color: #f4f5f8;
`;
const Tabs = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const TagForm = styled.div``;
const ModalWrapper = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
`;

const ModalHeader = styled.h3`
  color: #0a0a0a;
  margin-bottom: 20px;
  font-size: 22px;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
`;

const InputData = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
`;

const ConfirmButton = styled.button`
  background-color: #2382d4;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
`;
const CoverButtonEmi = styled.div`
  display: flex;
  align-items: center;
  gap: 168px;
  margin-top: 56px;
`;
const Emi = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  h6 {
    color: #747474;
    font-family: "inter_regular";
    font-size: 18px;
    margin-right: 16px;
  }
`;

const ToggleSwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
`;
const EmiEnabled = styled.div`
  display: ${({ enabled }) => (enabled ? "flex" : "none")};
  align-items: center;
  margin-bottom: 30px;
  gap: 30px;
`;

const InputLabelemi = styled.h3`
  color: #747474;
  font-family: "inter_regular";
  margin-bottom: 10px;
  white-space: nowrap;
`;
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  /* visibility: hidden; */
`;

const Switch = styled.span`
  position: absolute;
  cursor: pointer;
  background-color: #fff;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: -5px;
  transition: background-color 0.2s ease;
  border: 1px solid #000;

  &:before {
    content: "";
    position: absolute;
    left: 2px;
    top: 1px;
    width: 21px;
    height: 21px;
    background-color: #3c3c3c;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  ${HiddenCheckbox}:checked + &::before {
    transform: translateX(28px);
    background-color: #000;
  }

  ${HiddenCheckbox}:checked + & {
    background-color: #e4e4e4;
  }
`;
