import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import {
  build,
  calendar,
  cow,
  curve,
  dollar,
  farmers,
  left_arrow,
  milk,
  payment,
  r_build,
  right_arrow,
  user,
} from "../../../../../components/assets";
import { payment_history, routes } from "../../../../../utils/arrays";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RouteFarmerSingle } from "../../../../../api/auth";
import { Context } from "../../../../../context/Store";
import FarmerEdit from "../../../farmer-edit/FarmerEdit";
import ComingSoon from "../../../../includes/comingsoon/ComingSoon";
import FarmerPaymentList from "../../../farmers/payment";

function PlantSocietyFarmerSinglePage() {
  const [getFarmerData, setGetFarmerData] = useState();
  const [isInputDisabled, setInputDisabled] = useState(true);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  // get accesstoken from store
  const {
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { id } = useParams();
  // connection
  useEffect(() => {
    RouteFarmerSingle(config, id).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        setGetFarmerData(res.app_data.data);
      }
    });
  }, [isInputDisabled]);

  return (
    <div>
      <MainContainer>
        <TopBar>
          <TopCover>
            <Arrow onClick={goBack}>
              <img src={left_arrow} alt="image" />
            </Arrow>
            <Text>
              <TopTitle>{getFarmerData?.farmer}</TopTitle>
              <RoutePath>
                Routes / Route no : 0001 / Societies / Society 1
              </RoutePath>
            </Text>
          </TopCover>
          <Curve>
            <img className="topcurve" src={curve} alt="" />
          </Curve>
        </TopBar>
        <Bottom>
          {/* ----------------todays milk quantity-----------------------  */}
          <Container>
            <CoverCard>
              <Icon>
                <img src={milk} alt="icon" />
              </Icon>
              <Title>Todays Milk Quantity</Title>
            </CoverCard>
            <BottomCover>
              <Cover>
                <span>AM</span>
                <Span>
                  <span>{getFarmerData?.farmer_milk_am}</span> Litres
                </Span>
              </Cover>
              <Cover>
                <span>PM</span>
                <Span>
                  <span>{getFarmerData?.farmer_milk_pm}</span> Litres
                </Span>
              </Cover>
            </BottomCover>

            <Curve>
              <img src={curve} alt="image" />
            </Curve>
          </Container>
          {/* -------------Todays milk quality---------------- */}
          <Container>
            <CoverCard>
              <Icon>
                <img src={milk} alt="icon" />
              </Icon>
              <Title>Todays Milk Quality</Title>
            </CoverCard>
            <BottomCover>
              <Cover>
                <span>CLR</span>
                <Span>
                  <span>{getFarmerData?.average_clr_value.toFixed(2)}</span>
                </Span>
              </Cover>
              <Cover>
                <span>FAT</span>
                <Span>
                  <span>{getFarmerData?.fat_average_value.toFixed(2)}</span>
                </Span>
              </Cover>
              <Cover>
                <span>SNF</span>
                <Span>
                  <span>{getFarmerData?.snf_average_value.toFixed(2)}</span>
                </Span>
              </Cover>
            </BottomCover>

            <Curve>
              <img src={curve} alt="image" />
            </Curve>
          </Container>
          {/* ------------------Cows--------------- */}
          <Container>
            <CoverCard>
              <Icon>
                <img src={milk} alt="icon" />
              </Icon>
              <Title>Cows</Title>
            </CoverCard>
            <BottomCover>
              <Cover>
                <Span>
                  <span>{getFarmerData?.number_of_cows}</span>
                </Span>
                <span>Total Cows</span>
              </Cover>
              <Cover>
                <Span>
                  <span>{getFarmerData?.insured_cow_count}</span>
                </Span>
                <span>Insured Cows</span>
              </Cover>
            </BottomCover>

            <Curve>
              <img src={curve} alt="image" />
            </Curve>
          </Container>
          <Average>
            <AvgCard>
              <TextAvg>
                Average milk <br /> quantity
              </TextAvg>
              <AvgCount>
                <span>{getFarmerData?.avg_milk_quantity.toFixed(2)}</span>{" "}
                Litres
              </AvgCount>
              <Curve>
                <img src={curve} alt="" />
              </Curve>
            </AvgCard>
            <AvgCard>
              <TextAvg>Average quality readings</TextAvg>
              <ContAvg>
                <Cover>
                  <span>CLR</span>
                  <Span>
                    <span>{getFarmerData?.avg_total_clr_value.toFixed(2)}</span>
                  </Span>
                </Cover>
                <Cover>
                  <span>FAT</span>
                  <Span>
                    <span>{getFarmerData?.avg_total_fat_value.toFixed(2)}</span>
                  </Span>
                </Cover>
                <Cover>
                  <span>SNF</span>
                  <Span>
                    <span>{getFarmerData?.avg_total_snf_value.toFixed(2)}</span>
                  </Span>
                </Cover>
              </ContAvg>
              <Curve>
                <img src={curve} alt="" />
              </Curve>
            </AvgCard>
          </Average>
        </Bottom>
        <CoverBottom>
          <RouteInfo>
            {/* --------------------------------Farmer edit------------------- */}
            <FarmerEdit
              getFarmerData={getFarmerData}
              isInputDisabled={isInputDisabled}
              setInputDisabled={setInputDisabled}
            />
            {/* ---------------------------------------------------------------- */}
          </RouteInfo>
          <PaymentSection>
            <PaymentTop>
              <TitlePayment>Payment History</TitlePayment>
              {/* <TitleView to={`/total-societies/${"01"}/payments`}> */}
              {/* <TitleView>
              View all
              <ArrowIcon>
                <img src={right_arrow} alt="right-arrow" />
              </ArrowIcon>
            </TitleView> */}
            </PaymentTop>
            {/* {payment_history.map((item, index) => (
            <PayCard key={index}>
              <PayLeft>
                <PayIcon>
                  <img src={payment} alt="icon" />
                </PayIcon>
                <CoverAmount>
                  <AmountLabel>Amount</AmountLabel>
                  <Amount>{item.amount}</Amount>
                </CoverAmount>
              </PayLeft>
              <PayRight>
                <Date>
                  <span>{item.date}</span>
                  <CoverIcon>
                    <img src={calendar} alt="image" />
                  </CoverIcon>
                </Date>
                <DollarIcon>
                  <span>Bank</span>
                  <CoverIcon>
                    <img src={dollar} alt="image" />
                  </CoverIcon>
                </DollarIcon>
              </PayRight>
            </PayCard>
          ))} */}
            <PaymentCover>
              <FarmerPaymentList />
            </PaymentCover>
          </PaymentSection>
        </CoverBottom>
      </MainContainer>
    </div>
  );
}

export default PlantSocietyFarmerSinglePage;
const PaymentCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MainContainer = styled.div`
  padding: 20px;
`;
const TopBar = styled.div`
  padding: 20px;
  background-color: #fff;
  position: relative;
  border: 1px solid #dfe8ed;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
  flex-wrap: wrap;
  gap: 16px;
`;
const TopCover = styled.div`
  display: flex;
`;
const Arrow = styled.div`
  border: 1px solid #2382d4;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const ArrowIcon = styled.div`
  margin-left: 5px;
`;
const Text = styled.div``;
const PaymentSection = styled.div`
  background-color: #fff;
  width: 50%;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  padding: 30px;
  @media (min-width: 640px) and (max-width: 820px) {
    width: 100%;
  }
`;
const CoverBottom = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start; */
  margin-top: 20px;
  @media (max-width: 820px) {
    flex-direction: column;
    gap: 40px;
  }
`;
const TopTitle = styled.h2`
  color: #0a0a0a;
  margin-bottom: 5px;
`;
const RoutePath = styled.h4`
  color: #747474;
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  img {
    width: 100%;
    display: block;
  }
  .topcurve {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
const Bottom = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  @media (min-width: 769px) and (max-width: 1080px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
`;

const Container = styled.div`
  position: relative;
  background-color: #fff;
  padding: 20px 30px;
  width: 100%;
  z-index: 1;

  /* height: 250px; */
  margin-right: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  &:last-child {
    margin-right: 0;
  }
  h1 {
    color: #2382d4;
    font-size: 40px;
    margin-right: 10px;
  }
  h3 {
    color: #2382d4;
    font-family: "inter_regular";
    font-size: 22px;
  }
`;
const Icon = styled.div`
  width: 40px;
  margin-right: 10px;
  img {
    width: 100%;
    display: block;
  }
`;
const Title = styled.h4`
  color: #0a0a0a;
  font-family: "inter_regular";
  font-weight: unset;
  font-size: 20px;
`;
const Cover = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 50px;
  &:last-child {
    margin-right: 0;
  }
  span {
    color: #747474;
    font-family: "inter_regular";
  }
`;
const CoverCard = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  z-index: 1;

  margin-bottom: 20px;
`;
const View = styled(Link)`
  color: #2382d4;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  position: relative;
  display: inline-block;
  font-size: 20px;
  img {
    margin-left: 5px;
  }
  &::before {
    position: absolute;
    bottom: 0;
    content: "";
    background: #2382d4;
    width: 100%;
    height: 1px;
  }
`;
const RouteInfo = styled.div`
  background-color: #fff;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  padding: 30px;
  width: 60%;
  margin-right: 20px;
  @media (max-width: 820px) {
    width: 100%;
  }
`;
const RouteTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;
const TitleRout = styled.h2`
  font-size: 22px;
  color: #0a0a0a;
`;
const Button = styled.div`
  width: 120px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Form = styled.form``;
const CoverForm = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;
const CoverIn = styled.div`
  width: 50%;
  margin-right: 20px;
  margin-bottom: 30px;
  &:nth-child(even) {
    margin-right: 0;
  }
`;
const Label = styled.h3`
  color: #747474;
  font-family: "inter_regular";
  margin-bottom: 10px;
`;
const Input = styled.input`
  width: 100%;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  height: 60px;
  font-size: 18px;
  padding-left: 20px;
  border-radius: 5px;
`;
const CoverInfo = styled.div`
  /* width: 65%; */
`;
const TitleSection = styled.div`
  display: flex;
  align-items: center;
`;
const IconSection = styled.div`
  margin-right: 10px;
`;
const CoverTitleSec = styled.div`
  /* display: flex;
  align-items: center; */
`;
const Sub = styled.div`
  color: #747474;
  margin-top: 5px;
  font-family: "inter_regular";
  font-size: 16px;
  span {
    font-family: "inter_medium";
    color: #2382d4;
  }
`;
const PaymentTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const AmountLabel = styled.h3`
  color: #747474;
  font-family: "inter";
`;
const Amount = styled.h2`
  color: #0a0a0a;
  font-size: 26px;
`;
const TitlePayment = styled.h2``;
const TitleView = styled(Link)`
  display: flex;
  color: #0a0a0a;
  text-decoration: none;
`;
const PayIcon = styled.div`
  margin-right: 10px;
`;
const PayCard = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
`;
const PayLeft = styled.div`
  display: flex;
`;
const CoverAmount = styled.div``;
const PayRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;
const Date = styled.div`
  display: flex;
  span {
    display: block;
    font-size: 14px;
    color: #747474;
    font-family: "inter_regular";
  }
`;
const CoverIcon = styled.div`
  margin-left: 10px;
`;
const DollarIcon = styled.div`
  display: flex;
  span {
    display: block;
    color: #747474;
    font-family: "inter_regular";
    font-size: 14px;
  }
`;
const BottomCover = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Span = styled.h2`
  font-size: 20px;
  span {
    font-size: 28px;
    font-family: "inter_medium";
    color: #2382d4;
  }
  color: #2382d4;
  margin-top: 10px;
`;
const Average = styled.div`
  z-index: 1;
`;
const AvgCard = styled.div`
  display: flex;
  border: 1px solid #dfe8ed;
  padding: 20px;
  border-radius: 5px;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  margin-bottom: 20px;
`;
const AvgCount = styled.div`
  color: #2382d4;

  span {
    font-size: 28px;
    font-family: "inter_medium";
    color: #2382d4;
  }
`;
const TextAvg = styled.div`
  margin-right: 20px;
`;
const ContAvg = styled.div`
  display: flex;
`;
