import React from "react";
import text from "../../../components/assets/images/text.svg";
import gif from "../../../components/assets/images/Devices.gif";
import styled from "styled-components";

export default function Mobile() {
  return (
    <Container>
      <Cover>
        <Text>
          <img src={text} alt="" />
        </Text>
        <Gif>
          <img src={gif} alt="" />
        </Gif>
      </Cover>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Text = styled.div`
  img {
    display: block;
    width: 100%;
  }
  margin: 0 auto;
  width: 80%;
  @media (max-width: 480px) {
  }
`;
const Gif = styled.div`
  img {
    display: block;
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 80%;
    margin: 0 auto;
  }
`;
const Cover = styled.div``;
