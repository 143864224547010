import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { action, curve, left_arrow, plus } from "../../../../components/assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../../context/Store";
import CreateSociety from "../../creation/CreateSociety";
import { SocietiesListView } from "../../../../api/auth";
import Nodata from "../../../includes/nodata/Nodata";
import Loader from "../../../../components/loader/Loader";
import ReactPaginate from "react-paginate";
import { SearchInput } from "../../../../components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { getPaymentList, getPaymetList } from "../../../../api/payments";
import api from "../../../../api";
import { getDateStr, getYYMMDD } from "../../../../utils/functions/function";
import ButtonLoader from "../../../../components/button-loader/ButtonLoader";

function CompletedPayments() {
  const [isCopied, setCopied] = useState("");
  const [getSocieties, setGetSocieties] = useState();
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentList, setPaymentList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEnddate] = useState("");
  const [isButtonLoading, setButtonLoading] = useState(false);
  const currentDate = new Date();

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }
  const startIndex = (currentPage - 1) * 8;
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;

  const handleDownload = async () => {
    setButtonLoading(true);
    try {
      const apiUrl = `https://api.jeevamilk.com/api/v1/payments/print-plant-payment-list/?from_date=${getYYMMDD(
        startDate
      )}&to_date=${getYYMMDD(endDate)}`;

      // from_date
      // to_date params

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setButtonLoading(false);

      if (!response.ok) {
        setButtonLoading(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Extract the blob from the response
      const blob = await response.blob();

      // Create a temporary URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute of the link to the URL of the Blob
      link.href = url;

      // Set the download attribute with the desired file name
      link.download = "completed-payments-report";

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      // Revoke the URL to release the resources
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      setButtonLoading(false);
    }
  };
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "payments-report",
      },
    });
  }, []);
  const customStyles = {
    width: "100px",
    height: "30px",
    borderRadis: "8px",
    // Add any other custom styles here if needed
  };
  const CustomInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      style={{ width: "100px", height: "30px" }}
    />
  );

  useEffect(() => {
    setIsLoading(true);
    api
      .get("payments/completed-payments/", {
        params: {
          from_date: getYYMMDD(startDate),
          to_date: getYYMMDD(endDate),
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setPaymentList(res.app_data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setPaymentList([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      })
  }, [startDate, endDate]);
  return (
    <MainContainer>
      <>
        <Top>
          <h3>Payments Report</h3>
          <TopRight>
            {
              !startDate && !endDate &&
              <DateContainer>{getDateStr(currentDate)}</DateContainer>
            }
            <ButContainer>From</ButContainer>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              required
              dateFormat="dd-MM-yyyy"
              customInput={<CustomInput />}
              style={customStyles}
            />
            <ButContainer>To</ButContainer>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEnddate(date)}
              dateFormat="dd-MM-yyyy"
              required
              minDate={startDate}
              customInput={<CustomInput />}
              style={customStyles}
            />
            <FilterButton onClick={() => handleDownload()}>
            {
              isButtonLoading ?
              "Loading..."
              :
              "Print"

            }
            </FilterButton>
          </TopRight>
        </Top>
        <TopTitle>Society Payment Report</TopTitle>
        <CoverLi>
          <SocietyList>
            <CoverList>
              <ListLabels>
                <LabelName type="all">Sl No.</LabelName>
                <LabelName type="id"> Society Name</LabelName>
                <LabelName type="id"> Date</LabelName>
                <LabelName type="id"> QTY</LabelName>
                <LabelName type="soc"> Milk Value</LabelName>
                <LabelName type="soc">Incentive </LabelName>
                <LabelName type="loc">Amount</LabelName>
                <LabelName type="no">Bonus fund</LabelName>
                <LabelName type="milk">TC from Society</LabelName>
                <LabelName type="action">Milk rate</LabelName>
                <LabelName type="action">Test Aids</LabelName>
                <LabelName type="action">Advance</LabelName>
                <LabelName type="action">Insurance</LabelName>
                {/* <LabelName type="action">Insurance</LabelName> */}
                <LabelName type="action">Balance</LabelName>
                <LabelName type="milk">TC to Society</LabelName>
                <LabelName type="action">Net Balance</LabelName>
                {/* <LabelName type="id"></LabelName> */}
              </ListLabels>
              {isLoading ? (
                <Loader />
              ) : paymentList?.length == 0 ? (
                <Nodata />
              ) : (
                <CoverContainer>
                  {paymentList?.map((item, index) => (
                    <CoverItems>
                      <SocietyName>{item.route}</SocietyName>
                      {item?.data?.map((obj, index) => (
                        <List key={index}>
                          <Item type="all">
                            <Serial>{startIndex + index + 1}</Serial>
                          </Item>
                          <Item type="id" title={obj.society}>
                            <Id type="item-id">{obj.society}</Id>
                          </Item>
                          <Item type="milk">
                            <Span>{obj.created_at}</Span>
                          </Item>
                          <Item type="soc">
                            <span>{obj.quantity}</span>
                          </Item>
                          <Item type="loc">
                            <span>{obj.milk_value}</span>
                          </Item>
                          <Item type="no">
                            <Span>{obj.incentive}</Span>
                          </Item>
                          <Item type="milk">
                            <Span>{obj.amount}</Span>
                          </Item>
                          <Item type="milk">
                            <Span>{obj.bonus_fund}</Span>
                          </Item>
                          <Item type="milk">
                            <Span>{obj.tc_from_society}</Span>
                          </Item>
                          <Item type="milk">
                            <Span>{obj.milk_value}</Span>
                          </Item>
                          <Item type="milk">
                            <Span>{obj.test_aid}</Span>
                          </Item>
                          <Item type="milk">
                            <Span>{obj.advanced_payment}</Span>
                          </Item>
                          <Item type="milk">
                            <Span>{obj.insurance}</Span>
                          </Item>
                          <Item type="milk">
                            <Span>{obj.balance}</Span>
                          </Item>
                          <Item type="milk">
                            <Span>{obj.tc_to_society}</Span>
                          </Item>
                          <Item type="action">
                            <span>{obj.net_balance}</span>
                          </Item>
                        </List>
                      ))}
                    </CoverItems>
                  ))}
                </CoverContainer>
              )}
            </CoverList>
            {/* {isButtonLoading ? (
            <Proceed>
              <ButtonLoader />
            </Proceed>
          ) : (
            <Proceed onClick={() => proceedtoPay()}>Proceed to pay</Proceed>
          )} */}
          </SocietyList>
        </CoverLi>
      </>
      {pagination && pagination.total_pages > 1 && getSocieties.length > 0 && (
        <PaginationContainer>
          <PaginationText>
            Showing Societies : {pagination.first_item} - {pagination.last_item}{" "}
            of {pagination.total_items} Societies
          </PaginationText>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pagination.total_pages}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </PaginationContainer>
      )}
    </MainContainer>
  );
}

export default CompletedPayments;
const MainContainer = styled.div`
  padding: 20px;
  /* max-height: calc(100vh - 100px);
  overflow: scroll; */
  overflow-x: hidden;
`;
const CoverLi = styled.div`
  overflow: scroll;
`;
const TopTitle = styled.h2`
  text-align: center;
  margin-bottom: 10px;
  &.create {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10px;
    color: #0a0a0a;
  }
`;

const SocietyList = styled.div`
  background-color: #fff;
  padding: 0 20px;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  /* height: calc(100vh - 290px);
  overflow: scroll; */
  @media (max-width: 1080px) {
    min-width: 1500px;
    overflow-x: scroll;
  }
`;
const CoverList = styled.div`
  width: 100%;
`;
const SocietyName = styled.div`
  padding: 20px;
  background: #f4f5f8;
`;
const Item = styled.div`
  width: 80px;
  font-size: 14px;
  color: #2e3032;
  span {
    color: #2e3032;
  }
  &.active {
    i {
      color: #74d874;
    }
  }
  i {
    margin-left: 10px;
    font-size: 16px;
  }
`;
const LabelName = styled.h4`
  color: #797d82;
  font-family: "inter_regular";
  width: 80px;
`;
const ListLabels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #dfe8ed;
`;
const List = styled(Link)`
  padding: 20px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfe8ed;
  align-items: center;
  cursor: pointer;
`;
const Serial = styled.h4`
  color: #2e3032;
`;
const Id = styled.h4`
  color: #2382d4;
  text-overflow: ${({ type }) => (type === "item-id" ? "ellipsis" : "none")};
  overflow: hidden;
  width: ${({ type }) => (type === "item-id" ? "70px" : "80px")};
`;
const Span = styled.div`
  span {
    color: #2382d4;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 15px;
  @media all and (max-width: 1380px) {
    padding: 15px 0px;
    margin-right: 40px;
  }
  @media all and (max-width: 1280px) {
    margin-right: 20px;
    padding: 20px 0px;
  }
  @media all and (max-width: 980px) {
    margin-right: 10px;
  }
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 12px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
const TopRight = styled.div`
  display: flex;
  /* flex-wrap:wrap; */
  gap:10px;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 1080px) {
    min-width: 610px;
    overflow: scroll;
  }
`;
const DateContainer = styled.div`
  color: #747474;
  font-family: "inter_regular";
  width: 100%;
  text-align: end;
`;
const ButContainer = styled.div`
  padding: 5px 8px;
  background: #2382d4;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
`;
const CoverContainer = styled.div`
  max-height: calc(100vh - 300px);
  overflow: scroll;
`;
const CoverItems = styled.div`
  /* height: calc(100vh - 340px);
  overflow: scroll; */
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    font-size: 28px;
  }
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
`;

const FilterButton = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  border: 1px solid #2382d4;
  padding: 5px 12px;
  border-radius: 8px;
  position: relative;
`;
