import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SocietyDashboard from "../../screens/society-admin/dashboard";
import { Context } from "../../context/Store";
import PrivateRoute from "../routes/privateRoute";
import AuthRoute from "../routes/authRoute";
import AuthRouter from "./AuthRouter";
import SectionLoader from "../../components/sectionloader/SectionLoader";
import Dashboard from "../../screens/plant/DashBoard";
import Mobile from "../../screens/includes/nodata/Mobile";
import styled from "styled-components";

const MainRouter = () => {
  const [isLoading, setLoading] = useState(true);

  const {
    dispatch,
    state: { user_data, route_data },
  } = useContext(Context);
  const role = user_data.roles;
  useEffect(() => {
    let user_data = localStorage.getItem("user_data");
    user_data = JSON.parse(user_data);
    dispatch({ type: "UPDATE_USER_DATA", user_data: user_data });
    let route_data = localStorage.getItem("route_data");
    route_data = JSON.parse(route_data);
    dispatch({ type: "UPDATE_ROUTE_DATA", route_data: route_data });
    setTimeout(() => setLoading(false), 1100);
  }, []);

  const isVerified = user_data.isVerified;
  return isLoading ? (
    <SectionLoader />
  ) : (
    <>
      <Container>
        <Routes>
          {role === "Society" ? (
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <SocietyDashboard />
                </PrivateRoute>
              }
            />
          ) : role === "Plant" ? (
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
          ) : role == "" ? (
            !isVerified && (
              <>
                <Route path="*" element={<Navigate to="auth/" />} />
                <Route
                  path="auth/*"
                  element={
                    <AuthRoute>
                      <AuthRouter />
                    </AuthRoute>
                  }
                />
              </>
            )
          ) : role === "PlantSubProfile" ? (
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
          ) : (
            ""
          )}
        </Routes>
      </Container>
      <Mob>
        <Mobile />
      </Mob>
    </>
  );
};

export default MainRouter;

const Container = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;
const Mob = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: block;
  }
`;