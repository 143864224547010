import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import {
  action,
  curve,
  left_arrow,
  plus,
  plus_sign,
} from "../../../components/assets";
import { farmer_list, routs_societies } from "../../../utils/arrays";
import { Link, useNavigate } from "react-router-dom";
import { GetAllFarmers } from "../../../api/society";
import { Context } from "../../../context/Store";
import Nodata from "../../includes/nodata/Nodata";
import Loader from "../../../components/loader/Loader";
import ReactPaginate from "react-paginate";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SearchInput } from "../../../components";

function SocietyFarmerList() {
  const [isCopied, setCopied] = useState("");
  const [getAllFarmers, setAllFarmers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searched, setSearched] = useState("");
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    GetAllFarmers(config, currentPage, searched)
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setAllFarmers(res.app_data.data);
          setIsLoading(false);
          setPagination(res.app_data.pagination_data);
        } else {
          setAllFarmers([]);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
    setTimeout(() => setIsLoading(false), 1100);
  }, [currentPage, searched]);

  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "farmers",
      },
    });
  }, []);
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }
  return (
    <MainContainer>
      <TopBar>
        <TopTitle>Farmers</TopTitle>
        <CoverTop>
          <SearchInput state={searched} setState={setSearched} />
          <Add to="/create-farmer">
            <Icon>
              <i class="ri-add-line"></i>
            </Icon>
            <span>Add New Farmers</span>
          </Add>
        </CoverTop>
      </TopBar>
      <div className="parent-scroll">
        <SocietyList className="scroll">
          <ListLabels>
            <LabelName type="all">Sl No</LabelName>
            <LabelName type="id">Farmer ID</LabelName>
            <LabelName type="soc">Farmer name</LabelName>
            {/* <LabelName type="soc">Society name </LabelName> */}
            <LabelName type="loc">Address</LabelName>
            <LabelName type="no">Society</LabelName>
            <LabelName type="milk">Average milk </LabelName>
            <LabelName type="action">Username</LabelName>
            <LabelName type="action">Password</LabelName>
          </ListLabels>
          {isLoading ? (
            <Loader />
          ) : getAllFarmers?.length == 0 ? (
            <Nodata />
          ) : (
            getAllFarmers?.map((item, index) => (
              <List key={index} to={`/farmers/${item.id}`}>
                <Item type="all">
                  <Serial>{index + 1}</Serial>
                </Item>
                <Item type="id">
                  <Id title={item.farmer_id}>{item.farmer_id}</Id>
                </Item>
                <Item type="soc">
                  <CoverItem>
                    <Iconz>ST</Iconz>
                    <CoverName>
                      <Name>{item.name}</Name>
                      <Phone>{item.phone}</Phone>
                    </CoverName>
                  </CoverItem>
                </Item>
                {/* <Item type="soc">
              <span>{item.society_name}</span>
            </Item> */}
                <Item type="loc">
                  <span>
                    {item.address == "undefined" ? "--" : item.address}
                  </span>
                </Item>
                <CoverName>
                  <Item type="no">
                    <Span>{item.society}</Span>
                  </Item>
                  <Item type="milk">
                    <Span>{item.society_id}</Span>
                  </Item>
                </CoverName>
                <Item type="milk">
                  <span>{item.average_milk} liters</span>
                </Item>
                <Item
                  type="action"
                  className={isCopied == item.farmer_id && "active"}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <span>{item.farmer_id}</span>
                  <CopyToClipboard
                    text={item.farmer_id}
                    onCopy={() => setCopied(item.farmer_id)}
                  >
                    <i class="ri-file-copy-line"></i>
                  </CopyToClipboard>
                </Item>
                <Item
                  className={isCopied == item.password && "active"}
                  type="action"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <span>{item.password}</span>

                  <CopyToClipboard
                    text={item.password}
                    onCopy={() => setCopied(item.password)}
                  >
                    <i class="ri-file-copy-line"></i>
                  </CopyToClipboard>
                </Item>
              </List>
            ))
          )}
        </SocietyList>
      </div>

      {pagination && pagination.total_pages > 1 && getAllFarmers.length > 0 && (
        <PaginationContainer>
          <PaginationText>
            Showing Farmers : {pagination.first_item} - {pagination.last_item}{" "}
            of {pagination.total_items} Enquiries
          </PaginationText>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pagination.total_pages}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </PaginationContainer>
      )}
    </MainContainer>
  );
}

export default SocietyFarmerList;
const MainContainer = styled.div`
  padding: 20px;
  max-height: calc(100vh - 100px);
  overflow: scroll;
  overflow-x: hidden;
`;
const TopBar = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
const CoverTop = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  span{
    white-space: nowrap;

  }
  @media (max-width:680px) {
    width: 100%;
    span{
      white-space: nowrap;
      font-size: 12px;
  
    }
  }
`;
const Arrow = styled.div`
  border: 1px solid #2382d4;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const Text = styled.div``;
const TopTitle = styled.h2`
  color: #0a0a0a;
  margin-bottom: 5px;
`;
const RoutePath = styled.h4`
  color: #747474;
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  img {
    width: 100%;
    display: block;
  }
`;
const Add = styled(Link)`
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #2382d4;
  color: #2382d4;
  /* width: 120px; */
  height: 35px;
  display: flex;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
`;
const Icon = styled.div`
  margin-right: 10px;
  img {
    display: block;
    filter: brightness(0.1);
  }
`;

const SocietyList = styled.div`
  background-color: #fff;
  padding: 0 20px;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
`;
const Tab = styled.div`
  border-top: 1px solid #dfe8ed;
  border-left: 1px solid #dfe8ed;
  border-right: 1px solid #dfe8ed;
  border-bottom: 3px solid transparent;
  padding: 14px 20px;
  cursor: pointer;
  margin-right: 30px;
  color: #797d82;
  border-radius: 5px 5px 0 0;
  &.active {
    color: #2382d4;
    background-color: #e0f3ff;
    border-top: 1px solid #2382d4;
    border-left: 1px solid #2382d4;
    border-right: 1px solid #2382d4;
    border-bottom: 3px solid #2382d4;
  }
`;
const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Item = styled.div`
  /* width: 20%; */
  width: 140px;
  font-size: 16px;
  color: #2e3032;
  span {
    color: #2e3032;
  }
  &.active {
    i {
      color: #74d874;
    }
  }
  i {
    margin-left: 10px;
    font-size: 18px;
  }
  width: ${({ type }) =>
    type === "all"
      ? "45px"
      : type === "id"
      ? "90px"
      : type === "soc"
      ? "180px"
      : type === "loc"
      ? "200px"
      : type === "no"
      ? "150px"
      : type === "milk"
      ? "110px"
      : type === "action"
      ? "140px"
      : ""};
`;
const LabelName = styled.h4`
  color: #797d82;
  font-family: "inter_regular";
  /* width: 20%; */
  width: ${({ type }) =>
    type === "all"
      ? "45px"
      : type === "id"
      ? "90px"
      : type === "soc"
      ? "170px"
      : type === "loc"
      ? "200px"
      : type === "no"
      ? "150px"
      : type === "milk"
      ? "110px"
      : type === "action"
      ? "150px"
      : ""};
`;
const ListLabels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #dfe8ed;
  /* border-top: 1px solid #dfe8ed; */
`;
const List = styled(Link)`
  padding: 20px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfe8ed;
  align-items: center;
  cursor: pointer;
`;
const Serial = styled.h4`
  color: #2e3032;
`;
const Id = styled.h4`
  color: #2382d4;
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const CoverItem = styled.div`
  display: flex;
  align-items: center;
`;
const Iconz = styled.div`
  background-color: #e0f3ff;
  color: #2382d4;
  padding: 15px;
  font-family: "inter_medium";
  border-radius: 50%;
  margin-right: 10px;
`;
const CoverName = styled.div`
  width: 150px;
`;
const Name = styled.h4`
  color: #2e3032;
  font-size: 14px;
  margin-bottom: 5px;
`;
const Phone = styled.h4`
  font-size: 14px;
  color: #808080;
`;
const Span = styled.div`
  span {
    color: #2382d4;
  }
`;
const Icons = styled.div``;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 15px;
  @media all and (max-width: 1380px) {
    padding: 15px 0px;
    margin-right: 40px;
  }
  @media all and (max-width: 1280px) {
    margin-right: 20px;
    padding: 20px 0px;
  }
  @media all and (max-width: 980px) {
    margin-right: 10px;
  }
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 14px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
