import React, { useContext, useEffect, useState } from "react";
import {
  black_calendar,
  curve,
  left_arrow,
  no_data,
} from "../../../../components/assets";
import { styled } from "styled-components";
import { local_sale } from "../../../../utils/arrays";
import { useNavigate, useParams } from "react-router-dom";
import { getLocalSales } from "../../../../api/auth";
import { Context } from "../../../../context/Store";
import Nodata from "../../../includes/nodata/Nodata";

function SocietyLocalSales() {
  const [getLocalSaleData, setLocalSaleData] = useState([]);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  //get society id
  const { id } = useParams();

  // get accesstoken
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;

  // headers for the api
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  // get api for listing all local sales
  useEffect(() => {
    getLocalSales(config, id).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        setLocalSaleData(res.app_data.data);
      } else {
        setLocalSaleData([]);
      }
    });
  }, []);
  return (
    <Container>
      <TopBar>
        <TopCover>
          <Arrow onClick={goBack}>
            <img src={left_arrow} alt="image" />
          </Arrow>
          <Text>
            <TopTitle>Local sales </TopTitle>
            <RoutePath>Societies / Society 1 / Local sales </RoutePath>
          </Text>
        </TopCover>
        <Curve>
          <img src={curve} alt="" />
        </Curve>
      </TopBar>
      <CoverLi>
        <Table>
          <LabelItems>
            <Label>Sl No</Label>
            <Label>Date</Label>
            <Label>Quantity</Label>
            <Label>Price per liters</Label>
            <Label>Total amount</Label>
          </LabelItems>
          {getLocalSaleData.length == 0 ? (
            <Nodata />
          ) : (
            getLocalSaleData.map((item, index) => (
              <CardLabel key={index}>
                <Item>{index + 1} </Item>
                <Item>
                  <Icon>
                    <img src={black_calendar} alt="image" />
                    <span>{item.created_at} </span>
                  </Icon>
                </Item>
                <Item className="blue">{item.quantity} litre</Item>
                <Item>{item.price_per_liter} per litre</Item>
                <Item>{item.price}</Item>
              </CardLabel>
            ))
          )}
        </Table>
      </CoverLi>
    </Container>
  );
}

export default SocietyLocalSales;

const Container = styled.div`
  padding: 20px;
`;
const CoverLi = styled.div`
  overflow: scroll;
`;
const TopBar = styled.div`
  padding: 20px;
  background-color: #fff;
  position: relative;
  border: 1px solid #dfe8ed;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
`;
const TopCover = styled.div`
  display: flex;
`;
const Arrow = styled.div`
  border: 1px solid #2382d4;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const Text = styled.div``;
const TopTitle = styled.h2`
  color: #2382d4;
  margin-bottom: 5px;
`;
const RoutePath = styled.h4`
  color: #747474;
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  img {
    width: 100%;
    display: block;
  }
  @media (max-width: 640px) {
   display: none;
  }
`;
const Table = styled.div`
  background-color: #fff;
  @media (max-width: 1080px) {
    min-width: 1280px;
    overflow-x: scroll;
  }
`;
const LabelItems = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  align-items: center;
  border-bottom: 1px solid #dfe8ed;
`;
const CardLabel = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfe8ed;
  align-items: center;
  padding: 30px;
`;
const Item = styled.div`
  width: 20%;
  font-family: "inter_medium";
  color: #2e3032;
  &.blue {
    color: #2382d4;
  }
`;
const Icon = styled.div`
  display: flex;
  span {
    font-size: 14px;
  }
  img {
    margin-right: 5px;
  }
`;
const Label = styled.div`
  width: 20%;
  color: #797d82;
  font-family: "inter_regular";
  font-size: 18px;
`;
