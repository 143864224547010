import React, { useEffect, useState } from "react";

import { styled } from "styled-components";
// import { local_sale, paymentlist } from "../../../../utils/arrays";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../../api";
import Nodata from "../../../../includes/nodata/Nodata";
import Loader from "../../../../../components/loader/Loader";

function SocietyPaymentList({ SocietyId }) {
  const [paymentList, setPaymentList] = useState([]);
  const [isPayment, setIsPayment] = useState(true);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const { id } = useParams();
  useEffect(() => {
    api
    .get(`payments/society-list-payment-history/`)
    .then((res) => {
      setIsPayment(false);
      if(res.app_data.StatusCode === 6000){
        setPaymentList(res.app_data.data)
      }else{
        setPaymentList([])
      }
    })
    .catch((err) => {
      setIsPayment(false);
    })
  }, [id]);
  return (
      isPayment ? 
      <Loader />
      :
      <Container>
        <Table>
          <LabelItems>
            <Label className="sl">Sl No</Label>
            <Label>Detail</Label>
            {/* <Label>Transaction ID</Label> */}
            <Label>Status</Label>
            <Label>Amount</Label>
            {/* <Label>Action</Label> */}
          </LabelItems>
          {
            paymentList?.length > 0 ?
              paymentList.map((item, index) => (
                <CardLabel key={index}>
                  <Item className="sl">{index+1} </Item>
                  <Item>{item.detail}</Item>
                  {/* <Item className="blue">{item.transaction_id} </Item> */}
                  <Item>{item.status}</Item>
                  <Item>{item.amount}</Item>
                  {/* <Item>
                    <Icon>
                      <img src={download} alt="image" />
                      <span>Download</span>
                    </Icon>
                  </Item> */}
                </CardLabel>
              ))
              :
              <Nodata />
          }
        </Table>
      </Container>
  );
}

export default SocietyPaymentList;

const Container = styled.div`
  width: 100%;
`;
const Table = styled.div`
  background-color: #fff;
`;
const LabelItems = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  align-items: center;
  border-bottom: 1px solid #dfe8ed;
`;
const CardLabel = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfe8ed;
  align-items: center;
  padding: 30px;
`;
const Item = styled.div`
  width: 20%;
  font-family: "inter_medium";
  color: #2e3032;
  &.blue {
    color: #2382d4;
  }
  &.sl {
    width: 15%;
  }
`;
const Label = styled.div`
  width: 20%;
  color: #797d82;
  font-family: "inter_regular";
  font-size: 18px;
  &.sl {
    width: 15%;
  }
`;
