import React, { useContext, useEffect, useState } from "react";
import { SearchInput } from "../../../components";
import styled from "styled-components";
import product from "../../../components/assets/images/plant/milkprodcut.png";
import productImage from "../../../components/assets/images/plant/productcartmilk.png";
import cartImage from "../../../components/assets/images/plant/cart.png";
import {
    addToCart,
    cartList,
    checkoutConfirmOrder,
    deleteFromCart,
    getProductList,
} from "../../../api/product";
import { Context } from "../../../context/Store";
import { SocietiesList, SocietiesListView } from "../../../api/auth";
import { ToastContainer, toast } from "react-toastify";
import api from "../../../api";
import NoCart from "./includes/NoCart";
import { useNavigate } from "react-router-dom";
export default function AddOrder() {
    const [getData, setData] = useState([]);
    const [getSocieties, setSociety] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [productId, setProductId] = useState("");
    const [cartId, setCartId] = useState("");
    const [quantityById, setQuantityById] = useState({});
    const [societyId, setSocietyId] = useState("");
    const [render, setRender] = useState(false);
    const [searchedItems, setSearchedItems] = useState("");
    const [isConfirmed, setIsConfirmed] = useState(false);

    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const token = user_data.access_token;
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const navigate = useNavigate();
    const notify = () => {
        toast.success("Product added to cart successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };
    const notifyDeleted = () => {
        toast.success("Product deleted from cart successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    };
    useEffect(() => {
        api.get("product/products", {
            params: {
                q: searchedItems,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => {
            setData(res.app_data.data);
            res.app_data.data.map((item) => {
                setQuantityById((prev) => ({
                    ...prev,
                    [item.id]: 1
                }))
            })
        });
    }, [searchedItems]);

    const [counters, setCounters] = useState({});

    // const increment = (item) => {
    //   setCounters((prevCounters) => {
    //     const currentCounter = prevCounters[item.id] || 0;

    //     return { ...prevCounters, [item.id]: currentCounter + 1 };
    //   });
    // };

    // const decrement = (item) => {
    //   setCounters((prevCounters) => {
    //     const currentCounter = prevCounters[item.id] || 0;
    //     if (currentCounter > 0) {
    //       return { ...prevCounters, [item.id]: currentCounter - 1 };
    //     }
    //     return prevCounters;
    //   });
    // };

    // const handleIncrement = (productId) => {
    //     console.log(quantityById, "QuantityById in increment");
    //     setQuantityById((prevQuantity) => ({
    //         ...prevQuantity,
    //         [productId]: (prevQuantity[productId] || 0) + 1,
    //     }));
    // };

    // Function to handle decrementing quantity for a specific product ID
    // const handleDecrement = (productId) => {
    //     console.log(quantityById, "QuantityById in decrement");

    //     if (quantityById[productId] > 0) {
    //         setQuantityById((prevQuantity) => ({
    //             ...prevQuantity,
    //             [productId]: prevQuantity[productId] - 1,
    //         }));
    //     }
    // };

    //society list
    useEffect(() => {
        SocietiesListView(config, "list-type-all").then((res) => {
            if (res.app_data.StatusCode == 6000) {
                setSociety(res.app_data.data);
                setSocietyId(res.app_data.data[0]?.id);
            } else {
                setSociety([]);
            }
        });
    }, []);

    //Cart list
    useEffect(() => {
        societyId &&
            api
                .get("product/cart/", {
                    params: {
                        user_id: societyId,
                    },
                })
                .then((res) => {
                    if (res.app_data.StatusCode == 6000) {
                        setCartItems(res.app_data.data);
                    } else {
                        setCartItems([]);
                    }
                });
    }, [societyId, render]);

    const countKey = Object.values(counters);
    const formData = new FormData();
    formData.append("user_id", societyId);
    const addToCartApi = (item) => {
        const quantityElement = document.querySelector(`.quantity-${item.id}`);
        const quantity = quantityElement
            ? parseInt(quantityElement.textContent.trim())
            : 1;
            
        formData.append("quantity", quantity);
        formData.append("product_id", item.id);
        setRender(true);
        addToCart(config, formData).then((res) => {
            if (res.app_data.StatusCode == 6000) {
                notify();
                setRender(false);
            } else {
            }
        });
    };

    //delete cart
    const formDataProdct = new FormData();
    formDataProdct.append("user_id", societyId);
    const deleteCartItem = (cart_id) => {
        setRender(true);
        deleteFromCart(cart_id, formDataProdct, config).then((res) => {
            if (res.app_data.StatusCode == 6000) {
                notifyDeleted();
                setRender(false);
            } else {
            }
        });
    };
    //confirm order
    const useridFormData = new FormData();
    useridFormData.append("user_id", societyId);

    const confirmOrder = () => {
        checkoutConfirmOrder(useridFormData, config).then((res) => {
            if (res.app_data.StatusCode == 6000) {
                navigate("/orders");
            } else {
            }
        });
    };
    return (
        <Container>
            <ToastContainer />
            <Content>
                <ProductListSection>
                    <SelectSociety>
                        <CoverSelect>
                            <LabelSelect>Select Society*</LabelSelect>
                            <Select
                                onChange={(e) => setSocietyId(e.target.value)}
                            >
                                {getSocieties.length > 0 &&
                                    getSocieties?.map((item) => (
                                        <option value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </Select>
                        </CoverSelect>
                        <ProductList>
                            <TopBar>
                                <Text>Product List</Text>
                                <SearchBar>
                                    <SearchInput
                                        setState={setSearchedItems}
                                        state={searchedItems}
                                    />
                                </SearchBar>
                            </TopBar>
                            <CoverList>
                                {getData.map((item, index) => (
                                    <List
                                        key={index}
                                        onClick={() => {
                                            setProductId(item.id);
                                        }}
                                    >
                                        <ListItem>
                                            <Title>{index + 1} </Title>
                                        </ListItem>

                                        <ListItem>
                                            <Title>{item.name}</Title>
                                        </ListItem>
                                        <ListItem>
                                            <ImageContainer>
                                                <img src={item.image} alt="" />
                                            </ImageContainer>
                                        </ListItem>
                                        <ListItem>
                                            <Price>₹{item.price}</Price>
                                        </ListItem>
                                        <ListItem>
                                            <IncrDecr>
                                                <Div>
                                                    <Span
                                                        onClick={() =>{
                                                            // handleDecrement(
                                                            //     item.id
                                                            // )
                                                            setQuantityById((prevQuantity) => {
                                                                // Clone the previous state
                                                                const newQuantity = { ...prevQuantity };
                                                                // Check if the product exists and its quantity is greater than 0
                                                                if (newQuantity[item.id] && newQuantity[item.id] > 0) {
                                                                    newQuantity[item.id] -= 1;
                                                                }
                                                                return newQuantity;
                                                            });
                                                        }}
                                                    >
                                                        -
                                                    </Span>
                                                    <Value
                                                        className={`quantity-${item.id}`}
                                                    >
                                                        {quantityById[
                                                            item.id
                                                        ] || 1}{" "}
                                                    </Value>
                                                    <Span
                                                        onClick={() =>{
                                                            // handleIncrement(
                                                            //     item.id
                                                            // )
                                                            setQuantityById((prevQuantity) => {
                                                                // Ensure we're not mutating the state directly
                                                                const newQuantity = { ...prevQuantity };
                                                                // Default to 0 if productId doesn't exist, then increment
                                                                newQuantity[item.id] = (newQuantity[item.id]) + 1;
                                                                return newQuantity;
                                                            });
                                                        }}
                                                    >
                                                        +
                                                    </Span>
                                                </Div>
                                            </IncrDecr>
                                        </ListItem>
                                        <ListItem>
                                            <CartButton
                                                onClick={() =>
                                                    addToCartApi(item)
                                                }
                                            >
                                                <TextCart>Add to cart</TextCart>
                                                <CartIcon>
                                                    <img
                                                        src={cartImage}
                                                        alt="cart"
                                                    />
                                                </CartIcon>
                                            </CartButton>
                                        </ListItem>
                                    </List>
                                ))}
                            </CoverList>
                        </ProductList>
                    </SelectSociety>
                </ProductListSection>
                <CartList>
                    <TopSection>Cart List</TopSection>
                    <CartSection>
                        <CoverCartSection>
                            {cartItems?.data?.length == 0 ? (
                                <NoCart />
                            ) : (
                                cartItems.data?.map((item, index) => (
                                    <Cart key={index}>
                                        <Left>
                                            <CartImage>
                                                <img
                                                    src={item.product.image}
                                                    alt=""
                                                />
                                            </CartImage>
                                            <Covername>
                                                <ProductName>
                                                    {item.product.name}
                                                </ProductName>
                                                <ProductPrice>
                                                    ₹{item.product.price}
                                                </ProductPrice>
                                            </Covername>
                                        </Left>
                                        <Right>
                                            <Quantity>
                                                Qty:{" "}
                                                <span>{item.quantity} </span>
                                            </Quantity>
                                            <Remove
                                                onClick={() => {
                                                    setCartId(item.id);
                                                    deleteCartItem(item.id);
                                                }}
                                            >
                                                <RemoveText>Remove</RemoveText>
                                                <RemoveIcon>
                                                    <i class="ri-delete-bin-5-line"></i>
                                                </RemoveIcon>
                                            </Remove>
                                        </Right>
                                    </Cart>
                                ))
                            )}
                        </CoverCartSection>
                        {cartItems.total_price && (
                            <CoverBottom>
                                <CoverTotal>
                                    <TotalPrice>
                                        <TotalText>Total price</TotalText>
                                        <TotalAmount>
                                            ₹{cartItems.total_price}
                                        </TotalAmount>
                                    </TotalPrice>
                                    <ButtonTotal onClick={() => setIsConfirmed(true)}>
                                        Confirm Order
                                    </ButtonTotal>
                                </CoverTotal>
                            </CoverBottom>
                        )}
                    </CartSection>
                </CartList>
            </Content>
            {isConfirmed && (
                <Modal>
                    <ModalContainer>
                        <p>
                            Are you sure to confirm this order?
                        </p>
                        <ButtonContainer>
                            <ButtonNew onClick={() => setIsConfirmed(false)}>
                                Cancel
                            </ButtonNew>
                            <ButtonNew
                                onClick={() => confirmOrder()}
                                className="confirm"
                            >
                                Confirm
                            </ButtonNew>
                        </ButtonContainer>
                    </ModalContainer>
                </Modal>
            )}
        </Container>
    );
}

const Select = styled.select`
    width: 100%;
    background-color: #f4f5f8;
    border: 1px solid #dfe8ed;
    height: 60px;
    font-size: 18px;
    padding-left: 20px;
    border-radius: 5px;
    outline: none;
    &.auto {
        color: #adadad;
    }
`;
const Container = styled.div`
    background-color: #fff;
    height: calc(100vh - 80px);
    padding: 0 30px;
`;
const Content = styled.div`
    display: flex;
    gap: 30px;
    padding-top: 50px;
    @media (max-width: 1080px) {
        flex-direction: column;
    }
`;
const ProductListSection = styled.div`
    width: 65%;
    @media (max-width: 1080px) {
        width: 100%;
    }
`;
const SelectSociety = styled.div``;
const CoverSelect = styled.div`
    margin-bottom: 40px;
`;
const LabelSelect = styled.div`
    color: #747474;
    margin-bottom: 10px;
`;
const ProductList = styled.div``;
const TopBar = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Text = styled.div`
    font-weight: 400;
`;
const CoverList = styled.div`
    max-height: calc(100vh - 300px);
    overflow: scroll;
`;
const SearchBar = styled.div``;
const List = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    align-items: center;
    @media (max-width: 640px) {
        flex-wrap: wrap;
    }
`;
const Span = styled.div`
    color: #2382d4;
    font-size: 24px !important;
    cursor: pointer;
    padding: 10px;
`;
const ListItem = styled.div``;
const Title = styled.div``;
const Div = styled.div`
    display: flex;
    align-items: center;
`;
const ImageContainer = styled.div`
    width: 50px;
    img {
        display: block;
        width: 100%;
    }
`;
const Price = styled.div`
    color: #2382d4;
`;
const IncrDecr = styled.div``;
const Value = styled.div`
    font-size: 16px;
    border: 2px solid #a5a5a5;
    color: #2382d4;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: 40px;
`;
const CartList = styled.div`
    width: 35%;
    border: 1px solid #dfe8ed;
    padding: 40px 30px;
    @media (max-width: 1080px) {
        width: 100%;
    }
`;
const Cart = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    border: 1px solid #dfe8ed;
    padding: 16px 20px;
    align-items: center;
    box-sizing: border-box;
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const CartImage = styled.div`
    width: 50px;
    img {
        display: block;
        width: 100%;
    }
`;
const Covername = styled.div``;
const ProductName = styled.div`
    color: #747474;
    margin-bottom: 5px;
`;

const ProductPrice = styled.h2``;
const Right = styled.div``;
const Quantity = styled.div`
    color: #747474;
    margin-bottom: 5px;
`;
const Remove = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
`;
const RemoveText = styled.div`
    color: #747474;
`;
const RemoveIcon = styled.div`
    color: #ff0000;
    font-size: 20px;
    cursor: pointer;
`;
const CoverBottom = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
`;
const CoverTotal = styled.div``;
const TotalPrice = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
`;
const TotalText = styled.div`
    color: #747474;
`;
const TotalAmount = styled.h2`
    color: #2382d4;
`;
const ButtonTotal = styled.div`
    width: 150px;
    background-color: #2382d4;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    justify-content: center;
    border-radius: 4px;
    color: #fff;
`;
const TopSection = styled.div`
    font-size: 22px;
`;
const CartSection = styled.div``;
const CoverCartSection = styled.div`
    max-height: calc(100vh - 350px);
    overflow: scroll;
`;
const CartIcon = styled.div`
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
`;
const CartButton = styled.div`
    display: flex;
    gap: 5px;
    background-color: #2382d4;
    color: #fff;
    align-items: center;
    justify-content: center;
    width: 160px;
    cursor: pointer;
    border-radius: 5px;
    height: 40px;
    @media (max-width: 640px) {
        margin-top: 30px;
    }
`;
const TextCart = styled.div`
    font-size: 14px;
`;
const Modal = styled.div`
    width: 100%;
    height: 100vh;
    background: #00000070;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ModalContainer = styled.div`
    width: 45%;
    padding: 60px;
    background: #fff;
    border-radius: 10px;
    text-align: center;
    p {
        margin-bottom: 20px;
        font-weight: 400;
        color: #0a0a0a;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ButtonNew = styled.button`
    background: #2382d4;
    width: 150px;
    height: 40px;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    margin: 0 auto;
    &.confirm {
        background-color: #23d473;
    }
`;