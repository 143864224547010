import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import RectangleButton from "../../../components/Buttons/RectangleButton";
import RectangleColorButton from "../../../components/Buttons/RectangleColorButton";
import { RoundPlus } from "../../../components/assets";
import { DropDown } from "../../../components/assets/icons";
import { useNavigate } from "react-router-dom";
import { CreatingUser, PrivilegeView } from "../../../api/activities";
import { Context } from "../../../context/Store";
import ButtonLoader from "../../../components/button-loader/ButtonLoader";
import api from "../../../api";

function CreateSubUser() {
  const [username, setUsername] = useState("");
  const [isDroppedDown, setIsDroppedDown] = useState(false);
  const [privileges, setPrivileges] = useState([]);
  const [privilegesData, setPrivilegesData] = useState([]);
  const [selectedPrivilages, setSelectedPrivilages] = useState([]);
  const navigate = useNavigate();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleClick = () => {
    setIsDroppedDown(!isDroppedDown);
  };

  const handlePrivilegeData = () => {
    PrivilegeView(config).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        setPrivilegesData(res.app_data.data);
      }
    });
  };

  useEffect(() => {
    handlePrivilegeData();
  }, []);

  const handlePrivileges = (value) => {
    if (privileges.some((element) => element.id === value.id)) {
      setPrivileges(
        privileges.filter((e) =>
          e.id !== value.id ? { id: e.id, name: e.name } : null
        )
      );
    } else {
      setPrivileges([
        ...privileges,
        {
          id: value.id,
          name: value.name,
        },
      ]);
    }
  };
  const handlePrivilegesIds = (item) => {
    setSelectedPrivilages((prevSelectedIds) => [...prevSelectedIds, item.id]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    const formData = new FormData();
    formData.append("username", username);

    selectedPrivilages.map((item) => {
      formData.append("privilages", item);
    });

    if (!username) {
      setErrorMessage("Please enter username");
      setIsButtonLoading(false);
    } else if (!selectedPrivilages) {
      setErrorMessage("Please select privileges");
      setIsButtonLoading(false);
    } else {
      api
        .post("accounts/create-plant-sub-profile/", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.app_data.StatusCode === 6000) {
            // Handle success
            setIsButtonLoading(false);
            navigate("/sub-user");
          } else {
            // Handle error
            setIsButtonLoading(false);
            setErrorMessage(response.app_data.data.message);
          }
        })
        .catch((error) => {
          // Handle error
        });
    }
  };

  return (
    <Container>
      <TopBox>
        <Heading>User Creation</Heading>
      </TopBox>
      <BottomBox>
        <GeneralBox onSubmit={(e) => handleSubmit(e)}>
          <TitleBox>
            <Title>General</Title>
          </TitleBox>
          <SingleBox>
            <Label>Username*</Label>
            <InputBox>
              <input
                type="text"
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </InputBox>
          </SingleBox>
          <SingleBox>
            <Label>Privileges*</Label>
            <SelectBox>
              {privilegesData.map((element, index) => (
                <PrivilegeTag
                  className={
                    privileges?.some(
                      (privilege) => privilege?.id === element.id
                    )
                      ? "selected"
                      : ""
                  }
                  onClick={() => {
                    handlePrivileges(element);
                    handlePrivilegesIds(element);
                  }}
                  key={index}
                >
                  {element.name}
                </PrivilegeTag>
              ))}
            </SelectBox>
          </SingleBox>
          <MiniBox>
            <TagName>Add Privileges</TagName>
            {privileges?.map((privilege) => (
              <TagButton>{privilege.name}</TagButton>
            ))}
            {/* <TagButton>Societies</TagButton> */}
          </MiniBox>
          {/* <ErrorMessage>{errorMessage}</ErrorMessage> */}
          {isButtonLoading ? (
            <SubmitButton>
              <ButtonLoader />
            </SubmitButton>
          ) : (
            <Button type="submit" value="Submit" />
          )}
        </GeneralBox>
      </BottomBox>
    </Container>
  );
}
export default CreateSubUser;

const Container = styled.div`
  width: 70%;
  margin: 70px auto;
  @media (max-width: 1080px) {
    width: 95%;
  }
`;

const TopBox = styled.div`
  margin-bottom: 20px;
`;

const Heading = styled.h2`
  color: #0a0a0a;
  font-size: 28px;
`;

const BottomBox = styled.div`
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

const Title = styled.pre`
  font-size: 18px;
  margin-right: 10px;
`;

const GeneralBox = styled.form`
  width: 100%;
`;

const SingleBox = styled.div`
  width: 100%;
  margin-bottom: 24px;
  input {
    background-color: transparent;
    width: 100%;
    font-size: 18px;
  }
`;

const InputBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f5f8;
  padding: 18px 24px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #dfe8ed;
  input {
    font-family: "inter_light";
  }
`;

const DropDownTag = styled.div``;
const SelectBox = styled.div`
  width: 100%;
  background-color: #f4f5f8;
  /* padding: 18px 24px; */
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #dfe8ed;
  display: flex;
  flex-direction: column;
  height: 250px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    -moz-appearance: scroll;
    /* display: none; */
  }
`;

const PrivilegeTag = styled.span`
  color: #767676;
  font-size: 18px;
  font-family: "inter_light";
  padding: 10px 24px;
  width: 100%;
  cursor: pointer;
  &.selected {
    color: #ffffff;
    background-color: #9f9f9f;
  }
`;

const Label = styled.h4`
  color: #747474;
  font-family: "inter_light";
  font-weight: unset;
  font-size: 18px;
`;

const MiniBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TagName = styled.h6`
  font-size: 12px;
  font-family: "inter_light";
  padding: 4px 12px;
  margin-right: 10px;
`;

const TagButton = styled.span`
  font-size: 12px;
  background-color: #2382d4;
  color: #fff;
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 8px;
  height: 34px;
  margin-right: 10px;
  font-family: "inter_light";

  &:last-child {
    margin-right: 0px;
  }
`;
const ErrorMessage = styled.span`
  color: red;
  margin-bottom: 5px;
  text-align: right;
  width: 100%;
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const SubmitButton = styled.div`
  height: 38px;
  width: 160px;
  /* margin-top: 32px; */
  margin-left: auto;
  padding: 6px 20px;
  border-radius: 4px;
  background-color: #2382d4;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "inter_light";
`;
const Button = styled.input`
  height: 38px;
  width: 160px;
  /* margin-top: 32px; */
  margin-left: auto;
  padding: 6px 20px;
  border-radius: 4px;
  background-color: #2382d4;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "inter_light";
`;
