import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import ButtonLoader from "../../../components/button-loader/ButtonLoader";
import upload from "../../../components/assets/images/upload.png";
import { Context } from "../../../context/Store";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../api";
import { getSingleProductList } from "../../../api/product";

function EditProduct({ setAdd, id, type }) {
    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const navigate = useNavigate();
    const { state } = useLocation();
    //state
    const [productName, setproductName] = useState("");
    const [category, setCategory] = useState("test_aid");
    const [price, setprice] = useState("");
    const [imageFile, setImageFile] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [productId, setProductId] = useState(state?.product_id);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    // console.log(imageFile, "state of location", imageUrl);

    const token = user_data.access_token;

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const handleGetData = () => {
        getSingleProductList(config, productId).then((response) => {
            // console.log(response, "response from edit getSingleProductList");
			setproductName(response.app_data.data.name);
			setprice(response.app_data.data.price);
			setImageUrl(response.app_data.data.image);
        });
    };
    const handleImageChange = (e) => {
		setImageUrl("")
        setImageFile(e.target.files[0]);
    };
    const handleClear = () => {
        setproductName("");
        setCategory("");
        setImageFile(null);
        setprice("");
    };
    //add product
    const formData = new FormData();
    formData.append("name", productName);
    formData.append("price", price);
    if(imageFile) {
        formData.append("image", imageFile);
    }
    formData.append("category", category);

    const editProduct = (e) => {
        e.preventDefault();
        api.put(`product/edit-product/${productId}/`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                if (response.app_data.StatusCode == 6000) {
                  handleClear();
                  navigate("/products");
                } else {
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        handleGetData();
    }, []);

    return (
        <>
            <Title>Edit Product</Title>
            <RouteInfo>
                <CoverInfo>
                    <Form onSubmit={(e) => editProduct(e)}>
                        <General>General</General>
                        <CoverIn>
                            <Label>Product Name*</Label>
                            <Input
                                type="text"
                                value={productName}
                                onChange={(e) => setproductName(e.target.value)}
                                required
                            />
                        </CoverIn>
                        {/* <CoverIn>
              <Label>Product Category*</Label>
              <Select onChange={(e) => setCategory(e.target.value)}>
                <option value="milk_can">Milk can</option>
                <option value="test_aid">Test aid</option>
              </Select>
            </CoverIn> */}
                        <CoverIn>
                            <Label>Price*</Label>
                            <Input
                                type="text"
                                value={price}
                                onChange={(e) => setprice(e.target.value)}
                                required
                            />
                        </CoverIn>
                        <CoverIn>
                            <Label>Upload Image*</Label>
                            <CoverFile
                                // type={imageFile.length}
                                style={{
                                    background: `url(${
										imageFile ? URL.createObjectURL(imageFile) : imageUrl
										
                                    })`,
                                }}
                            >
                                <Input
                                    type="file"
                                    className="file"
                                    onChange={handleImageChange}
                                    // required
                                />
                            </CoverFile>
                        </CoverIn>
                        <Buttons>
                            {isButtonLoading ? (
                                <Button>
                                    <ButtonLoader />
                                </Button>
                            ) : (
                                <SubmitButton type="submit" value="Submit" />
                            )}
                        </Buttons>
                    </Form>
                </CoverInfo>
            </RouteInfo>
        </>
    );
}

export default EditProduct;

const CoverFile = styled.div`
    width: 100%;
    background-color: #f4f5f8;
    background-size: 120px !important;
    /* background-size: ${({ type }) =>
        type > 0 ? "contain" : ""} !important; */
    background-repeat: no-repeat !important;
    background-position: center !important;

    border: 1px solid #dfe8ed;
    font-size: 18px;
    padding-left: 20px;

    border-radius: 5px;
`;
const Select = styled.select`
    width: 100%;
    background-color: #f4f5f8;
    border: 1px solid #dfe8ed;
    height: 60px;
    font-size: 18px;
    padding-left: 20px;
    border-radius: 5px;
    outline: none;
    &.auto {
        color: #adadad;
    }
`;
const RouteInfo = styled.div`
    background-color: #fff;
    margin-top: 20px;
    border: 1px solid #dfe8ed;
    border-radius: 8px;
    padding: 30px;
    width: 70%;
    margin: 0 auto;
`;
const Title = styled.h2`
    color: #0a0a0a;
    margin-bottom: 20px;
    font-size: 26px;
    width: 70%;
    margin: 0 auto;
    padding-bottom: 10px;
`;
const Button = styled.div`
    width: 170px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2382d4;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.cancel {
        background-color: #fff;
        border: 1px solid #2382d4;
        color: #2382d4;
        margin-right: 20px;
    }
`;
const SubmitButton = styled.input`
    width: 170px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2382d4;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.cancel {
        background-color: #fff;
        border: 1px solid #2382d4;
        color: #2382d4;
        margin-right: 20px;
    }
`;
const Form = styled.form``;
const ErrorMessage = styled.span`
    color: red;
    margin-bottom: 5px;
    text-align: right;
    width: 100%;
    display: inline-block;
    &::first-letter {
        text-transform: uppercase;
    }
`;
const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;
const CoverInfo = styled.div``;
const General = styled.h3`
    color: #0a0a0a;
    position: relative;
    overflow: hidden;
    font-size: 22px;
    margin-bottom: 30px;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 100px;
        background-color: #ebebeb;
        bottom: 10px;
    }
    &.veh {
        margin-top: 30px;
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            left: 260px;
            bottom: 10px;
            background-color: #ebebeb;
        }
    }
`;
const CoverForm = styled.div`
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
`;
const CoverIn = styled.div`
    width: 100%;
    margin-right: 20px;
    margin-bottom: 30px;
    &:nth-child(even) {
        margin-right: 0;
    }
    position: relative;
    &.add {
        width: 100%;
    }
`;
const Label = styled.h3`
    color: #747474;
    font-family: "inter_regular";
    margin-bottom: 10px;
`;
const Input = styled.input`
    width: 100%;
    background-color: #f4f5f8;
    border: 1px solid #dfe8ed;
    height: 60px;
    font-size: 18px;
    padding-left: 20px;
    border-radius: 5px;
    &.auto {
        color: #adadad;
    }
    &.file {
        height: 150px;
        opacity: 0;
    }
`;

const Icon = styled.div`
    color: #292c2d;
    position: absolute;
    right: 20px;
    top: 53px;
    cursor: pointer;
    @media all and (max-width: 400px) {
        right: 20px;
        top: 38px;
    }
`;
