import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import {
  add,
  curve,
  filterIcon,
  left_arrow,
  printerColor,
} from "../../../components/assets";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../context/Store";
import api from "../../../api";
import Loader from "../../../components/loader/Loader";
import Nodata from "../../includes/nodata/Nodata";
import ButtonLoader from "../../../components/button-loader/ButtonLoader";

function PriceSheet() {
  const [getPriceSheet, setPriceSheet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoader, setButtonLoader] = useState(false);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  // get accesstoken from store
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const startDate = user_data.start_date;
  const endDate = user_data.end_date;
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "sheet",
      },
    });
  }, []);

  useEffect(() => {
    setButtonLoader(true);
    api
      .get("activities/get-plant-milk-base-price/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setPriceSheet(res.app_data.data);
          setButtonLoader(false);
          setIsLoading(false);
        } else {
          setPriceSheet([]);
          setButtonLoader(false);
          setIsLoading(false);
        }
      });
  }, []);
  return (
    <Container>
      <div className="parent-scroll">
        <ListContainer className="scroll">
          <HeadRow>
            <HeadColumnData>Sl. no</HeadColumnData>
            <HeadColumnData>CLR</HeadColumnData>
            <HeadColumnData>FAT</HeadColumnData>
            <HeadColumnData>SNF</HeadColumnData>
            <HeadColumnData>Price</HeadColumnData>
          </HeadRow>
          <BottomContainer>
            {isLoading ? (
              <Loader />
            ) : getPriceSheet.length == 0 ? (
              <Nodata />
            ) : (
              getPriceSheet?.map((row, index) => (
                <Row key={index}>
                  <ColumnData className="bold">{index + 1}</ColumnData>
                  <ColumnData className="bold">{row.clr_value}</ColumnData>
                  <ColumnData>{row.fat_value}</ColumnData>
                  <ColumnData>{row.snf_value}</ColumnData>
                  <ColumnData>
                    <span>{row.price}</span>
                  </ColumnData>
                </Row>
              ))
            )}
          </BottomContainer>
        </ListContainer>
      </div>
    </Container>
  );
}

export default PriceSheet;

const Container = styled.div`
  padding: 20px;
`;
const TopBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h2`
  color: #0a0a0a;
  /* margin-bottom: 20px; */
  font-size: 26px;
`;

const NavButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Button = styled.div`
  width: 260px;
  height: 40px;
  background: #fff;
  color: #2382d4;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 16px;
  cursor: pointer;

  &.background {
    background-color: #2382d4;
    color: #fff;
    margin-left: 15px;
  }

  img {
    margin-right: 10px;
  }
`;

const BottomContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow-y: scroll;
`;

const ListContainer = styled.div`
  background-color: #fff;
  /* padding: 0 24px; */
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  /* height: calc(100vh - 300px); */
  margin-bottom: 20px;

  h3 {
    color: #747474;
    font-family: "inter_regular";
    font-size: 22px;
  }
`;

const HeadRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: 1px solid #ccc;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: 1px solid #ccc;
`;
const RoutePath = styled.h4`
  color: #747474;
  span {
    color: #2382d4;
  }
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  img {
    width: 100%;
    display: block;
  }
`;
const HeadColumnData = styled.div`
  color: #747474;
  font-size: 18px;
  font-family: "inter_light";
  width: 15%;
  &:first-child {
    width: 7%;
  }
  &:nth-last-child(3) {
    width: 25%;
  }
  &:nth-last-child(2) {
    width: 10%;
  }
  &:last-child {
    text-align: right;
  }
`;
const ColumnData = styled.div`
  color: #0a0a0a;
  font-size: 18px;
  font-family: "inter_medium";
  width: 15%;
  &:first-child {
    width: 7%;
  }
  &:nth-last-child(3) {
    width: 25%;
  }
  &:nth-last-child(2) {
    width: 10%;
  }
  &:last-child {
    text-align: right;
  }
  &.bold {
    font-family: "inter_regular";
  }
  span {
    color: #2382d4;
  }
`;
const TopBar = styled.div`
  padding: 20px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  border: 1px solid #dfe8ed;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
`;
const TopCover = styled.div`
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
`;
const Arrow = styled.div`
  border: 1px solid #2382d4;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const Text = styled.div``;
const TopTitle = styled.h2`
  text-align: center;
  margin-bottom: 10px;
  &.create {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10px;
    color: #0a0a0a;
  }
`;
