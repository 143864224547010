export const routes = [
  {
    id: "01",
    route_no: "001",
    route_name: "Kattappana",
    total_litres: 650,
    total_sociities: 15,
    total_farmers: 76,
  },
  {
    id: "02",
    route_no: "002",
    route_name: "Mundakkayam",
    total_litres: 540,
    total_sociities: 50,
    total_farmers: 74,
  },
  {
    id: "03",
    route_no: "003",
    route_name: "Kottayam",
    total_litres: 658,
    total_sociities: 40,
    total_farmers: 90,
  },
  {
    id: "04",
    route_no: "004",
    route_name: "Mundakkayam",
    total_litres: 458,
    total_sociities: 20,
    total_farmers: 79,
  },
  {
    id: "05",
    route_no: "005",
    route_name: "Kattappana",
    total_litres: 369,
    total_sociities: 56,
    total_farmers: 87,
  },
  {
    id: "06",
    route_no: "006",
    route_name: "Kottayam",
    total_litres: 158,
    total_sociities: 62,
    total_farmers: 41,
  },
  {
    id: "07",
    route_no: "007",
    route_name: "Mundakkayam",
    total_litres: 786,
    total_sociities: 32,
    total_farmers: 76,
  },
  {
    id: "08",
    route_no: "008",
    route_name: "Kattappana",
    total_litres: 650,
    total_sociities: 40,
    total_farmers: 54,
  },
  {
    id: "09",
    route_no: "009",
    route_name: "Kottayam",
    total_litres: 587,
    total_sociities: 20,
    total_farmers: 86,
  },
];

export const routs_societies = [
  {
    id: "01",
    society_id: "#JM123",
    society_name: "Kattappana co operative milk society 1234",
    president_name: "Sudhakaran TP",
    president_number: "+91 701 2121 235",
    location: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society_phone_number: "04868 271747",
    average_milk: "240 liters",
  },
  {
    id: "02",
    society_id: "#JM123",
    society_name: "Kattappana co operative milk society 1234",
    president_name: "Sudhakaran TP",
    president_number: "+91 701 2121 235",
    location: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society_phone_number: "04868 271747",
    average_milk: "240 liters",
  },
  {
    id: "03",
    society_id: "#JM123",
    society_name: "Kattappana co operative milk society 1234",
    president_name: "Sudhakaran TP",
    president_number: "+91 701 2121 235",
    location: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society_phone_number: "04868 271747",
    average_milk: "240 liters",
  },
  {
    id: "04",
    society_id: "#JM123",
    society_name: "Kattappana co operative milk society 1234",
    president_name: "Sudhakaran TP",
    president_number: "+91 701 2121 235",
    location: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society_phone_number: "04868 271747",
    average_milk: "240 liters",
  },
  {
    id: "05",
    society_id: "#JM123",
    society_name: "Kattappana co operative milk society 1234",
    president_name: "Sudhakaran TP",
    president_number: "+91 701 2121 235",
    location: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society_phone_number: "04868 271747",
    average_milk: "240 liters",
  },
  {
    id: "06",
    society_id: "#JM123",
    society_name: "Kattappana co operative milk society 1234",
    president_name: "Sudhakaran TP",
    president_number: "+91 701 2121 235",
    location: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society_phone_number: "04868 271747",
    average_milk: "240 liters",
  },
  {
    id: "07",
    society_id: "#JM123",
    society_name: "Kattappana co operative milk society 1234",
    president_name: "Sudhakaran TP",
    president_number: "+91 701 2121 235",
    location: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society_phone_number: "04868 271747",
    average_milk: "240 liters",
  },
  {
    id: "08",
    society_id: "#JM123",
    society_name: "Kattappana co operative milk society 1234",
    president_name: "Sudhakaran TP",
    president_number: "+91 701 2121 235",
    location: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society_phone_number: "04868 271747",
    average_milk: "240 liters",
  },
  {
    id: "01",
    society_id: "#JM123",
    society_name: "Kattappana co operative milk society 1234",
    president_name: "Sudhakaran TP",
    president_number: "+91 701 2121 235",
    location: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society_phone_number: "04868 271747",
    average_milk: "240 liters",
  },
  {
    id: "09",
    society_id: "#JM123",
    society_name: "Kattappana co operative milk society 1234",
    president_name: "Sudhakaran TP",
    president_number: "+91 701 2121 235",
    location: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society_phone_number: "04868 271747",
    average_milk: "240 liters",
  },
  {
    id: "10",
    society_id: "#JM123",
    society_name: "Kattappana co operative milk society 1234",
    president_name: "Sudhakaran TP",
    president_number: "+91 701 2121 235",
    location: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society_phone_number: "04868 271747",
    average_milk: "240 liters",
  },
];

export const payment_history = [
  {
    id: 1,
    amount: "₹25,000",
    date: "12 Jan, 2023",
  },
  {
    id: 2,
    amount: "₹5,000",
    date: "10 Mar, 2023",
  },
  {
    id: 3,
    amount: "₹55,000",
    date: "14 Feb, 2023",
  },
  {
    id: 4,
    amount: "₹52,000",
    date: "30 Apr, 2023",
  },
];

export const local_sale = [
  {
    id: 1,
    date: "12th January, 2023",
    quantity: "01",
    price: "36",
    total: "36",
  },
  {
    id: 2,
    date: "12th January, 2023",
    quantity: "01",
    price: "36",
    total: "36",
  },
  {
    id: 3,
    date: "12th January, 2023",
    quantity: "01",
    price: "36",
    total: "36",
  },
  {
    id: 4,
    date: "12th January, 2023",
    quantity: "01",
    price: "36",
    total: "36",
  },
  {
    id: 5,
    date: "12th January, 2023",
    quantity: "01",
    price: "36",
    total: "36",
  },
  {
    id: 6,
    date: "12th January, 2023",
    quantity: "01",
    price: "36",
    total: "36",
  },
  {
    id: 7,
    date: "12th January, 2023",
    quantity: "01",
    price: "36",
    total: "36",
  },
];

export const paymentlist = [
  {
    id: 1,
    amount: "₹10,000",
    transaction_id: "#1234567896454",
    date: "12 Jan, 2023",
    mode: "Bank transfer",
  },
  {
    id: 1,
    amount: "₹10,000",
    transaction_id: "#1234567896454",
    date: "12 Jan, 2023",
    mode: "Bank transfer",
  },
  {
    id: 1,
    amount: "₹10,000",
    transaction_id: "#1234567896454",
    date: "12 Jan, 2023",
    mode: "Bank transfer",
  },
  {
    id: 1,
    amount: "₹10,000",
    transaction_id: "#1234567896454",
    date: "12 Jan, 2023",
    mode: "Bank transfer",
  },
  {
    id: 1,
    amount: "₹10,000",
    transaction_id: "#1234567896454",
    date: "12 Jan, 2023",
    mode: "Bank transfer",
  },
  {
    id: 1,
    amount: "₹10,000",
    transaction_id: "#1234567896454",
    date: "12 Jan, 2023",
    mode: "Bank transfer",
  },
  {
    id: 1,
    amount: "₹10,000",
    transaction_id: "#1234567896454",
    date: "12 Jan, 2023",
    mode: "Bank transfer",
  },
  {
    id: 1,
    amount: "₹10,000",
    transaction_id: "#1234567896454",
    date: "12 Jan, 2023",
    mode: "Bank transfer",
  },
];

export const farmer_list = [
  {
    id: 1,
    farmer_id: "#JM123",
    farmer_name: "Sudhakaran P",
    society_name: "Kattappana co operative milk society 1234 ",
    address: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society: "Society 1",
    society_id: "#JM123",
    average_milk: "07",
  },
  {
    id: 2,
    farmer_id: "#JM123",
    farmer_name: "Sudhakaran P",
    society_name: "Kattappana co operative milk society 1234 ",
    address: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society: "Society 1",
    society_id: "#JM123",
    average_milk: "07",
  },
  {
    id: 3,
    farmer_id: "#JM123",
    farmer_name: "Sudhakaran P",
    society_name: "Kattappana co operative milk society 1234 ",
    address: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society: "Society 1",
    society_id: "#JM123",
    average_milk: "07",
  },
  {
    id: 4,
    farmer_id: "#JM123",
    farmer_name: "Sudhakaran P",
    society_name: "Kattappana co operative milk society 1234 ",
    address: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society: "Society 1",
    society_id: "#JM123",
    average_milk: "07",
  },
  {
    id: 5,
    farmer_id: "#JM123",
    farmer_name: "Sudhakaran P",
    society_name: "Kattappana co operative milk society 1234 ",
    address: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society: "Society 1",
    society_id: "#JM123",
    average_milk: "07",
  },
  {
    id: 6,
    farmer_id: "#JM123",
    farmer_name: "Sudhakaran P",
    society_name: "Kattappana co operative milk society 1234 ",
    address: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society: "Society 1",
    society_id: "#JM123",
    average_milk: "07",
  },
  {
    id: 7,
    farmer_id: "#JM123",
    farmer_name: "Sudhakaran P",
    farmer_number: "+91 701 2121 235",
    society_name: "Kattappana co operative milk society 1234 ",
    address: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society: "Society 1",
    society_id: "#JM123",
    average_milk: "07",
  },
  {
    id: 8,
    farmer_id: "#JM123",
    farmer_name: "Sudhakaran P",
    society_name: "Kattappana co operative milk society 1234 ",
    address: "Swaraj - Periyonkavala rd opposite CSI church swaraj",
    society: "Society 1",
    society_id: "#JM123",
    average_milk: "07",
  },
];

export const society_localsales = [
  {
    id: "01",
    date: "12th January, 2023",
    quantity: "01",
    price_per_litr: "36",
    total_amount: "36",
  },
  {
    id: "02",
    date: "12th January, 2023",
    quantity: "01",
    price_per_litr: "36",
    total_amount: "36",
  },
  {
    id: "03",
    date: "12th January, 2023",
    quantity: "01",
    price_per_litr: "36",
    total_amount: "36",
  },
  {
    id: "04",
    date: "12th January, 2023",
    quantity: "01",
    price_per_litr: "36",
    total_amount: "36",
  },
  {
    id: "05",
    date: "12th January, 2023",
    quantity: "01",
    price_per_litr: "36",
    total_amount: "36",
  },
  {
    id: "06",
    date: "12th January, 2023",
    quantity: "01",
    price_per_litr: "36",
    total_amount: "36",
  },
  {
    id: "07",
    date: "12th January, 2023",
    quantity: "01",
    price_per_litr: "36",
    total_amount: "36",
  },
  {
    id: "08",
    date: "12th January, 2023",
    quantity: "01",
    price_per_litr: "36",
    total_amount: "36",
  },
  {
    id: "09",
    date: "12th January, 2023",
    quantity: "01",
    price_per_litr: "36",
    total_amount: "36",
  },
  {
    id: "10",
    date: "12th January, 2023",
    quantity: "01",
    price_per_litr: "36",
    total_amount: "36",
  },
];
