import { useContext } from "react";
import api from "..";
import { Context } from "../../context/Store";
import { getYYMMDD } from "../../utils/functions/function";

export const CreatingUser = (formData, config) => {
  return api
    .post("accounts/create-plant-sub-profile/", formData, config)
    .then((res) => {
      return res;
    });
};

export const PrivilegeView = (config) => {
  return api.get("accounts/privilage-view/", config).then((res) => {
    return res;
  });
};

export const subUserList = (config) => {
  return api.get("accounts/view-plant-sub-profile/", config).then((res) => {
    return res;
  });
};

export const getPricePerLitre = (config) => {
  return api.get("activities/get-price-per-liter/", config).then((res) => {
    return res;
  });
};

export const editPricePerLitre = (priceid, priceFormData, config) => {
  return api
    .post(
      `activities/edit-price-per-liter-local-sale/${priceid}/`,
      priceFormData,
      config
    )
    .then((res) => {
      return res;
    });
};

export const getFarmerTotalMIlkStatus = (config, id, startDate, endDate) => {
  return api
    .get(
      `activities/total-milk-status-to-farmer/${id}`,
      {
        params: {
          society_id: id,
          from_date: getYYMMDD(startDate),
          to_date: getYYMMDD(endDate),
        },
      },
      config
    )
    .then((res) => {
      return res;
    });
};

export const editPlantArrivals = (id, priceFormData, config) => {
  return api
    .post(`activities/edit-plant-arraivals/${id}/`, priceFormData, config)
    .then((res) => {
      return res;
    });
};

export const getSinglePlantArrival = (id, config) => {
  return api
    .get(`activities/single-plant-arraival/${id}`, config)
    .then((res) => {
      return res;
    });
};
export const deletePlantArrival = (config, id) => {
  return api.post(`activities/delete-plant-arrivals/${id}/`, config).then((res) => {
    return res;
  });
};

export const getArrivals = (config) => {
  return api.get(`activities/plant-arraivals-list/`, config).then((res) => {
    return res;
  });
};

export const submitArrivals = (config) => {
  return api
    .post(`activities/submit-all-plant-arraival/`, config)
    .then((res) => {
      return res;
    });
};
export const submitSocietyArrivals = (config) => {
  return api.post(`activities/submit-society-arrivals/`, config).then((res) => {
    return res;
  });
};
export const deleteFarmerMilk = (config, id) => {
  return api.post(`activities/delete-farmer-milk/${id}/`, config).then((res) => {
    return res;
  });
};
