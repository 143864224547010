import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import {
  clr,
  curve,
  dropdown,
  fat,
  filter,
  milk,
  print,
  snf,
  societyIcon,
} from "../../../components/assets";
import InputCard from "../../../components/card/InputCard";
import { Context } from "../../../context/Store";
import { AddArrivals, updateMilkRate } from "../../../api/auth";
import Swal from "sweetalert2";
import api from "../../../api";
import Loader from "../../../components/loader/Loader";
import ButtonLoader from "../../../components/button-loader/ButtonLoader";
import { Navigate, useNavigate } from "react-router-dom";

function Arrivals() {
  const [societyId, setSocietyId] = useState("");
  const [societyName, setSocietyName] = useState("");
  const [totalMilk, setTotalMilk] = useState("");
  const [clrValue, setClrValue] = useState("");
  const [fatValue, setFatValue] = useState("");
  const [nofCans, setNoofCans] = useState("");
  const [getTotal, setTotal] = useState({});
  const [query, setQuery] = useState("");
  const [isShow, setShow] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [isCalculate, setCalculate] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [damagedMilk, setDamagedMilk] = useState("");
  const [canDetails, setCanDetails] = useState([]);
  const [timeType, setTimeType] = useState("am");

  const handleSelectChange = (event) => {
    setTimeType(event.target.value);
  };

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "arrivals",
      },
    });
  }, []);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  // const confirmSubmit = (e) => {
  //     Swal.fire({
  //         title: "Are you sure to proceed?",
  //         showDenyButton: true,
  //         denyButtonText: `No`,
  //         confirmButtonText: "Yes",
  //         showLoaderOnConfirm: true,
  //         preConfirm: async (login) => {
  //             try {
  //               handleSubmit(e);
  //             } catch (error) {
  //                 console.log("error", error);
  //             }
  //         },
  //         allowOutsideClick: () => !Swal.isLoading(),
  //     }).then((result) => {
  //         /* Read more about isConfirmed, isDenied below */
  //         if (result.isConfirmed) {
  //             // handleSubmit(e);

  //             if (!isCalculate) {
  //               handleClear();
  //             }
  //             navigate("/arrivals");
  //         }
  //     });
  // };
  const confirmSubmit = (e) => {
    Swal.fire({
      title: "Are you sure to proceed?",
      showDenyButton: true,
      denyButtonText: `No`,
      confirmButtonText: "Yes",
      // Removed showLoaderOnConfirm and preConfirm for direct handling
    }).then((result) => {
      if (result.isConfirmed) {
        // Assuming handleSubmit returns a promise
        handleSubmit(e)
          .then(() => {
            // This block executes if handleSubmit resolves successfully
            if (!isCalculate) {
              handleClear();
            }
            navigate("/arrivals");
          })
          .catch((error) => {
            // Handle any errors that occurred during handleSubmit
            console.error("Submission error", error);
            // Optionally, show an error message to the user
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    });
  };
  //payload
  const formData = new FormData();
  formData.append("total_milk", totalMilk);
  formData.append("clr_value", clrValue);
  formData.append("fat_value", fatValue);
  formData.append("society_id", societyId);
  formData.append("time_status", timeType);

  if (!isCalculate) {
    formData.append("is_confirmed", "True");
  }
  if (damagedMilk) {
    formData.append("damaged_milk_quantity", damagedMilk);
  }
  formData.append("cans", nofCans);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonLoading(true);

    if (!societyId) {
      setErrorMessage("Please enter society ID");
      setIsButtonLoading(false);
    } else if (!nofCans) {
      setErrorMessage("Please enter No. of cans");
      setIsButtonLoading(false);
    } else if (!totalMilk) {
      setErrorMessage("Please enter total milk");
      setIsButtonLoading(false);
    } else if (!totalMilk) {
      setErrorMessage("Please enter total milk");
      setIsButtonLoading(false);
    } else if (damagedMilk.length < 0 && !clrValue) {
      setErrorMessage("Please enter CLR value");
      setIsButtonLoading(false);
    } else if (damagedMilk.length < 0 && !fatValue) {
      setErrorMessage("Please enter FAT value");
      setIsButtonLoading(false);
    } else {
      return new Promise((resolve, reject) => {
        // isCalculate &&
        AddArrivals(config, formData)
          .then((res) => {
            if (res.app_data.StatusCode == 6000) {
              setIsButtonLoading(false);
              setSubmit(true);
              setTotal(res.app_data.data);
              setErrorMessage("");
              setCalculate(false);
              // if (!isCalculate) {
              //   handleClear();
              // }
              resolve();
            } else {
              setErrorMessage(res.app_data.message);
              setIsButtonLoading(false);
              setCalculate(true);
              setSubmit(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  };
  const saveandAdd = (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    if (!societyId) {
      setErrorMessage("Please enter society ID");
      setIsButtonLoading(false);
    } else if (!nofCans) {
      setErrorMessage("Please enter No. of cans");
      setIsButtonLoading(false);
    } else if (!totalMilk) {
      setErrorMessage("Please enter total milk");
      setIsButtonLoading(false);
    } else if (!totalMilk) {
      setErrorMessage("Please enter total milk");
      setIsButtonLoading(false);
    } else if (damagedMilk.length < 0 && !clrValue) {
      setErrorMessage("Please enter CLR value");
      setIsButtonLoading(false);
    } else if (damagedMilk.length < 0 && !fatValue) {
      setErrorMessage("Please enter FAT value");
      setIsButtonLoading(false);
    } else {
      AddArrivals(config, formData).then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setIsButtonLoading(false);
          setSubmit(true);
          setTotal(res.app_data.data);
          setErrorMessage("");
          setCalculate(false);
          if (!isCalculate) {
            handleClear();
          }
        } else {
          setErrorMessage(res.app_data.data.message);
          setIsButtonLoading(false);
          setCalculate(true);
          setSubmit(false);
        }
      });
    }
  };
  useEffect(() => {
    api
      .get(`accounts/societies/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          q: query,
          list_by: !query ? "all" : "",
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode === 6000) {
          setSearchResult(res.app_data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setSearchResult([]);
        }
      });
  }, [query]);
  const handleClear = (e) => {
    setNoofCans("");
    setTotalMilk("");
    setClrValue("");
    setDamagedMilk("");
    setFatValue("");
    setTotal("");
    setSocietyId("");
    setQuery("");
    setSocietyName("");
    setSubmit(true);
    setCalculate(true);
  };

  //can details
  useEffect(() => {
    api
      .get("activities/get-society-can-detals", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          society: societyId,
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode === 6000) {
          setCanDetails(res.app_data.data);
        } else {
          setCanDetails([]);
        }
      });
  }, [societyId]);

  return isLoading ? (
    <Loader />
  ) : (
    <Container>
      <TopBox>
        <Title>Arrivals</Title>
      </TopBox>
      <BottomBox
        onSubmit={(e) => {
          e.preventDefault();
          isCalculate && handleSubmit(e);
        }}
      >
        <QuantityContainer>
          <NameBox>
            <SocietyBox>
              <Label>Society ID</Label>
              <input
                className="id"
                type="text"
                placeholder="Society ID"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                  setShow(true);
                }}
                required
              />
              {isShow && (
                <SearchResults>
                  {searchResult?.length == 0 ? (
                    <NoResult>No Societies</NoResult>
                  ) : (
                    searchResult?.map((item, index) => (
                      <Result
                        onClick={() => {
                          setSocietyName(item.name);
                          setQuery(item.code);
                          setSocietyId(item.id);
                          setShow(false);
                        }}
                        key={index}
                      >
                        {item.name}{" "}
                      </Result>
                    ))
                  )}
                </SearchResults>
              )}
            </SocietyBox>
            <SocietyBox>
              <Label>Society name*</Label>
              <input
                type="text"
                placeholder="Society Name"
                value={societyName}
                // onChange={(e) => setSocietyName(e.target.value)}
                disabled
              />
            </SocietyBox>
            <SocietyBox>
              <Label>AM/PM</Label>
              <Select value={timeType} onChange={handleSelectChange}>
                <option value="am">AM</option>
                <option value="pm">PM</option>
              </Select>
            </SocietyBox>
          </NameBox>
          {canDetails.length > 0 && (
            <>
              <DetailBox>
                <heading> Can details from society</heading>
                <HrLine></HrLine>
              </DetailBox>
              <BoxContainer>
                <BoxPart>
                  <BoxTitle>Total Milk Status (AM)</BoxTitle>
                </BoxPart>
                <BoxPart>
                  <BoxTitle>Total Milk Status (PM)</BoxTitle>
                </BoxPart>
                <BoxPart>
                  <Can>
                    <CanDetails>
                      <CanNo>No of cans</CanNo>
                      <Number>{canDetails[0]?.am?.no_of_cans}</Number>
                    </CanDetails>
                    <CanRight>
                      <CanNo>Milk quantity</CanNo>
                      <Number>{canDetails[0]?.am?.total_milk_quantity}</Number>
                    </CanRight>
                  </Can>
                </BoxPart>
                <BoxPart>
                  <Can>
                    <CanDetails>
                      <CanNo>No of cans</CanNo>
                      <Number>{canDetails[0]?.pm.no_of_cans} </Number>
                    </CanDetails>
                    <CanRight>
                      <CanNo>Milk quantity</CanNo>
                      <Number>{canDetails[0]?.pm.total_milk_quantity}</Number>
                    </CanRight>
                  </Can>
                </BoxPart>
              </BoxContainer>
            </>
          )}
          <TitleBox>
            <TitleQuantity>Quantity</TitleQuantity>
            <Line></Line>
          </TitleBox>
          <CoverCard>
            <InputCard
              title="No of Cans"
              icon={societyIcon}
              value={nofCans}
              setState={setNoofCans}
              label="Nos"
            />
            <InputCard
              title="Total milk"
              icon={societyIcon}
              value={totalMilk}
              setState={setTotalMilk}
              type="convert"
              label="kg"
            />
            <InputCard
              title="Damaged milk"
              icon={societyIcon}
              value={damagedMilk}
              setState={setDamagedMilk}
              type="convert"
              number="11"
              label="Kg"
            />
          </CoverCard>
        </QuantityContainer>
        <QuantityContainer>
          <TitleBox>
            <TitleQuantity>Quality</TitleQuantity>
            <HrLine></HrLine>
          </TitleBox>
          <CoverCard>
            <InputCard
              title="CLR value"
              setState={setClrValue}
              value={clrValue}
            />
            <InputCard
              title="FAT value"
              setState={setFatValue}
              value={fatValue}
            />
          </CoverCard>
        </QuantityContainer>
        <CalculationContainer>
          <ValueBox>
            <SNFBox>
              <Label>SNF value</Label>
              <LabelValue>
                {getTotal.snf_value ? getTotal?.snf_value : "0"}
              </LabelValue>
            </SNFBox>
            <PerLiterBox>
              <Label>Price per liter</Label>
              <LabelValue>
                {getTotal.price_per_liter ? getTotal?.price_per_liter : "0"}
              </LabelValue>
            </PerLiterBox>
          </ValueBox>
          <HrLine></HrLine>
          <PriceBox>
            <TotalPrice>Total price</TotalPrice>
            <TotalPriceValue>
              ₹ {getTotal.actual_price ? getTotal?.actual_price : "0"}
            </TotalPriceValue>
          </PriceBox>
          <ErrorMessage>{errorMessage}</ErrorMessage>
          <CoverButton>
            {!isCalculate && (
              <>
                <ReCalc onClick={(e) => setCalculate(true)}>
                  Re-calculate
                </ReCalc>
                <ButtonNew onClick={(e) => saveandAdd(e)}>
                  Save and Add Another
                </ButtonNew>
              </>
            )}
            <ButtonNew
              onClick={(e) => {
                !isCalculate && confirmSubmit(e);
              }}
            >
              {isCalculate ? "Calculate" : "Submit Data"}
            </ButtonNew>
          </CoverButton>
        </CalculationContainer>
      </BottomBox>
    </Container>
  );
}

export default Arrivals;

const CoverButton = styled.div`
  display: flex;
  justify-content: end;
  /* margin-top: 50px; */
  gap: 20px;
`;
const ReCalc = styled.div`
  background: #2382d4;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
`;
const ButtonNew = styled.button`
  background: #2382d4;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
`;

const Container = styled.div`
  padding: 20px;
  max-height: calc(100vh - 95px);
  overflow: scroll;
  border-radius: 8px;
  /* @media (max-width: 1080px) {
    max-height: calc(100vh - 230px);
  } */
`;
const SearchResults = styled.div`
  height: 300px;
  overflow: scroll;
  background-color: #f2f2f2;
  position: absolute;
  border-radius: 0 0 20px 20px;
  top: 92px;
  width: 100%;
  z-index: 10;
`;
const NoResult = styled.div`
  /* background-color: #e5e5e5; */
  padding: 20px;
  /* cursor: pointer; */
  margin-bottom: 10px;
`;
const Result = styled.div`
  background-color: #e5e5e5;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 10px;
`;
const TopBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 50%; */
`;
const Title = styled.h2`
  color: #0a0a0a;
  /* margin-bottom: 20px; */
  font-size: 26px;
`;
const SubmitButton = styled.div`
  width: 160px;
  height: 40px;
  background: ${({ type }) => (type ? "#000" : "#2382d4")};
  color: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  padding: 6px 20px;
  /* font-family:'DM Sans'; */
`;
const Button = styled.input`
  width: 160px;
  height: 40px;
  background: ${({ type }) => (type ? "#000" : "#2382d4")};
  color: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  padding: 6px 20px;
  /* font-family:'DM Sans'; */
`;
const BottomBox = styled.form`
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  background-color: #fff;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  padding: 40px;
`;

const QuantityContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;
const NameBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
const SocietyBox = styled.div`
  width: 30%;
  display: inline-block; /* Display inline to have them in the same row */
  margin-right: 10px; /* Add some margin for spacing */
  position: relative;
  @media (max-width: 640px) {
    width: 100%;
  }
  input,
  select {
    padding: 18px 24px;
    border: 1px solid #dfe8ed;
    border-radius: 4px;
    width: 100%;
    margin: 10px 0;
    font-size: 18px;
    font-family: "inter_light";
    &.id {
      text-transform: capitalize;
    }
  }
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const TitleQuantity = styled.div`
  margin-right: 10px;
`;
const HrLine = styled.div`
  width: 83%;
  height: 1px;
  background-color: #eee;
`;

const CoverCard = styled.div`
  display: flex;
  @media (max-width: 820px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  @media (max-width: 640px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
`;
const CalculationContainer = styled.div`
  margin-left: auto;
  width: 50%;
`;

const ValueBox = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const SNFBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
`;
const Select = styled.select`
  padding: 18px 24px;
  border: 1px solid #dfe8ed;
  border-radius: 4px;
  width: 100%;
  margin: 10px 0;
  outline: none;
  background-color: unset;
  font-size: 18px;
  font-family: "inter_light";

  @media (max-width: 639px) {
    font-size: 13px;
  }
`;
const Label = styled.h4`
  color: #747474;
  font-family: "inter_light";
  font-weight: unset;
  font-size: 18px;
  white-space: nowrap;
  @media (max-width: 639px) {
    font-size: 15px;
  }
`;

const LabelValue = styled.span`
  width: 30%;
  text-align: right;
  font-size: 22px;
`;

const PerLiterBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
`;

const PriceBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 14px 0;
`;

const TotalPrice = styled.h4`
  color: #747474;
  font-family: "inter_regular";
  font-weight: unset;
  font-size: 20px;
`;
const TotalPriceValue = styled.span`
  font-size: 26px;
  width: 30%;
  text-align: right;
  color: #2382d4;
`;
const ErrorMessage = styled.span`
  color: red;
  margin-bottom: 5px;
  text-align: right;
  width: 100%;
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
`;
const DetailBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Line = styled.div`
  width: 83%;
  height: 1px;
  background-color: #eee;
`;
const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 1209px;
  height: 128px;
  border: 1px solid #c9c9c9;
  margin-bottom: 25px;
`;

const BoxPart = styled.div`
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
`;
const BoxTitle = styled.div`
  color: #747474;
  font-family: "inter_light";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-weight: bold;
`;

const Can = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 169px; /* Adjust the margin as needed */
  }
`;

const CanDetails = styled.div`
  display: flex;
  gap: 20px;
`;

const CanRight = styled.div`
  display: flex;
  gap: 20px;
`;

const CanNo = styled.div`
  color: #747474;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "inter_light";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 39px;
`;

const Number = styled.div`
  color: #0a0a0a;
  font-size: 32px;
  font-family: "inter_light";
  font-weight: bold;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
