import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/reducers/auth/userSlice";
import { loginFunction } from "../../../api/auth";
import { Header, Notifications, Sidebar } from "../../includes";
import { styled } from "styled-components";
import { Route, Routes } from "react-router-dom";
import Statics from "../Statics";
import MilkStatus from "../Milk-status";
import Routs from "../Routs";
import SingleRoute from "../Routs/singlepage";
import RouteSocities from "../Routs/socities";
import SocityPage from "../Routs/societypage";
import LocalSales from "../Routs/localsales";
import PaymentList from "../Routs/paymentList";
import FarmerList from "../Routs/farmers";
import FarmerPage from "../Routs/farmers/farmersinglepage";
import Arrivals from "../Arrivals";
import { Bonus, Incentives, Loans, MilkRate } from "../Payments";
import AddBonus from "../Payments/Bonus/AddBonus";
import AddIncentive from "../Payments/Incentives/AddIncentive";
import AddLoan from "../Payments/Loans/AddLoan";
import ViewLoan from "../Payments/Loans/ViewLoan";
import CreateFarmer from "../creation/CreateFarmer";
import CreateRoute from "../creation/CreateRoute";
import CreateSociety from "../creation/CreateSociety";
import CreateSubUser from "../creation/CreateSubUser";
import PlantSociety from "../socities";
import PlantSocietySingle from "../socities/singlepage";
import PlantSocietyFarmers from "../socities/farmers";
import PlantSocietyFarmerSinglePage from "../socities/farmers/singlepage";
import SocietyLocalSales from "../socities/localsales";
import PlantFarmers from "../farmers";
import PlantFarmerSingle from "../farmers/singlepage";
import SocietyPaymentList from "../socities/payment";
import FarmerPaymentList from "../farmers/payment";
import UserCreated from "../user/UserCreated";
import SubUser from "../user/SubUser";
import ServiceRoute from "../../../routes/routes/ServiceRoute";
import ViewEditBonus from "../Payments/Bonus/ViewEditBonus";
import ViewEditIncentive from "../Payments/Incentives/ViewEditIncentive";
import MilkStatusDetail from "../socities/singlepage/milk-status-detail/MilkStatusDetail";
import MilkStatusDetailSociety from "../socities/singlepage/milk-status-detail/MilkStatusDetailSociety";
import PriceSheet from "../sheet/PriceSheet";
import MilkStatusDetailFarmer from "../socities/singlepage/milk-status-detail/MilkStatusDetailFarmer";
import ArrivalList from "../creation/ArrivalList";
import EditArrivals from "../Arrivals/edit-arrivals";
import MilkReport from "../milk-report/MilkReport";
import ProductsList from "../products/ProductsList";
import Orders from "../products/Orders";
import AddProject from "../products/AddProduct";
import AddProduct from "../products/AddProduct";
import PaymentReport from "../Payments/Reports/PaymentReport";
import BankDetails from "../Payments/Reports/BankDetails";
import AddOrder from "../products/AddOrder";
import ProcessedPayment from "../Payments/Reports/ProcessedPayment";
import CompletedPayments from "../Payments/Reports/CompletedPayments";
import PaymentHistory from "../Payments/Reports/PaymentHistory";
import MilkView from "../milk-report/MilkView";
import EditProduct from "../products/EditProduct";
import Insurance from "../insurance/Insurance";
import AddInsurance from "../insurance/AddInsurance";
import TagHistory from "../insurance/Taghistory";
import Renewal from "../insurance/renewal/Renewal";
import Notification from "../notification/Notification";
import EditInsurance from "../insurance/EditInsurance";
import ViewInsurance from "../insurance/ViewInsurance";
import Cows from "../../society-admin/farmer/farmer-single/Cows";
import PrintMilkReport from "../milk-report/PrintMilkReport";

const Dashboard = () => {
  const [isHovered, setHovered] = useState(false);
  return (
    <Container>
      <Left
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={isHovered && "active"}
      >
        <Hamburger onClick={() => setHovered(!isHovered)}>
          <i class="ri-menu-line"></i>
        </Hamburger>
        <Sidebar isHovered={isHovered} />
      </Left>
      <Header />
      <Right className={isHovered && "active"}>
        <Routes>
          {/* <Route path="/" element={<Header />}> */}
          <Route
            path="/"
            element={
              <ServiceRoute urls="dashboard">
                <Statics />
              </ServiceRoute>
            }
          />
          <Route path="/arrivals" element={<ArrivalList />} />
          <Route
            path="/milk-status"
            element={
              <ServiceRoute urls="milk_status">
                <MilkStatus />
              </ServiceRoute>
            }
          />
          <Route
            path="/milk-status/detail"
            element={
              <ServiceRoute urls="milk_status">
                <MilkStatusDetail />
              </ServiceRoute>
            }
          />
          <Route
            path="/add-arrivals"
            element={
              <ServiceRoute urls="arrivals">
                <Arrivals />
              </ServiceRoute>
            }
          />
          <Route
            path="/edit-arrivals/:id"
            element={
              <ServiceRoute urls="arrivals">
                <EditArrivals />
              </ServiceRoute>
            }
          />
          <Route
            path="/add-bonus"
            element={
              <ServiceRoute urls="payments">
                <AddBonus />
              </ServiceRoute>
            }
          />
          <Route
            path="/processed-payments"
            element={
              <ServiceRoute urls="payments">
                <ProcessedPayment />
              </ServiceRoute>
            }
          />
          <Route
            path="/completed-payment-report"
            element={
              <ServiceRoute urls="payments">
                <CompletedPayments />
              </ServiceRoute>
            }
          />
          <Route
            path="/bonus"
            element={
              <ServiceRoute urls="payments">
                <Bonus />
              </ServiceRoute>
            }
          />
          <Route
            path="/payment-history"
            element={
              <ServiceRoute urls="payments">
                <PaymentHistory />
              </ServiceRoute>
            }
          />
          <Route
            path="/view-edit-bonus"
            element={
              <ServiceRoute urls="payments">
                <ViewEditBonus />
              </ServiceRoute>
            }
          />
          <Route
            path="/add-incentive"
            element={
              <ServiceRoute urls="payments">
                <AddIncentive />
              </ServiceRoute>
            }
          />
          <Route
            path="/view-edit-incentive"
            element={
              <ServiceRoute urls="payments">
                <ViewEditIncentive />
              </ServiceRoute>
            }
          />
          <Route
            path="/incentives"
            element={
              <ServiceRoute urls="payments">
                <Incentives />
              </ServiceRoute>
            }
          />
          <Route
            path="/add-loan"
            element={
              <ServiceRoute urls="payments">
                <AddLoan />
              </ServiceRoute>
            }
          />
          <Route
            path="/view-loan/:id"
            element={
              <ServiceRoute urls="payments">
                <ViewLoan />
              </ServiceRoute>
            }
          />
          <Route
            path="/loans"
            element={
              <ServiceRoute urls="payments">
                <Loans />
              </ServiceRoute>
            }
          />
          <Route
            path="/milk-rate"
            element={
              <ServiceRoute urls="payments">
                <MilkRate />
              </ServiceRoute>
            }
          />
          <Route path="/notifications" element={<Notifications />} />
          <Route
            path="/routes"
            element={
              <ServiceRoute urls="routes">
                <Routs />
              </ServiceRoute>
            }
          />
          <Route
            path="/routes/:id"
            element={
              <ServiceRoute urls="routes">
                <SingleRoute />
              </ServiceRoute>
            }
          />
          <Route
            path="/routes/:id/societies"
            element={
              <ServiceRoute urls="routes">
                <RouteSocities />
              </ServiceRoute>
            }
          />
          <Route
            path="/routes/:id/societies/:id"
            element={
              <ServiceRoute urls="routes">
                <SocityPage />
              </ServiceRoute>
            }
          />

          <Route
            path="/societies/:id/localsales"
            element={
              <ServiceRoute urls="socities">
                <LocalSales />
              </ServiceRoute>
            }
          />
          <Route
            path="/routes/:id/societies/:id/paymentlist"
            element={
              <ServiceRoute urls="routes">
                <PaymentList />
              </ServiceRoute>
            }
          />

          <Route
            path="/routes/:id/farmers"
            element={
              <ServiceRoute urls="routes">
                <FarmerList />
              </ServiceRoute>
            }
          />
          <Route
            path="/routes/:id/farmers/:id"
            element={
              <ServiceRoute urls="routes">
                <FarmerPage />
              </ServiceRoute>
            }
          />

          <Route
            path="/farmers"
            element={
              <ServiceRoute urls="farmers">
                <FarmerList />
              </ServiceRoute>
            }
          />
          <Route
            path="/societies"
            element={
              <ServiceRoute urls="socities">
                <RouteSocities />
              </ServiceRoute>
            }
          />
          {/* --------society----------- */}
          <Route
            path="/total-societies"
            element={
              <ServiceRoute urls="socities">
                <PlantSociety />
              </ServiceRoute>
            }
          />
          <Route
            path="/total-societies/:id"
            element={
              <ServiceRoute urls="socities">
                <PlantSocietySingle />
              </ServiceRoute>
            }
          />
          <Route
            path="/sheet"
            element={
              <ServiceRoute urls="milk_price_sheet">
                <PriceSheet />
              </ServiceRoute>
            }
          />
          <Route
            path="/total-societies/:id/detail"
            element={
              <ServiceRoute urls="socities">
                <MilkStatusDetailSociety />
              </ServiceRoute>
            }
          />
          <Route
            path="/total-societies/:id/farmers"
            element={
              <ServiceRoute urls="socities">
                <PlantSocietyFarmers />
              </ServiceRoute>
            }
          />
          <Route
            path="/total-societies/:id/farmers/:id"
            element={
              <ServiceRoute urls="socities">
                <PlantSocietyFarmerSinglePage />
              </ServiceRoute>
            }
          />
          <Route
            path="/total-societies/:id/localsales"
            element={
              <ServiceRoute urls="socities">
                <SocietyLocalSales />
              </ServiceRoute>
            }
          />

          <Route
            path="/total-societies/:id/payments"
            element={
              <ServiceRoute urls="socities">
                <SocietyPaymentList />
              </ServiceRoute>
            }
          />

          <Route
            path="/payment-reports"
            element={
              <ServiceRoute urls="socities">
                <PaymentReport />
              </ServiceRoute>
            }
          />
          <Route
            path="/bank-details"
            element={
              <ServiceRoute urls="socities">
                <BankDetails />
              </ServiceRoute>
            }
          />
          {/* ---------------farmers--------------- */}
          <Route
            path="/plant-farmers"
            element={
              <ServiceRoute urls="farmers">
                <PlantFarmers />
              </ServiceRoute>
            }
          />
          <Route
            path="/plant-farmers/:id"
            element={
              <ServiceRoute urls="farmers">
                <PlantFarmerSingle />
              </ServiceRoute>
            }
          />
          <Route
            path="/plant-farmers/:id/detail"
            element={
              <ServiceRoute urls="farmers">
                <MilkStatusDetailFarmer />
              </ServiceRoute>
            }
          />
          <Route
            path="/plant-farmers/:id/payment"
            element={
              <ServiceRoute urls="farmers">
                <FarmerPaymentList />
              </ServiceRoute>
            }
          />
          <Route path="/cows/:id" element={<Cows />} />

          {/* ----------creation---------- */}
          <Route
            path="/create-farmer"
            element={
              <ServiceRoute urls="farmers">
                <CreateFarmer />
              </ServiceRoute>
            }
          />

          <Route
            path="/create-route"
            element={
              <ServiceRoute urls="routes">
                <CreateRoute />
              </ServiceRoute>
            }
          />
          <Route
            path="/create-society"
            element={
              <ServiceRoute urls="socities">
                <CreateSociety />
              </ServiceRoute>
            }
          />
          {/* --------insurance----------- */}
          <Route path="/insurance" element={<Insurance />} />
          <Route path="/view-insurance/:id" element={<ViewInsurance />} />
          <Route
            path="/add-insurance"
            element={
              <ServiceRoute urls="insurance">
                <AddInsurance />
              </ServiceRoute>
            }
          />

          <Route
            path="/taghistory/:id"
            element={
              <ServiceRoute urls="insurance">
                <TagHistory />
              </ServiceRoute>
            }
          />
          <Route path="/renewal" element={<Renewal />} />
          <Route path="/edit-insurance/:id" element={<EditInsurance />} />
          <Route path="/create-user" element={<CreateSubUser />} />
          <Route path="/user-created" element={<UserCreated />} />
          <Route path="/sub-user" element={<SubUser />} />
          <Route path="/milk-report" element={<MilkReport />} />
          <Route path="/milk-report/print" element={<PrintMilkReport />} />
          <Route path="/milk-view" element={<MilkView />} />
          <Route path="/products" element={<ProductsList />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/add-product" element={<AddProduct />} />
          <Route path="/edit-product" element={<EditProduct />} />
          <Route path="/add-order" element={<AddOrder />} />
        </Routes>
      </Right>
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  height: 100vh;
  background: #f4f5f8;
  display: flex;
`;
const Left = styled.div`
  width: 5%;
  height: 100%;
  position: relative;
  transition: 0.4s ease;
  z-index: 100;
  &.active {
    width: 16%;
    transition: 0.4s ease;
  }
  @media (max-width: 1080px) {
    width: 6%;
    &.active {
      width: 22%;
    }
  }
  @media (max-width: 768px) {
    width: 9%;
    &.active {
      width: 32%;
    }
  }
  @media (max-width: 640px) {
    width: 12%;
    &.active {
      width: 34%;
    }
  }
`;
const Right = styled.div`
  width: 100%;
  max-height: 100vh;
  overflow-x: hidden;
  margin-top: 80px;
  overflow-y: scroll;
  /* @media (max-width: 1080px) {
    min-width: 1280px;
    overflow-x: scroll;
  } */
`;
const Hamburger = styled.div`
  position: absolute;
  color: #585656;
  cursor: pointer;
  right: -32px;
  top: 20px;
  font-size: 28px;
`;
