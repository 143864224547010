import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { add } from "../../../components/assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import NodataFound from "../../includes/nodata/NodataFound";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import NotificatioModal from "./NotificationModal";
import api from "../../../api";
import { Context } from "../../../context/Store";
import NotificationModal from "./NotificationModal";
import Swal from "sweetalert2";

function Insurance() {
  const [getData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [render, setRender] = useState(false);
  const ModalTabClick = () => {
    // Open the modal when the "New Tag" tab is clicked
    setIsModalOpen(true);
  };

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Notification",
      },
    });
  }, []);
  // get accesstoken from store
  const token = user_data.access_token;
  //notification listing
  useEffect(() => {
    api
      .get(
        `activities/list-plant-notification/`,
        {
          params: {
            renewed_type: "",
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.app_data.StatusCode === 6000) {
          setData(res.app_data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setData([]);
        }
      });
  }, [render, isModalOpen]);

  const confirmDelete = (item) => {
    setRender(true);
    Swal.fire({
      title: "Are you sure to proceed?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDelete(item);
      }
    });
  };

  const handleDelete = (item) => {
    api.post(`activities/delete-notifications/${item.id}/`, {}).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        setRender(false);
      } else {
      }
    });
  };
  return (
    <Container>
      <Top>
        <h3>Notification</h3>
        <Button onClick={() => setIsModalOpen(true)}>
          <img src={add} alt="notification" />
          Add Notification
        </Button>
      </Top>
      <div className="parent-scroll">
        <Bottom className="scroll">
          <TableHead>
            <TableItem>Sl No.</TableItem>
            <TableItem>Heading</TableItem>
            <TableItem>Message</TableItem>
            <TableItem>Action</TableItem>
          </TableHead>
          <CoverContainer>
            {isLoading ? (
              <Loader />
            ) : getData.length > 0 ? (
              getData.map((item, index) => (
                <TableContent key={index}>
                  <TableItem>{index + 1}</TableItem>
                  <TableItem>{item.heading}</TableItem>
                  <TableItem>{item.message}</TableItem>
                  <TableItem>
                    {/* <EditIcon>
                    <ModeEditOutlineOutlinedIcon />
                  </EditIcon> */}
                    <DeleteIcon onClick={() => confirmDelete(item)}>
                      <i class="ri-delete-bin-6-line"></i>
                    </DeleteIcon>
                  </TableItem>
                </TableContent>
              ))
            ) : (
              <NodataFound />
            )}
          </CoverContainer>
        </Bottom>
      </div>
      <NotificationModal isOpen={isModalOpen} onClose={setIsModalOpen} />
    </Container>
  );
}

export default Insurance;

const Container = styled.div`
	margin-top: 20px;
	padding: 0px 15px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
`;

const Top = styled.div`
	margin: 0px;
	padding: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Button = styled.div`
	width: 175px;
	height: 40px;
	background: #fff;
	color: #2382d4;
	align-items: center;
	display: flex;
	justify-content: center;
	border: 1px solid #2382d4;
	border-radius: 4px;
	font-size: 14px;
	padding: 6px 16px;
	cursor: pointer;

	&.background {
		background-color: #2382d4;
		color: #fff;
		margin-left: 15px;
	}

	img {
		margin-right: 10px;
	}
`;

const Tabs = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const Tab = styled(Link)`
	border-top: 1px solid #dfe8ed;
	text-decoration: none;
	border-left: 1px solid #dfe8ed;
	border-right: 1px solid #dfe8ed;
	border-bottom: 1px solid #dfe8ed;
	padding: 14px 20px;
	cursor: pointer;
	margin-right: 30px;
	color: #797d82;
	border-radius: 5px 5px 0 0;

	&.active {
		color: #2382d4;
		background-color: #e0f3ff;
		border-top: 1px solid #2382d4;
		border-left: 1px solid #2382d4;
		border-right: 1px solid #2382d4;
		border-bottom: 3px solid #2382d4;
	}
`;

const Bottom = styled.div`
	padding-top: 15px;
`;
const TableHead = styled.div`
	height: 70px;
	background: #fff;
	padding: 0 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
`;

const TableItems = styled.div`
	width: 10%;
	color: #000;
	font-size: 18px;
`;

const TableContainer = styled.div`
	height: 70px;
	background: #fff;
	padding: 0 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 15px;
`;

const CoverContainer = styled.div`
	max-height: calc(100vh - 250px);
	overflow: auto;
`;

const TableContent = styled.div`
	height: 70px;
	background: #fff;
	padding: 0 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;

const EditIcon = styled.div`
	color: #747474;
	font-size: 24px;
	cursor: pointer;
	padding: 0;
	margin-right: 10px;
	border-radius: 5px;
	height: 29px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const DeleteIcon = styled.div`
	color: #747474;
	font-size: 24px;
	cursor: pointer;
	padding: 0;
	margin-right: 10px;
	border-radius: 5px;
	height: 29px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ViewIcon = styled.div`
	color: #747474;
	font-size: 24px;
	cursor: pointer;
	padding: 0;
	margin-right: 10px;
	border-radius: 5px;
	height: 29px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const TableItem = styled.div`
	color: #747474;
	font-size: 15px;
	display: flex;
	align-items: center;
	width: 10%;
	white-space: nowrap;
`;
