const firebaseConfig = {
    apiKey: "AIzaSyBC_rRtKdYJmGpJTuG5KR_RNLB7nzD4h0U",
    authDomain: "jeeva-77175.firebaseapp.com",
    projectId: "jeeva-77175",
    storageBucket: "jeeva-77175.appspot.com",
    messagingSenderId: "667870851671",
    appId: "1:667870851671:web:92f16e47e2c8637d062912",
    measurementId: "G-Z66FMF9FC1"
  };

export default firebaseConfig