import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { blue_calendar } from "../../../components/assets";
import { Context } from "../../../context/Store";
import {
  CreatingFarmer,
  RouteSocieties,
  SocietiesList,
  SocietiesListView,
} from "../../../api/auth";
import "react-datepicker/dist/react-datepicker.css";

import { getDDMMYY, getYYMMDD } from "../../../utils/functions/function";
import { useNavigate, useParams } from "react-router-dom";
import ButtonLoader from "../../../components/button-loader/ButtonLoader";
import api from "../../../api";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";

function CreateFarmer({ setAdd }) {
  const [name, setName] = useState();
  const [joinDate, setJoinDate] = useState();
  const [vehicleNumber, setVehicleNumber] = useState();
  const [show, setPassShow] = useState(false);
  const [phone, setPhone] = useState();
  const [numberOfCows, setNumberCows] = useState(0);
  const [password, setPassword] = useState();
  const [address, setAddress] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [societyId, setSocietyId] = useState("");
  const [societyName, setSocietyName] = useState("");
  const [query, setQuery] = useState("");
  const [isShow, setShow] = useState(false);
  const [searchResult, setSearchResult] = useState("");

  const [bonusAmount, setBounsAmount] = useState(0);
  const [branch, setBranch] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  // goback function
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  // get accesstoken from store
  const {
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const path = window.location.pathname;
  const showSuccessModal = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Farmer Created Successfully",
      showConfirmButton: false,
      timer: 1500,
    });
  };
  const getsocietyId = user_data.username;
  const role = user_data.roles;
  const { id } = useParams();
  //payload
  const formData = new FormData();
  formData.append("name", name);
  if (joinDate) {
    formData.append("join_date", getYYMMDD(joinDate));
  }
  // formData.append("vehicle_number", vehicleNumber);
  formData.append("phone", phone);
  // formData.append("number_of_cows", numberOfCows);
  formData.append("password", password);
  formData.append("address", address);

  formData.append("bonus_amount", bonusAmount);
  formData.append("account_number", accountNumber);
  formData.append("ifsc_code", ifscCode);
  formData.append("bank_name", bankName);
  formData.append("branch", branch);
  {
    role == "Plant"
      ? formData.append("society_id", query)
      : formData.append("society_id", getsocietyId);
  }

  //creating route
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    // if (!joinDate) {
    // setErrorMessage("Please select joining date");
    // setIsButtonLoading(false);
    // } else if (!numberOfCows) {
    //   // setErrorMessage("Please enter number of cows");
    //   setIsButtonLoading(false);
    if (!name) {
      // setErrorMessage("Please enter farmer's name");
      setIsButtonLoading(false);
    } else if (!phone) {
      // setErrorMessage("Please enter farmer's phone number");
      setIsButtonLoading(false);
    } else if (!password) {
      // setErrorMessage("Please enter password");
      setIsButtonLoading(false);
    } else {
      CreatingFarmer(config, formData)
        .then((res) => {
          if (res.app_data.StatusCode == 6000) {
            setErrorMessage("");
            showSuccessModal();
            setIsButtonLoading(false);
            if (path == "/plant-farmers") {
              setAdd(false);
            }
            if (role == "Plant") {
              navigate("/plant-farmers");
            } else {
              navigate("/farmers");
            }
          } else {
            setIsButtonLoading(false);
            setErrorMessage(res.app_data.data.message);
          }
        })
        .catch((error) => {
          setIsButtonLoading(false);
          setErrorMessage(error.app_data.data.message);
        });
    }
  };
  useEffect(() => {
    role == "Plant" &&
      api
        .get(`accounts/societies/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            q: query,
          },
        })
        .then((res) => {
          if (res.app_data.StatusCode === 6000) {
            setSearchResult(res.app_data.data);
          } else {
            setSearchResult([]);
          }
        });
  }, [query]);

  return (
    <TotalContainer>
      <Title>Create Farmer</Title>
      <RouteInfo>
        <CoverInfo>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <General>General</General>
            <CoverForm>
              <CoverIn>
                <Label>Name*</Label>
                <Input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </CoverIn>
              <CoverIn>
                <Label>Join date</Label>
                {/* <Input
                  type="date"
                  value={joinDate}
                  onChange={(e) => setJoinDate(e.target.value)}
                  // required
                /> */}
                <InputBox>
                  <DatePicker
                    value={joinDate === "" ? "DD/MM/YYYY" : getDDMMYY(joinDate)}
                    onChange={(date) => setJoinDate(date)}
                    // required
                  />
                  <Cal>
                    <img src={blue_calendar} alt="image" />
                  </Cal>
                </InputBox>
              </CoverIn>
            </CoverForm>
            <CoverForm>
              <CoverIn>
                <Label>Phone no*</Label>
                <Input
                  type="number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value.slice(0, 10))}
                  required
                />
              </CoverIn>
              {/* <CoverIn>
                <Label>No of Cows</Label>
                <Input
                  type="number"
                  value={numberOfCows}
                  onChange={(e) => setNumberCows(e.target.value)}
                  // required
                />
              </CoverIn> */}
            </CoverForm>
            {/* ---------------------new details----------------------- */}
            <CoverForm>
              <CoverIn>
                <Label>Bonus Amount</Label>
                <Input
                  type="number"
                  value={bonusAmount}
                  onChange={(e) => setBounsAmount(e.target.value)}
                  // required
                />
              </CoverIn>
              <CoverIn>
                <Label>Account Number</Label>
                <Input
                  type="number"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  // required
                />
              </CoverIn>
            </CoverForm>
            <CoverForm>
              <CoverIn>
                <Label>IFSC Code</Label>
                <Input
                  type="text"
                  value={ifscCode}
                  onChange={(e) => setIfscCode(e.target.value)}
                  // required
                />
              </CoverIn>
              <CoverIn>
                <Label>Bank Name</Label>
                <Input
                  type="text"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  // required
                />
              </CoverIn>
            </CoverForm>
            <CoverForm>
              <CoverIn>
                <Label>Branch</Label>
                <Input
                  type="text"
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                  // required
                />
              </CoverIn>
              <CoverIn>
                <Label>Password*</Label>
                <Input
                  type={show ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <Icon onClick={() => setPassShow(!show)}>
                  {show ? (
                    <i className="ri-eye-off-line"></i>
                  ) : (
                    <i className="ri-eye-line"></i>
                  )}
                </Icon>
              </CoverIn>
            </CoverForm>
            {/* ----------------------------------ends-------------------------- */}
            <CoverIn className="add">
              <Label>Address*</Label>
              <TextAea
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              ></TextAea>
            </CoverIn>
            {role == "Plant" ? (
              <>
                <General className="veh">Society details</General>

                <NameBox>
                  <SocietyBox>
                    <Label>Society ID*</Label>
                    <input
                      className="id"
                      type="text"
                      placeholder="Society ID"
                      value={query}
                      onChange={(e) => {
                        setQuery(e.target.value);
                        setShow(true);
                      }}
                      required
                    />
                    {isShow && (
                      <SearchResults>
                        {searchResult?.length == 0
                          ? "No Societies"
                          : searchResult?.map((item, index) => (
                              <Result
                                onClick={() => {
                                  setSocietyName(item.name);
                                  setQuery(item.code);
                                  setSocietyId(item.id);
                                  setShow(false);
                                }}
                                key={index}
                              >
                                {item.name}
                              </Result>
                            ))}
                      </SearchResults>
                    )}
                  </SocietyBox>
                  <SocietyBox>
                    <Label>Society name*</Label>
                    <input
                      type="text"
                      placeholder="Society Name"
                      value={societyName}
                      // onChange={(e) => setSocietyName(e.target.value)}
                      disabled
                    />
                  </SocietyBox>
                </NameBox>
              </>
            ) : (
              ""
            )}

            <ErrorMessage>{errorMessage}</ErrorMessage>
            <Buttons>
              <Button className="cancel" onClick={() => goBack()}>
                Cancel
              </Button>
              {isButtonLoading ? (
                <Button>
                  <ButtonLoader />
                </Button>
              ) : (
                <SubmitButton type="submit" value="Create" />
              )}
            </Buttons>
          </Form>
        </CoverInfo>
      </RouteInfo>
    </TotalContainer>
  );
}

export default CreateFarmer;

const TotalContainer = styled.div`
  margin-top: 30px;
`;
const RouteInfo = styled.div`
  background-color: #fff;
  margin-top: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  padding: 30px;
  width: 70%;
  margin: 0 auto;
  max-height: calc(100vh - 180px);
  overflow: scroll;
  @media (max-width: 1080px) {
    width: 99%;
  }
`;
const TextAea = styled.textarea`
  width: 100%;
  padding-top: 20px;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  height: 150px;
  font-size: 18px;
  padding-left: 20px;
  border-radius: 5px;
  outline: none;
`;
const Title = styled.h2`
  color: #0a0a0a;
  margin-bottom: 20px;
  font-size: 26px;

  width: 70%;
  margin: 0 auto;
  padding-bottom: 10px;
  @media (max-width: 1080px) {
    width: 99%;
  }
`;
const Cal = styled.div`
  position: absolute;
  right: 12px;
  cursor: pointer;
  z-index: 1;
  bottom: 15px;
`;
const Button = styled.div`
  width: 170px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.cancel {
    background-color: #fff;
    border: 1px solid #2382d4;
    color: #2382d4;
    margin-right: 20px;
  }
`;
const InputBox = styled.div`
  background-color: #f4f5f8;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #dfe8ed;
  position: relative;
  input {
    font-family: "inter_light";
    width: 100%;
    background: #f4f5f8;
  }
`;
const SubmitButton = styled.input`
  width: 170px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.cancel {
    background-color: #fff;
    border: 1px solid #2382d4;
    color: #2382d4;
    margin-right: 20px;
  }
`;
const Form = styled.form``;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const Select = styled.select`
  width: 100%;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  height: 60px;
  font-size: 18px;
  padding-left: 20px;
  border-radius: 5px;
  outline: none;
  &.auto {
    color: #adadad;
  }
`;
const CoverInfo = styled.div``;
const General = styled.h3`
  color: #0a0a0a;
  position: relative;
  overflow: hidden;
  font-size: 22px;
  margin-bottom: 30px;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 100px;
    background-color: #ebebeb;
    bottom: 10px;
  }
  &.veh {
    margin-top: 30px;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 260px;
      bottom: 10px;
      background-color: #ebebeb;
    }
  }
`;
const CoverForm = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;
const CoverIn = styled.div`
  width: 50%;
  margin-right: 20px;
  margin-bottom: 30px;
  &:nth-child(even) {
    margin-right: 0;
  }
  position: relative;
  &.add {
    width: 100%;
  }
`;
const Label = styled.h3`
  color: #747474;
  font-family: "inter_regular";
  margin-bottom: 10px;
`;
const Input = styled.input`
  width: 100%;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  height: 60px;
  font-size: 18px;
  z-index: 2;
  padding-left: 20px;
  border-radius: 5px;
  &.auto {
    color: #adadad;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  margin-bottom: 5px;
  text-align: right;
  width: 100%;
  display: inline-block;
`;

const NameBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const SocietyBox = styled.div`
  width: 49%;
  position: relative;
  input {
    padding: 18px 24px;
    border: 1px solid #dfe8ed;
    border-radius: 4px;
    width: 100%;
    margin: 10px 0;
    font-size: 18px;
    font-family: "inter_light";
    &.id {
      text-transform: capitalize;
    }
  }
`;
// const Label = styled.h4`
//   color: #747474;
//   font-family: "inter_light";
//   font-weight: unset;
//   font-size: 18px;
// `;

const SearchResults = styled.div`
  height: 300px;
  overflow: scroll;
  background-color: #f2f2f2;
  position: absolute;
  border-radius: 0 0 20px 20px;
  top: 92px;
  width: 100%;
  z-index: 10;
`;
const Result = styled.div`
  background-color: #e5e5e5;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 10px;
`;

const Icon = styled.div`
  color: #292c2d;
  position: absolute;
  right: 20px;
  top: 53px;
  cursor: pointer;
  @media all and (max-width: 400px) {
    right: 20px;
    top: 38px;
  }
`;
