import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { JeevaBlue } from "../../../components/assets/images";
import {
	ArrivalIcon,
	DashboardIcon,
	InsuranceIcon,
	MessageIcon,
	MilkIcon,
	PaymentIcon,
	RouteIcon,
	SocityIcon,
	DropDown,
	BlueDropDown,
	HollowBullet,
} from "../../../components/assets/icons";
import { Link } from "react-router-dom";
import {
	can_details,
  curveWhite,
	farmer_icon,
	local_sales,
	milk_recieved,
	milk_report,
	milk_status,
	product_icon,
} from "../../../components/assets";
import { Context } from "../../../context/Store";

const Sidebar = ({ isHovered}) => {

  useEffect(() => {
    const path = window.location.pathname;
  }, [window.location.pathname]);
  const path = window.location.pathname;

  const [toggleState, setToggleState] = useState(false);
  const [toggleProject, setToggleProject] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [toggleInsurance, setToggleInsurance] = useState(false);
  const [toggleProducts, setToggleProducts] = useState(false);
  const [paymentToggleState, setPaymentToggleState] = useState(false);
  const [insuranceToggleState, setInsuranceToggleState] = useState(false);

  // Handling toggle for Payment dropdown
  const handlePaymentToggle = () => {
    setPaymentToggleState(!paymentToggleState);
  };

  // Handling toggle for Insurance dropdown
  const handleInsuranceToggle = () => {
    setInsuranceToggleState(!insuranceToggleState);
  };

  const handleOnClick = () => {
    setIsClicked(!isClicked);
  };
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const privileges = user_data.privileges;
  const role = user_data.roles;
  const active_menu = user_data.active_menu;
  const active_sub_menu = user_data.active_sub_menu;
  return (
    <Cover>
      <Heading>
        {!isHovered ? (
          <img
            className={!isHovered && "active"}
            src={JeevaBlue}
            alt="Jeeva milk"
          />
        ) : (
          <>
            <img
              className={!isHovered && "active"}
              src={JeevaBlue}
              alt="Jeeva milk"
            />
            <h1>Jeeva milk</h1>
          </>
        )}
      </Heading>
      {role == "Plant" ? (
        <Navigation>
          <CoverLink>
            <NavLinks
              to="/"
              className={active_menu == "dashboard" ? "active" : ""}
              onClick={handleOnClick}
            >
              <img className={!isHovered && "active"} src={DashboardIcon} />
              <NavTitle
                className={active_menu == "dashboard" ? "active" : ""}
                type={isHovered}
              >
                Dashboard
              </NavTitle>
            </NavLinks>

            <NavLinks
              to="/arrivals"
              className={active_menu == "arrivals" ? "active" : ""}
            >
              <img className={!isHovered && "active"} src={ArrivalIcon} />
              <NavTitle
                className={active_menu == "arrivals" ? "active" : ""}
                type={isHovered}
              >
                Arrivals
              </NavTitle>
            </NavLinks>
            {/* ------------payment-------------------------- */}
            <NavLinks
              to="/processed-payments"
              // className={active_menu == "payments" ? "active" : ""}
              onClick={() => {
                setToggleState(!toggleState);
                setToggleProject(false);
                setToggleProducts(false);
              }}
            >
              <img className={!isHovered && "active"} src={PaymentIcon} />
              <div className="pay">
                <NavTitle type={isHovered}>Payments</NavTitle>
                <Drop className={isHovered && "active"}>
                  <img src={DropDown} />
                </Drop>
              </div>
            </NavLinks>

            <DropDownBox
              className={toggleState && isHovered ? "open" : "close"}
            >
              <NavLinks
                to="/processed-payments"
                completed-payment-report
                // className="innerNav"
                className={
                  active_menu == "payments" ? "active innerNav" : "innerNav"
                }
              >
                <img className={!isHovered && "active"} src={HollowBullet} />
                <NavTitle
                  className={
                    active_menu == "payments" ? "active innerNav" : "innerNav"
                  }
                  type={isHovered}
                >
                  Proceed payment{" "}
                </NavTitle>
              </NavLinks>
              <NavLinks
                to="/completed-payment-report"
                // className="innerNav"
                className={
                  active_menu == "payments-report"
                    ? "active innerNav"
                    : "innerNav"
                }
              >
                <img className={!isHovered && "active"} src={HollowBullet} />
                <NavTitle
                  className={
                    active_menu == "payments-report"
                      ? "active innerNav"
                      : "innerNav"
                  }
                  type={isHovered}
                >
                  Completed payments{" "}
                </NavTitle>
              </NavLinks>
              <NavLinks
                to="/payment-history"
                // className="innerNav"
                className={
                  active_menu == "payments-history"
                    ? "active innerNav"
                    : "innerNav"
                }
              >
                <img className={!isHovered && "active"} src={HollowBullet} />
                <NavTitle
                  className={
                    active_menu == "payments-history"
                      ? "active innerNav"
                      : "innerNav"
                  }
                  type={isHovered}
                >
                  Transaction history{" "}
                </NavTitle>
              </NavLinks>
              <NavLinks
                to="/incentives"
                className={
                  active_menu == "incentives" ? "active innerNav" : "innerNav"
                }
              >
                <img className={!isHovered && "active"} src={HollowBullet} />
                <NavTitle
                  className={
                    active_menu == "incentives" ? "active innerNav" : "innerNav"
                  }
                  type={isHovered}
                >
                  Incentives
                </NavTitle>
              </NavLinks>
              <NavLinks
                to="/loans"
                // className="innerNav"
                className={
                  active_menu == "loans" ? "active innerNav" : "innerNav"
                }
              >
                <img className={!isHovered && "active"} src={HollowBullet} />
                <NavTitle
                  className={
                    active_menu == "loans" ? "active innerNav" : "innerNav"
                  }
                  type={isHovered}
                >
                  Loans
                </NavTitle>
              </NavLinks>
              <NavLinks
                to="/milk-rate"
                // className="innerNav"
                className={
                  active_menu == "milk-rate" ? "active innerNav" : "innerNav"
                }
              >
                <img className={!isHovered && "active"} src={HollowBullet} />
                <NavTitle
                  className={
                    active_menu == "milk-rate" ? "active innerNav" : "innerNav"
                  }
                  type={isHovered}
                >
                  Milk rate
                </NavTitle>
              </NavLinks>
            </DropDownBox>

            {/* ----------------payment ends------------------- */}
            {/* ----------------products------------------- */}
            <NavLinks
              to="/products"
              onClick={() => {
                setToggleProducts(!toggleProducts);
                setToggleInsurance(false);
                setToggleState(false);
              }}
            >
              <img className={!isHovered && "active"} src={product_icon} />
              <div className="pay">
                <NavTitle type={isHovered}>Products</NavTitle>
                <Drop className={isHovered && "active"}>
                  <img src={DropDown} />
                </Drop>
              </div>
            </NavLinks>
            <DropDownBox
              className={toggleProducts && isHovered ? "open" : "close"}
            >
              <NavLinks
                to="/products"
                className={
                  active_menu === "products" ? "active innerNav" : "innerNav"
                }
              >
                <img className={!isHovered && "active"} src={HollowBullet} />
                <NavTitle
                  className={
                    active_menu === "products" ? "active innerNav" : "innerNav"
                  }
                  type={isHovered}
                >
                  Items
                </NavTitle>
              </NavLinks>
              <NavLinks
                to="/orders"
                className={
                  active_menu === "orders" ? "active innerNav" : "innerNav"
                }
              >
                <img className={!isHovered && "active"} src={HollowBullet} />
                <NavTitle
                  className={
                    active_menu === "orders" ? "active innerNav" : "innerNav"
                  }
                  type={isHovered}
                >
                  Orders
                </NavTitle>
              </NavLinks>
            </DropDownBox>
            {/* ----------------products----ends--------------- */}

            <Line></Line>
            <NavLinks
              to="/milk-view"
              className={active_menu == "milk-report" ? "active" : ""}
            >
              <img className={!isHovered && "active"} src={milk_report} />
              <NavTitle
                className={active_menu == "milk-report" ? "active" : ""}
                type={isHovered}
              >
                Milk Report
              </NavTitle>
            </NavLinks>
            {/* <NavLinks
              to="/milk-status"
              className={active_menu == "milk-status" ? "active" : ""}
            >
              <img className={!isHovered && "active"} src={MilkIcon} />
              <NavTitle type={isHovered}>Milk Status</NavTitle>
            </NavLinks> */}
            <NavLinks
              to="/routes"
              className={active_menu == "routes" ? "active" : ""}
            >
              <img className={!isHovered && "active"} src={RouteIcon} />
              <NavTitle
                className={active_menu == "routes" ? "active" : ""}
                type={isHovered}
              >
                Routes
              </NavTitle>
            </NavLinks>
            <NavLinks
              to="/total-societies?society=all"
              className={active_menu == "socities" ? "active" : ""}
            >
              <img className={!isHovered && "active"} src={SocityIcon} />
              <NavTitle
                className={active_menu == "socities" ? "active" : ""}
                type={isHovered}
              >
                Societies
              </NavTitle>
            </NavLinks>
            <NavLinks
              to="/plant-farmers"
              className={active_menu == "farmers" ? "active" : ""}
            >
              <img className={!isHovered && "active"} src={farmer_icon} />
              <NavTitle
                className={active_menu == "farmers" ? "active" : ""}
                type={isHovered}
              >
                Farmers
              </NavTitle>
            </NavLinks>
          </CoverLink>

          <NavLinks
            to="/sheet"
            className={active_menu == "sheet" ? "active" : ""}
          >
            <img className={!isHovered && "active"} src={ArrivalIcon} />
            <NavTitle
              className={active_menu == "sheet" ? "active" : ""}
              type={isHovered}
            >
              Milk Price Sheet
            </NavTitle>
          </NavLinks>

          {/* ----------------insurance----start--------------- */}
          {/* Insurance */}
          <NavLinks
            to="/insurance"
            className={active_menu === "insurance" ? "active" : ""}
            onClick={() => {
              setToggleInsurance(!toggleInsurance);
              setToggleProducts(false);
              setToggleState(false);
            }}
          >
            <img className={!isHovered && "active"} src={InsuranceIcon} />
            <div className="pay">
              <NavTitle
                className={active_menu === "insurance" ? "active" : ""}
                type={isHovered}
              >
                Insurance
              </NavTitle>
              <Drop className={isHovered && "active"}>
                <img src={DropDown} />
              </Drop>
            </div>
          </NavLinks>
          <DropDownBox
            className={toggleInsurance && isHovered ? "open" : "close"}
          >
            <NavLinks
              to="/insurance"
              className={
                active_menu === "Insurance" ? "active innerNav" : "innerNav"
              }
            >
              <img className={!isHovered && "active"} src={HollowBullet} />
              <NavTitle
                className={
                  active_menu === "Insurance" ? "active innerNav" : "innerNav"
                }
                type={isHovered}
              >
                Insurance Details
              </NavTitle>
            </NavLinks>
            <NavLinks
              to="/renewal"
              className={
                active_menu === "Renewal" ? "active innerNav" : "innerNav"
              }
            >
              <img className={!isHovered && "active"} src={HollowBullet} />
              <NavTitle
                className={
                  active_menu === "Renewal" ? "active innerNav" : "innerNav"
                }
                type={isHovered}
              >
                Renewal
              </NavTitle>
            </NavLinks>
          </DropDownBox>
          <NavLinks
            to="/notification"
            className={active_menu == "Notification" ? "active" : ""}
          >
            <i className="ri-notification-2-line"></i>
            <NavTitle
              className={active_menu == "Notification" ? "active" : ""}
              type={isHovered}
            >
              Notification
            </NavTitle>
          </NavLinks>

          <NavLinks
            to="/sub-user"
            className={active_menu == "sub-users" ? "active" : ""}
          >
            <i className="ri-user-shared-2-line"></i>
            <NavTitle
              className={active_menu == "sub-users" ? "active" : ""}
              type={isHovered}
            >
              Sub users
            </NavTitle>
          </NavLinks>
        </Navigation>
      ) : role == "Society" ? (
        // -------------------------------------society----------------------

        <Navigation>
          <NavLinks
            to="/"
            className={active_menu == "dashboard" ? "active" : ""}
            onClick={handleOnClick}
          >
            <img className={!isHovered && "active"} src={DashboardIcon} />
            <NavTitle
              className={active_menu == "dashboard" ? "active" : ""}
              type={isHovered}
            >
              Dashboard
            </NavTitle>
          </NavLinks>
          <NavLinks
            className={active_menu == "arrivals" ? "active" : ""}
            onClick={handleOnClick}
            to="/arrivals"
          >
            <img className={!isHovered && "active"} src={milk_recieved} />
            <NavTitle
              className={active_menu == "arrivals" ? "active" : ""}
              type={isHovered}
            >
              Arrivals
            </NavTitle>
          </NavLinks>
          <NavLinks
            className={active_menu == "milk-status" ? "active" : ""}
            onClick={handleOnClick}
            to="/milk-status"
          >
            <img className={!isHovered && "active"} src={MilkIcon} />
            <NavTitle
              className={active_menu == "milk-status" ? "active" : ""}
              type={isHovered}
            >
              Milk Status
            </NavTitle>
          </NavLinks>
          <NavLinks
            className={active_menu == "milk-report" ? "active" : ""}
            to="/milk-view"
          >
            <img className={!isHovered && "active"} src={milk_status} />
            <NavTitle
              className={active_menu == "milk-report" ? "active" : ""}
              type={isHovered}
            >
              Milk report
            </NavTitle>
          </NavLinks>
          <NavLinks
            className={active_menu == "can-details" ? "active" : ""}
            onClick={handleOnClick}
            to="/can-details"
          >
            <img className={!isHovered && "active"} src={can_details} />
            <NavTitle
              className={active_menu == "can-details" ? "active" : ""}
              type={isHovered}
            >
              Can details
            </NavTitle>
          </NavLinks>
          <NavLinks
            className={active_menu == "local-sales" ? "active" : ""}
            onClick={handleOnClick}
            to="/local-sales"
          >
            <img className={!isHovered && "active"} src={local_sales} />
            <NavTitle
              className={active_menu == "local-sales" ? "active" : ""}
              type={isHovered}
            >
              Local sales
            </NavTitle>
          </NavLinks>
          <NavLinks
            className={active_menu == "payments" ? "active" : ""}
            to="/processed-payments"
            onClick={handlePaymentToggle}
          >
            <img className={!isHovered && "active"} src={PaymentIcon} />
            <div className="pay">
              <NavTitle
                className={active_menu == "payments" ? "active" : ""}
                type={isHovered}
              >
                Payments
              </NavTitle>
              <Drop className={isHovered && "active"}>
                <img src={DropDown} />
              </Drop>
            </div>
          </NavLinks>
          <DropDownBox
            className={paymentToggleState && isHovered ? "open" : "close"}
          >
            <NavLinks
              to="/processed-payments"
              className={
                active_sub_menu == "processed" ? "innerNav actives" : ""
              }
            >
              <img className={!isHovered && "active"} src={HollowBullet} />
              <NavTitle
                className={
                  active_sub_menu == "processed" ? "innerNav actives" : ""
                }
                type={isHovered}
              >
                Proceed payments
              </NavTitle>
            </NavLinks>

            <NavLinks
              className={
                active_sub_menu == "payments-report" ? "innerNav actives" : ""
              }
              to="/completed-payment-report"
            >
              <img className={!isHovered && "active"} src={HollowBullet} />
              <NavTitle
                className={
                  active_sub_menu == "payments-report" ? "innerNav actives" : ""
                }
                type={isHovered}
              >
                Payment report
              </NavTitle>
            </NavLinks>
            <NavLinks
              className={
                active_sub_menu == "payments-history" ? "innerNav actives" : ""
              }
              to="/payment-history"
            >
              <img className={!isHovered && "active"} src={HollowBullet} />
              <NavTitle
                className={
                  active_sub_menu == "payments-history"
                    ? "innerNav actives"
                    : ""
                }
                type={isHovered}
              >
                Transaction history
              </NavTitle>
            </NavLinks>
            <NavLinks
              className={active_menu == "incentives" ? "innerNav active" : ""}
              to="/incentives"
            >
              <img className={!isHovered && "active"} src={HollowBullet} />
              <NavTitle
                className={active_menu == "incentives" ? "innerNav active" : ""}
                type={isHovered}
              >
                Incentives
              </NavTitle>
            </NavLinks>
            <NavLinks
              className={active_menu == "loans" ? "innerNav active" : ""}
              to="/loans"
            >
              <img className={!isHovered && "active"} src={HollowBullet} />
              <NavTitle
                className={active_menu == "loans" ? "innerNav active" : ""}
                type={isHovered}
              >
                Loans
              </NavTitle>
            </NavLinks>
          </DropDownBox>
          {/* ------------------Current loan--------------- */}
          <NavLinks
            className={active_menu == "current-loan" ? "active" : ""}
            to="/current-loan"
          >
            <Icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="rgba(198,200,202,1)"
              >
                <path d="M4 5H20V3H4V5ZM20 9H4V7H20V9ZM9 13H15V11H21V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V11H9V13Z"></path>
              </svg>
            </Icon>
            <div className="pay">
              <NavTitle
                className={active_menu == "current-loan" ? "active" : ""}
                type={isHovered}
              >
                Current Loan
              </NavTitle>
            </div>
          </NavLinks>
          {/* --------------------current loan ends-------------------- */}
          
          <NavLinks
            className={active_menu == "farmers" ? "active" : ""}
            to="/farmers"
          >
            <img className={!isHovered && "active"} src={farmer_icon} />
            <NavTitle
              className={active_menu == "farmers" ? "active" : ""}
              type={isHovered}
            >
              Farmers
            </NavTitle>
          </NavLinks>

          {/* <NavLinks
            to="/sheet"
            className={active_menu == "Milk Price Sheet" ? "active" : ""}
          >
            <img className={!isHovered && "active"} src={ArrivalIcon} />
            <NavTitle type={isHovered}>Milk Price Sheet</NavTitle>
          </NavLinks> */}
          <NavLinks
            className={active_menu == "insurance" ? "active" : ""}
            to="/insurance"
            onClick={handleInsuranceToggle}
          >
            <img className={!isHovered && "active"} src={InsuranceIcon} />
            <div className="pay">
              <NavTitle
                className={active_menu == "insurance" ? "active" : ""}
                type={isHovered}
              >
                Insurance
              </NavTitle>
              <Drop className={isHovered && "active"}>
                <img src={DropDown} />
              </Drop>
            </div>
          </NavLinks>
          <DropDownBox
            className={insuranceToggleState && isHovered ? "open" : "close"}
          >
            <NavLinks
              to="/insurance"
              className={active_sub_menu == "details" ? "innerNav actives" : ""}
            >
              <img className={!isHovered && "active"} src={HollowBullet} />
              <NavTitle
                className={
                  active_sub_menu == "details" ? "innerNav actives" : ""
                }
                type={isHovered}
              >
                Details
              </NavTitle>
            </NavLinks>

            <NavLinks
              className={active_sub_menu == "payment" ? "innerNav actives" : ""}
              to="/payment"
            >
              <img className={!isHovered && "active"} src={HollowBullet} />
              <NavTitle
                className={
                  active_sub_menu == "payment" ? "innerNav actives" : ""
                }
                type={isHovered}
              >
                Payment
              </NavTitle>
            </NavLinks>
          </DropDownBox>
          <NavLinks
            to="/notification"
            className={active_menu == "Notification" ? "active" : ""}
          >
            <i className="ri-notification-2-line"></i>
            <NavTitle
              className={active_menu == "Notification" ? "active" : ""}
              type={isHovered}
            >
              Notification
            </NavTitle>
          </NavLinks>
        </Navigation>
      ) : // -------------------------------------subprofile----------------------

      role == "PlantSubProfile" ? (
        <Navigation>
          <CoverLink>
            <NavLinkss
              to="/"
              type={privileges.dashboard}
              className={active_menu == "dashboard" ? "active" : ""}
              onClick={handleOnClick}
            >
              <img className={!isHovered && "active"} src={DashboardIcon} />
              <NavTitle
                className={active_menu == "dashboard" ? "active" : ""}
                type={isHovered}
              >
                Dashboard
              </NavTitle>
            </NavLinkss>

            <NavLinkss
              to="/milk-status"
              type={privileges.milk_status}
              className={active_menu == "milk-status" ? "active" : ""}
            >
              <img className={!isHovered && "active"} src={MilkIcon} />
              <NavTitle
                className={active_menu == "milk-status" ? "active" : ""}
                type={isHovered}
              >
                Milk Status
              </NavTitle>
            </NavLinkss>

            <NavLinkss
              to="/routes"
              type={privileges.routs}
              className={active_menu == "routes" ? "active" : ""}
            >
              <img className={!isHovered && "active"} src={RouteIcon} />
              <NavTitle
                className={active_menu == "routes" ? "active" : ""}
                type={isHovered}
              >
                Routes
              </NavTitle>
            </NavLinkss>

            <NavLinkss
              to="/total-societies"
              type={privileges.socities}
              className={active_menu == "socities" ? "active" : ""}
            >
              <img className={!isHovered && "active"} src={SocityIcon} />
              <NavTitle
                className={active_menu == "socities" ? "active" : ""}
                type={isHovered}
              >
                Societies
              </NavTitle>
            </NavLinkss>

            <NavLinkss
              to="/plant-farmers"
              type={privileges.farmers}
              className={active_menu == "farmers" ? "active" : ""}
            >
              <img className={!isHovered && "active"} src={farmer_icon} />
              <NavTitle
                className={active_menu == "farmers" ? "active" : ""}
                type={isHovered}
              >
                Farmers
              </NavTitle>
            </NavLinkss>
            <NavLinkss
              to="/sub-user"
              type={privileges.sub_users}
              className={active_menu == "sub-users" ? "active" : ""}
            >
              <i className="ri-user-shared-2-line"></i>
              <NavTitle
                className={active_menu == "sub-users" ? "active" : ""}
                type={isHovered}
              >
                Sub users
              </NavTitle>
            </NavLinkss>
          </CoverLink>
          <NavLinkss
            to="/arrivals"
            type={privileges.arrivals}
            className={active_menu == "arrivals" ? "active" : ""}
          >
            <img className={!isHovered && "active"} src={ArrivalIcon} />
            <NavTitle
              className={active_menu == "arrivals" ? "active" : ""}
              type={isHovered}
            >
              Arrivals
            </NavTitle>
          </NavLinkss>

          <NavLinkss
            to={`${active_menu}`}
            type={privileges.payments}
            className={active_menu == "payments" ? "active" : ""}
            onClick={() => {
              setToggleState(!toggleState);
            }}
          >
            <img className={!isHovered && "active"} src={PaymentIcon} />
            <div className="pay">
              <NavTitle
                className={active_menu == "payments" ? "active" : ""}
                type={isHovered}
              >
                Payments
              </NavTitle>
              <Drop className={isHovered && "active"}>
                <img src={DropDown} />
              </Drop>
            </div>
          </NavLinkss>

          <DropDownBox className={toggleState && isHovered ? "open" : "close"}>
            <NavLinks
              to="/bonus"
              // className="innerNav"
              className={
                active_menu == "bonus" ? "active innerNav" : "innerNav"
              }
            >
              <img className={!isHovered && "active"} src={HollowBullet} />
              <NavTitle
                className={
                  active_menu == "bonus" ? "active innerNav" : "innerNav"
                }
                type={isHovered}
              >
                Bonus
              </NavTitle>
            </NavLinks>
            <NavLinks
              to="/incentives"
              // className="innerNav"
              className={
                active_menu == "incentives" ? "active innerNav" : "innerNav"
              }
            >
              <img className={!isHovered && "active"} src={HollowBullet} />
              <NavTitle
                className={
                  active_menu == "incentives" ? "active innerNav" : "innerNav"
                }
                type={isHovered}
              >
                Incentives
              </NavTitle>
            </NavLinks>
            <NavLinks
              to="/loans"
              // className="innerNav"
              className={
                active_menu == "loans" ? "active innerNav" : "innerNav"
              }
            >
              <img className={!isHovered && "active"} src={HollowBullet} />
              <NavTitle
                className={
                  active_menu == "loans" ? "active innerNav" : "innerNav"
                }
                type={isHovered}
              >
                Loans
              </NavTitle>
            </NavLinks>
            <NavLinks
              to="/milk-rate"
              // className="innerNav"
            >
              <img className={!isHovered && "active"} src={HollowBullet} />
              <NavTitle type={isHovered}>Milk rate</NavTitle>
            </NavLinks>
          </DropDownBox>

          <NavLinks to="" disabled className="coming-soon">
            <img className={!isHovered && "active"} src={InsuranceIcon} />
            <NavTitle type={isHovered}>Insurance</NavTitle>
            <Coming>
              <IconS>
                <i className="ri-flashlight-fill"></i>
              </IconS>
              Coming Soon
            </Coming>
          </NavLinks>
          <NavLinks to="" className="coming-soon">
            <img className={!isHovered && "active"} src={MessageIcon} />
            <NavTitle type={isHovered}>Messages</NavTitle>
            <Coming>
              <IconS>
                <i className="ri-flashlight-fill"></i>
              </IconS>
              Coming Soon
            </Coming>
          </NavLinks>
        </Navigation>
      ) : (
        ""
      )}
    </Cover>
  );
};

export default Sidebar;


const Cover = styled.div`
  height: 100%;
  background: #010f24;
`;

const Heading = styled.div`
  padding: 25px;
  display: flex;
  align-items: center;
  color: #fff;
  gap: 10px;
  transition: 0.4s ease;
  margin-left: -14px;

  h1 {
    font-size: 20px;
    transition: 0.4s ease;
    white-space: nowrap;
  }
  svg {
    width: 30px;
    cursor: pointer;
    pointer-events: none;
  }
`;
const Line = styled.div`
  width: 100%;
  background: #131f31;
  height: 1;
`;
const Navigation = styled.div`
  padding: 5px;
  height: calc(100vh - 100px);
  overflow: scroll;
`;
const NavLinkss = styled(Link)`
  display: ${({ type }) => (type ? "flex" : "none")};
  position: relative;
  align-items: center;
  padding: 15px 20px;
  gap: 8px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  /* overflow: hidden; */
  transition: 0.4s ease;
  margin-bottom: 10px;
  i {
    color: #c6c8ca;
    font-family: "inter_medium";
  }
  &.coming-soon {
    pointer-events: none;
  }
  &.innerNav {
    padding: 15px 30px;
    img {
      height: 10px;
      width: 10px;
    }
  }
  &.active {
    background: #fff;
    transition: 0.4s ease;
    h4 {
      color: #2786d6;
      font-family: "inter_regular";
    }
    &:after {
      content: "";
      position: absolute;
      width: 6px;
      height: 80%;
      background: #2382d4;
      left: 0;
      top: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &.pay {
      img {
        fill: #2786d6;
      }
    }
  }

  &:hover {
    transition: 0.4s ease;
    h4 {
      color: #2786d6;
    }
  }

  h4 {
    color: #c6c8ca;
    font-family: "inter_medium";
  }
  div {
    &.pay {
      display: flex;
      width: 100%;
      img {
        fill: #000;
      }
    }
  }
  img {
    &.active {
      width: 50px;
    }
  }
`;
const NavLinks = styled(Link)`
  display: flex;
  position: relative;
  align-items: center;
  padding: 15px 20px;
  gap: 8px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  /* overflow: hidden; */
  transition: 0.4s ease;
  margin-bottom: 10px;
  i {
    color: #c6c8ca;
    font-family: "inter_medium";
  }
  &.coming-soon {
    pointer-events: none;
  }
  &.innerNav {
    padding: 15px 30px;
    img {
      height: 10px;
      width: 10px;
    }
  }
  &.active {
    background: #fff;
    transition: 0.4s ease;
    h4 {
      color: #2786d6;
      font-family: "inter_regular";
      display: ${({ type }) => (type ? "block" : "none")};
    }
    &:after {
      content: "";
      position: absolute;
      width: 6px;
      height: 80%;
      background: #2382d4;
      left: 0;
      top: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &.pay {
      img {
        fill: #2786d6;
      }
    }
  }
  &.actives {
    transition: 0.4s ease;
    h4 {
      color: #2786d6;
      display: ${({ type }) => (type ? "block" : "none")};
      font-family: "inter_regular";
    }
    &:after {
      content: "";
      position: absolute;
      width: 6px;
      height: 80%;
      background: #2382d4;
      left: 0;
      top: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &.pay {
      img {
        fill: #2786d6;
      }
    }
  }
  &:hover {
    transition: 0.4s ease;
    h4 {
      display: ${({ type }) => (type ? "block" : "none")};
      color: #2786d6;
    }
  }

  h4 {
    color: #c6c8ca;
    font-family: "inter_medium";
  }
  div {
    &.pay {
      display: flex;
      width: 100%;
      img {
        fill: #000;
      }
    }
  }
`;

const DropDownBox = styled.div`
  transition: all 0.5s ease;
  height: ${(props) => (props.className == "open" ? "fit-content" : "0")};
  overflow: ${(props) => (props.className == "open" ? "" : "hidden")};
`;
const Drop = styled.div`
  display: none;
  &.active {
    display: block;
  }
  img {
    &.active {
      width: 50px;
    }
  }
`;
const Icon = styled.div`
  width: 20px;
  svg{
    width: 18px;
    height: 18px;
  }
`;
const NavTitle = styled.div`
  display: ${({ type }) => (type ? "block" : "none")};
  font-family: "inter_regular";
  color: #c6c8ca;
  white-space: nowrap;
  transition: 0.4s ease;
  &.active {
    color: #2786d6;
    transition: 0.4s ease;
  }
`;


const CoverLink = styled.div`
	margin-bottom: 10px;
`;
const Coming = styled.div`
	display: flex;
	justify-content: unset !important;
	width: fit-content !important;
	position: absolute;
	font-size: 10px;
	top: 40px;
	left: 40px;
	align-items: center;
	background-color: #2382d4;
	color: #fff;
	padding: 5px 10px;
	border-radius: 10px;
	&:before {
		content: "";
		position: absolute;
		width: 100px;
		height: 100%;
		background-image: linear-gradient(
			120deg,
			rgba(255, 255, 255, 0) 30%,
			rgba(255, 255, 255, 0.404),
			rgba(255, 255, 255, 0) 70%
		);
		top: 0;
		left: -100px;
		animation: shimmerAnimation 3s infinite linear;
		@keyframes shimmerAnimation {
			0% {
				left: -100px;
			}
			20% {
				left: 100%;
			}
			100% {
				left: 100%;
			}
		}
		animation: shimmerAnimation 3s infinite linear;
	}
	overflow: hidden;
`;
const IconS = styled.div`
	color: #fff;
	margin-right: 5px;
`;
