import React, { useContext, useEffect, useState } from "react";
import {
  build,
  curve,
  farmers,
  left_arrow,
  r_build,
  right_arrow,
} from "../../../../components/assets";
import { styled } from "styled-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../../context/Store";
import {
  RouteSingleEdit,
  RouteSingleView,
  deleteRoute,
} from "../../../../api/auth";
import Loader from "../../../../components/loader/Loader";
import ButtonLoader from "../../../../components/button-loader/ButtonLoader";
import Swal from "sweetalert2";

function SingleRoute() {
  const [routSingleData, setRouteSingleData] = useState();
  const [isInputDisabled, setInputDisabled] = useState(true);

  // edit api states
  const [routeName, setRouteName] = useState();
  const [routeNumber, setRouteNumber] = useState();
  const [vehicleName, setVehicleName] = useState();
  const [registerNumber, setRegisterNumber] = useState();
  const [driverName, setDriverName] = useState();
  const [driverNumber, setDriverNumber] = useState();
  const [coDriver, setCoDriver] = useState();
  const [coDriverNumber, setCoDriverNumber] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const navigate = useNavigate();

  const handleToggleInput = () => {
    setInputDisabled((prevDisabled) => !prevDisabled);
  };

  const goBack = () => {
    navigate(-1);
  };
  const { id } = useParams();

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    RouteSingleView(config, id).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        setIsLoading(false);
        setRouteSingleData(res.app_data.data);
        setRouteName(res.app_data.data.name);
        setRouteNumber(res.app_data.data.code);
        setVehicleName(res.app_data.data.vehicle_name);
        setRegisterNumber(res.app_data.data.vehicle_number);
        setDriverName(res.app_data.data.driver_name);
        setDriverNumber(res.app_data.data.driver_phone);
        setCoDriver(res.app_data.data.co_driver_name);
        setCoDriverNumber(res.app_data.data.co_driver_phone);
      }
    });
  }, [
    // routeName,
    // vehicleName,
    // registerNumber,
    // driverName,
    // coDriver,
    // coDriverNumber,
    isInputDisabled,
  ]);
  //payload
  const formData = new FormData();
  formData.append("rout_name", routeName);
  formData.append("vehicle_name", vehicleName);
  formData.append("vehicle_number", registerNumber);
  formData.append("driver_name", driverName);
  formData.append("driver_number", driverNumber);
  formData.append("co_driver_name", coDriver);
  formData.append("co_driver_number", coDriverNumber);
  const handleSubmit = () => {
    setIsButtonLoading(true);
    RouteSingleEdit(config, formData, id).then((res) => {
      if (res.StatusCode == 6000) {
        setIsButtonLoading(false);
      } else {
        setIsButtonLoading(false);
      }
    });
  };
  const confirmDelete = () => {
    Swal.fire({
      title: "Do you want to delete this route?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteRouteFunction();
      }
    });
  };
  const deleteRouteFunction = () => {
    deleteRoute(config, id).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        navigate("/routes");
      } else {
      }
    });
  };
  return isLoading ? (
    <Loader />
  ) : (
    <CoverM>
      <MainContainer>
        <TopBar>
          <TopCover>
            <Arrow onClick={goBack}>
              <img src={left_arrow} alt="image" />
            </Arrow>
            <Text>
              <TopTitle>{routSingleData?.name}</TopTitle>
              <RoutePath>Routes / Route no : {routSingleData?.code}</RoutePath>
            </Text>
          </TopCover>
          <Curve>
            <img src={curve} alt="" />
          </Curve>
        </TopBar>
        <Bottom>
          {/* -------------average milk quantity---------------- */}
          <Container>
            <Icon>
              <img src={r_build} alt="icon" />
            </Icon>
            <Title>Average milk quantity</Title>
            <Cover>
              <h1>{routSingleData?.average_milk}</h1>
              <h3>Litres </h3>
            </Cover>
            <Curve>
              <img src={curve} alt="image" />
            </Curve>
          </Container>
          {/* ------------------total societies--------------- */}
          <Container>
            <CoverCard>
              <Icon>
                <img src={r_build} alt="icon" />
              </Icon>
              <TextCount>
                <CoverTitle>
                  <TitleCount>Jeeva societies</TitleCount>
                  <span>{routSingleData?.society.jeeva_society}</span>
                </CoverTitle>
                <CoverTitle>
                  <TitleCount>Private societies</TitleCount>
                  <span>{routSingleData?.society.private_society}</span>
                </CoverTitle>
                <CoverTitle>
                  <TitleCount>Farms</TitleCount>
                  <span>{routSingleData?.society.farms}</span>
                </CoverTitle>
              </TextCount>
            </CoverCard>
            <Title>Total societies</Title>
            <Cover>
              <h1>{routSingleData?.society.total_count}</h1>
              <h3>Societies </h3>
            </Cover>
            <View to={`/routes/${id}/societies`}>
              View
              <img src={right_arrow} alt="image" />
            </View>
            <Curve>
              <img src={curve} alt="image" />
            </Curve>
          </Container>
          {/* ------------------------total farmers------------------------- */}
          <Container>
            <Icon>
              <img src={farmers} alt="icon" />
            </Icon>
            <Title>Total farmers</Title>
            <Cover>
              <h1>{routSingleData?.farmers}</h1>
              <h3>Farmers</h3>
            </Cover>
            <View to={`/routes/${id}/farmers`}>
              View
              <img src={right_arrow} alt="image" />
            </View>
            <Curve>
              <img src={curve} alt="image" />
            </Curve>
          </Container>
        </Bottom>
        {/* --------------------------Rout Informations---------------------- */}
        <RouteInfo>
          <CoverInfo>
            <RouteTop>
              <TitleRout>Route information's</TitleRout>
              <CoverButton>
                {isButtonLoading ? (
                  <Button>
                    <ButtonLoader />
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      handleToggleInput();
                      handleSubmit();
                    }}
                  >
                    {isInputDisabled ? "Edit" : "Submit"}
                  </Button>
                )}
                <More onClick={() => confirmDelete()}>
                  <i class="ri-delete-bin-line"></i>
                </More>
              </CoverButton>
            </RouteTop>
            <Form>
              <General>General</General>
              <CoverForm>
                <CoverIn>
                  <Label>Route name</Label>
                  <Input
                    type="text"
                    disabled={isInputDisabled}
                    value={routeName}
                    onChange={(e) => setRouteName(e.target.value)}
                  />
                </CoverIn>
                <CoverIn>
                  <Label>Route no</Label>
                  <Input
                    type="text"
                    value={routeNumber}
                    onChange={(e) => setRouteNumber(e.target.value)}
                    disabled
                  />
                </CoverIn>
              </CoverForm>
              <General className="veh">Vehicle & Driver details</General>
              <CoverForm>
                <CoverIn>
                  <Label>Vehicle name / number</Label>
                  <Input
                    type="text"
                    value={vehicleName}
                    onChange={(e) => setVehicleName(e.target.value)}
                    disabled={isInputDisabled}
                  />
                </CoverIn>
              
              </CoverForm>
              <CoverForm>
                <CoverIn>
                  <Label>Driver name</Label>
                  <Input
                    type="text"
                    value={driverName}
                    onChange={(e) => setDriverName(e.target.value)}
                    disabled={isInputDisabled}
                  />
                </CoverIn>
                <CoverIn>
                  <Label>Phone number</Label>
                  <Input
                    type="number"
                    value={driverNumber}
                    onChange={(e) => setDriverNumber(e.target.value.slice(0,10))}
                    disabled={isInputDisabled}
                  />
                </CoverIn>
              </CoverForm>
              <CoverForm>
                <CoverIn>
                  <Label>Co-driver name</Label>
                  <Input
                    type="text"
                    value={coDriver === "undefined" ? "" : coDriver}
                    onChange={(e) => setCoDriver(e.target.value)}
                    disabled={isInputDisabled}
                  />
                </CoverIn>
                <CoverIn>
                  <Label>Phone number</Label>
                  <Input
                    type="number"
                    value={coDriverNumber}
                    onChange={(e) => setCoDriverNumber(e.target.value.slice(0,10))}
                    disabled={isInputDisabled}
                  />
                </CoverIn>
              </CoverForm>
            </Form>
          </CoverInfo>
        </RouteInfo>
      </MainContainer>
    </CoverM>
  );
}

export default SingleRoute;
const CoverM = styled.div`
  overflow: scroll;
`;
const MainContainer = styled.div`
  padding: 20px;
  max-height: calc(100vh - 100px);
  overflow: scroll;
  overflow-x: hidden;
  
`;
const TopBar = styled.div`
  padding: 20px;
  background-color: #fff;
  position: relative;
  border: 1px solid #dfe8ed;
  margin-bottom: 20px;
`;
const TopCover = styled.div`
  display: flex;
`;
const View = styled(Link)`
  color: #2382d4;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  position: relative;
  display: inline-block;
  font-size: 20px;
  img {
    margin-left: 5px;
  }
  &::before {
    position: absolute;
    bottom: 0;
    content: "";
    background: #2382d4;
    width: 100%;
    height: 1px;
  }
`;
const Arrow = styled.div`
  border: 1px solid #2382d4;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const Text = styled.div``;
const TextCount = styled.div`
  /* width: 80%; */
`;
const CoverCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TitleCount = styled.div`
  color: #747474;
`;
const CoverTitle = styled.div`
  display: flex;
  font-family: "inter_regular";
  margin-bottom: 10px;
  span {
    color: #2382d4;
    margin-left: 10px;
  }
`;
const TopTitle = styled.h2`
  color: #2382d4;
  margin-bottom: 5px;
`;
const RoutePath = styled.h4`
  color: #747474;
`;
const Bottom = styled.div`
  display: flex;
  @media (max-width:768px) {
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap:20px;
  }
  @media (min-width: 769px)and (max-width:1080px) {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:20px;
  }
`;

const Container = styled.div`
  position: relative;
  background-color: #fff;
  padding: 20px 30px;
  width: 100%;
  /* height: 250px; */
  margin-right: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  &:last-child {
    margin-right: 0;
  }
  h1 {
    color: #2382d4;
    font-size: 40px;
    margin-right: 10px;
  }
  h3 {
    color: #2382d4;
    font-family: "inter_regular";
    font-size: 22px;
  }
`;
const Cover = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const Icon = styled.div`
  width: 55px;
  margin-bottom: 30px;
  img {
    width: 100%;
    display: block;
  }
`;
const Title = styled.h4`
  color: #747474;
  font-family: "inter_regular";
  font-weight: unset;
  font-size: 20px;
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  img {
    width: 100%;
    display: block;
  }
`;
const CoverInfo = styled.div`
  width: 65%;
  
  @media (max-width:1080px) {
    flex-direction:column;
    gap: 40px;
    width:100%;
  }
`;
const RouteInfo = styled.div`
  background-color: #fff;
  margin-top: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  padding: 30px;
`;
const RouteTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  gap: 14px;
`;
const CoverButton = styled.div`
  display: flex;
  align-items: center;
`;
const TitleRout = styled.h2`
  font-size: 24px;
  color: #0a0a0a;
`;
const Button = styled.div`
  width: 120px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Form = styled.form``;
const General = styled.h3`
  color: #0a0a0a;
  position: relative;
  overflow: hidden;
  font-size: 22px;
  margin-bottom: 30px;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 80px;
    background-color: #ebebeb;
    bottom: 10px;
  }
  &.veh {
    margin-top: 30px;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 290px;
      bottom: 10px;
      background-color: #ebebeb;
    }
  }
`;
const CoverForm = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;
const CoverIn = styled.div`
  width: 50%;
  margin-right: 20px;
  margin-bottom: 30px;
  &:nth-child(even) {
    margin-right: 0;
  }
`;
const Label = styled.h3`
  color: #747474;
  font-family: "inter_regular";
  margin-bottom: 10px;
`;
const Input = styled.input`
  width: 100%;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  height: 60px;
  font-size: 18px;
  padding-left: 20px;
  border-radius: 5px;
`;
const More = styled.div`
  font-size: 28px;
  margin-left: 10px;
  color: #dc3737;
  cursor: pointer;
`;
