import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../context/Store";
import styled from "styled-components";
import Nodata from "../../../includes/nodata/Nodata";
import Loader from "../../../../components/loader/Loader";
import { totalicon } from "../../../../components/assets";
import { Card } from "../../../../components";
import DatePicker from "react-datepicker";
import api from "../../../../api";
import { formatINR, getDateStr, getYYMMDD } from "../../../../utils/functions/function";

export default function PaymentHistory() {
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEnddate] = useState("");
  const [isPrintButtonLoading, setIsPrintButtonLoading] = useState(false);
  const currentDate = new Date();

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const token = user_data.access_token;

  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_sub_menu: "payments-history",
        active_menu: "payments",
      },
    });
  }, []);
  const customStyles = {
    width: "100px",
    height: "30px",
    borderRadis: "8px",
    // Add any other custom styles here if needed
  };
  const CustomInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      style={{ width: "100px", height: "30px" }}
    />
  );
  useEffect(() => {
    setIsLoading(true);

    api
      .get("payments/society-list-payment-history/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          from_date: getYYMMDD(startDate),
          to_date: getYYMMDD(endDate),
        },
      })
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setPaymentDetails(res.app_data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setPaymentDetails([]);
        }
      });
  }, [startDate, endDate]);
  const handleDownload = async () => {
    setIsPrintButtonLoading(true);
    try {
      const apiUrl =
        "https://api.jeevamilk.com/api/v1/activities/print-bank-details/";

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setIsPrintButtonLoading(false);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Extract the blob from the response
      const blob = await response.blob();

      // Create a temporary URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute of the link to the URL of the Blob
      link.href = url;

      // Set the download attribute with the desired file name
      link.download = "milk-report";

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      // Revoke the URL to release the resources
      URL.revokeObjectURL(url);
    } catch (error) {
      setIsPrintButtonLoading(false);
      console.error("Error downloading file:", error);
    }
  };
  return (
    <Container>
      <Top>
        <h3>Payment history</h3>
        <TopRight>
          {
            !startDate && !endDate &&
            <DateContainer>{getDateStr(currentDate)}</DateContainer>
          }
          <ButContainer>From</ButContainer>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            required
            customInput={<CustomInput />}
            dateFormat="dd/MM/yyyy"
            style={customStyles}
          />
          <ButContainer>To</ButContainer>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEnddate(date)}
            required
            dateFormat="dd/MM/yyyy"
            customInput={<CustomInput />}
            style={customStyles}
          />
          {startDate && endDate && (
            <FilterButton onClick={() => window.location.reload()}>
              <i class="ri-restart-line"></i>
            </FilterButton>
          )}
          {/* <FilterButton onClick={() => handleDownload()}>
            {
              isPrintButtonLoading ?
              "Loading..."
              :
              "Print"
            }
          </FilterButton> */}
        </TopRight>
      </Top>
      <Cards>
        <Card
          title="Credit"
          icon={totalicon}
          number={
            paymentDetails?.credited_amount ?
            formatINR(paymentDetails?.credited_amount)
            :
            formatINR(0)
          }
        />
        <Card
          title="Debit"
          icon={totalicon}
          // number={
          //   paymentDetails?.debited_amount &&
          //   formatINR(
          //     paymentDetails?.debited_amount
          //       ? paymentDetails?.debited_amount
          //       : 0
          //   )
          // }
          number={
            paymentDetails?.debited_amount ?
            formatINR(paymentDetails?.debited_amount)
            :
            formatINR(0)
          }
        />
      </Cards>
      <div className="parent-scroll">
        <ListContainer className="scroll">
          <HeadRow>
            <HeadColumnData>Sl. no</HeadColumnData>
            <HeadColumnData>Detail</HeadColumnData>
            <HeadColumnData>Status</HeadColumnData>
            <HeadColumnData>Amount</HeadColumnData>
            {/* <HeadColumnData>A/C Type</HeadColumnData> */}
          </HeadRow>
          <BottomContainer>
            {isLoading ? (
              <Loader />
            ) : paymentDetails?.data?.length == 0 ? (
              <Nodata />
            ) : (
              paymentDetails?.data?.map((row, index) => (
                <Row key={index}>
                  <ColumnData className="bold">{index + 1}</ColumnData>
                  <ColumnData className="bold">{row.detail}</ColumnData>
                  <ColumnData>{row.status}</ColumnData>
                  <ColumnData>₹{row.amount}</ColumnData>
                  {/* <ColumnData className="bold">₹{row.total_expense}</ColumnData> */}
                </Row>
              ))
            )}
          </BottomContainer>
        </ListContainer>
      </div>
    </Container>
  );
}

const Container = styled.div`
  padding: 20px;
  height: calc(100vh - 100px);
  overflow-y: scroll;
`;
const Cards = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 20px;
  @media (max-width: 1080px) {
    width: 100%;
  }
  @media (max-width:640px) {
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap:20px;
  }
`;
const BottomContainer = styled.div`
  max-height: calc(100vh - 250px);
  overflow-y: scroll;
`;

const ListContainer = styled.div`
  background-color: #fff;
  /* padding: 0 24px; */
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  /* height: calc(100vh - 300px); */
  margin-bottom: 20px;

  h3 {
    color: #747474;
    font-family: "inter_regular";
    font-size: 22px;
  }
`;

const HeadRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: 1px solid #ccc;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: 1px solid #ccc;
`;

const Curve = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  img {
    width: 100%;
    display: block;
  }
`;
const HeadColumnData = styled.div`
  color: #747474;
  font-size: 18px;
  font-family: "inter_light";
  width: 15%;
  &:first-child {
    width: 7%;
  }
  &:nth-last-child(3) {
    width: 25%;
  }
  &:nth-last-child(2) {
    width: 10%;
  }
  &:last-child {
    text-align: right;
  }
`;
const ColumnData = styled.div`
  color: #0a0a0a;
  font-size: 18px;
  font-family: "inter_medium";
  width: 15%;
  &:first-child {
    width: 7%;
  }
  &:nth-last-child(3) {
    width: 25%;
  }
  &:nth-last-child(2) {
    width: 10%;
  }
  &:last-child {
    text-align: right;
  }
  &.bold {
    font-family: "inter_regular";
  }
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap:10px;
  h3 {
    font-size: 28px;
  }
`;

const FilterButton = styled.button`
  border: none;
  outline: none;
  border: 1px solid #2382d4;
  padding: 5px 12px;
  border-radius: 8px;
  position: relative;
`;
const TopRight = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 1080px) {
    min-width: 610px;
    overflow: scroll;
  }
`;
const DateContainer = styled.div`
  color: #747474;
  font-family: "inter_regular";
  width: 100%;
  text-align: end;
`;
const ButContainer = styled.div`
  padding: 5px 8px;
  background: #2382d4;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
`;
