import React, { useContext, useEffect, useState } from "react";
import {
    Link,
    Navigate,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../../context/Store";
import {
    deleteFarmerMilk,
    getArrivals,
    submitArrivals,
    submitSocietyArrivals,
} from "../../../api/activities";
import NodataFound from "../../includes/nodata/NodataFound";
import { arrivalsSociety } from "../../../api/auth";
import ArrivalModal from "./Modal";

const ArrivalsList = () => {
    const [modal, setModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedArrival, setSelectedArrival] = useState("");
    const [getData, setData] = useState([]);
    const [totalMilk, setTotalMilk] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const token = user_data.access_token;
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    useEffect(() => {
        dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
                active_menu: "arrivals",
            },
        });
    }, []);

    // useEffect(() => {
    //   arrivalsSociety(config, id)
    //     .then((res) => {
    //       if (res.app_data.StatusCode == 6000) {
    //         setData(res.app_data.data);
    //       }
    //     })
    //     .catch((err) => {});
    // }, []);
    useEffect(() => {
        // Fetch arrivals data and calculate total milk
        arrivalsSociety(config, id)
            .then((res) => {
                if (res.app_data.StatusCode === 6000) {
                    setData(res.app_data.data);
                    const total = calculateTotalMilkInRows();
                    setTotalMilk(total);
                } else {
                    setData(res.app_data.data);
                    const total = calculateTotalMilkInRows();
                    setTotalMilk(total);
                }
            })
            .catch((err) => {
                console.error("Error fetching data:", err);
            });
    }, [selectedArrival]);
    const submitHandler = () => {
        setModal(true);
        const newTotalMilk = calculateTotalMilkInRows(); // Calculate total milk
        setTotalMilk(newTotalMilk);
    };
    const cancelHandler = () => {
        setDeleteModal(false);
    };
    const confirmSubmit = (id) => {
        deleteFarmerMilk(config, id)
            .then((res) => {
                if (res.app_data.StatusCode == 6000) {
                    // navigate("/milk-report");
                    // window.location.reload();
                    setDeleteModal(false);
                    setSelectedArrival("");
                }
            })
            .catch((error) => {});
    };
    const calculateTotalMilkInRows = () => {
        let total = 0;
        getData.forEach((item) => {
            total += item.total_milk;
        });
        return total;
    };
    return (
        <>
            <TotalContainer>
                <CoverTop>
                    <Top>
                        <h3>Arrivals</h3>
                        <Button to="/add-arrivals">Add Arrivals</Button>
                    </Top>
                    <div className="parent-scroll">
                        <Bottom className="scroll">
                            <TableHead>
                                <TableItem className="farmer-index">
                                    Sl No.
                                </TableItem>
                                <TableItem>Farmer name</TableItem>
                                <TableItem>Total milk</TableItem>
                                <TableItem>Damaged Milk</TableItem>
                                <TableItem className="milk-value">CLR</TableItem>
                                <TableItem className="milk-value">FAT</TableItem>
                                <TableItem className="milk-value">SNF</TableItem>
                                <TableItem>Price(L)</TableItem>
                                <TableItem>Total price</TableItem>
                                <TableItem className="action">Action</TableItem>
                            </TableHead>
                            <TableBody>
                                {getData?.length == 0 ? (
                                    <NodataFound />
                                ) : (
                                    getData.map((item, index) => (
                                        <TableContainer key={index}>
                                            <TableItems className="farmer-index">
                                                {index + 1}{" "}
                                            </TableItems>
                                            {/* <TableItems
                                              title={item.farmer_id}
                                              className="farmer-id"
                                          >
                                              {item.farmer_id}{" "}
                                          </TableItems> */}
                                            <TableItems
                                                className="name"
                                                title={item.farmer_name}
                                            >
                                                {item.farmer_name}
                                            </TableItems>
                                            <TableItems>
                                                {item.total_milk}
                                            </TableItems>
                                            <TableItems>
                                                {item.damaged_milk_quantity
                                                    ? item.damaged_milk_quantity
                                                    : "0"}
                                            </TableItems>
                                            <TableItems className="milk-value">
                                                {item.clr_value}
                                            </TableItems>
                                            <TableItems className="milk-value">
                                                {item.fat_value}
                                            </TableItems>
                                            <TableItems className="milk-value">
                                                {item.snf_value}
                                            </TableItems>
                                            <TableItems>
                                                {item.liter_price}
                                            </TableItems>
                                            <TableItems className="total">
                                                {item.total_price}
                                            </TableItems>
                                            <TableItems className="total action">
                                                <i
                                                    class="ri-file-edit-fill"
                                                    onClick={() =>
                                                        navigate(
                                                            `/edit-arrivals/${item.id}`
                                                        )
                                                    }
                                                ></i>
                                                <i
                                                    class="ri-delete-bin-6-line"
                                                    style={{
                                                        color: "red",
                                                        marginLeft: "10px",
                                                    }}
                                                    onClick={() => {
                                                        setSelectedArrival(
                                                            item.id
                                                        );
                                                        setDeleteModal(true);
                                                    }}
                                                ></i>
                                            </TableItems>
                                        </TableContainer>
                                    ))
                                )}
                            </TableBody>
                        </Bottom>
                    </div>
                </CoverTop>
                {getData?.length !== 0 && (
                    <CoverButton>
                        <ButtonNew onClick={() => submitHandler()}>
                            Submit Data
                        </ButtonNew>
                        {modal && (
                            <ArrivalModal
                                open={modal}
                                totalMilk={calculateTotalMilkInRows()}
                                setOpen={setModal}
                            />
                        )}
                    </CoverButton>
                )}
            </TotalContainer>
            {deleteModal && (
                <Modal>
                    <ModalContainer>
                        <p>Are you sure you want to delete this entry?</p>
                        <ButtonContainer>
                            <ButtonNew onClick={() => cancelHandler()}>
                                Cancel
                            </ButtonNew>
                            <ButtonNew
                                onClick={() => confirmSubmit(selectedArrival)}
                                className="confirm"
                            >
                                Confirm
                            </ButtonNew>
                        </ButtonContainer>
                    </ModalContainer>
                </Modal>
            )}
        </>
    );
};

export default ArrivalsList;

const CoverTop = styled.div``;
const CoverButton = styled.div`
    margin: 15px 20px;
`;
const TotalContainer = styled.div`
    margin-top: 30px;
    padding: 0px 15px;
    height: calc(100vh - 110px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow-y: scroll;
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
        font-size: 28px;
    }
`;
const Button = styled(Link)`
    background: #2382d4;
    width: 150px;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Bottom = styled.div`
    padding-top: 15px;
`;
const TableHead = styled.div`
    height: 70px;
    background: #fff;
    padding: 0px 15px;
    display: flex;
    align-items: center;
`;
const TableBody = styled.div`
    height: calc(100vh - 300px);
    overflow: scroll;
`;

const Icon = styled.div``;
const TableItem = styled.div`
    width: 20%;
    color: #747474;
    font-size: 18px;
    &.farmer-index {
        width: 5%;
    }
    &.milk-value {
        width: 10%;
    }
    &.action {
        width: 5%;
    }
`;
const TableItems = styled.div`
    width: 20%;
    color: #000000;
    font-size: 18px;
    &.total {
        color: #2382d4;
        cursor: pointer;
    }
    &.name {
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &.farmer-id {
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 5px;
        /* width: 10%; */
    }
    &.farmer-index {
        width: 5%;
    }
    &.milk-value {
        width: 10%;
    }
    &.action {
        width: 5%;
    }
`;
const TableContainer = styled.div`
    height: 70px;
    background: #fff;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    margin-top: 15px;
`;
const Modal = styled.div`
    width: 100%;
    height: 100vh;
    background: #00000070;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ModalContainer = styled.div`
    width: 45%;
    padding: 60px;
    background: #fff;
    border-radius: 10px;
    text-align: center;
    p {
        margin-bottom: 20px;
        font-weight: 400;
        color: #0a0a0a;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ButtonNew = styled.button`
    background: #2382d4;
    width: 150px;
    height: 40px;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    margin: 0 auto;
    &.confirm {
        background-color: #23d473;
    }
`;
