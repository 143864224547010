import React from "react";
import { styled } from "styled-components";

const Input = ({ label, type, placaholder, margin, ...prop }) => {
  return (
    <Cover margin={margin}>
      <label>{label}</label>
      <input type={type} placaholder={placaholder} {...prop} />
    </Cover>
  );
};

export default Input;

const Cover = styled.div`
  width: 100%;
  // height:40px;
  // background:red;
  label {
    font-size: 16px;
    color: #929292;
    font-family: "inter_light";
  }
  input {
    width: 100%;
    height: 35px;
    outline: none;
    border: 1.5px solid #e0e0e0;
    border-radius: 5px;
    padding: 2px 10px;
    margin-bottom: ${({ margin }) => margin};
    &::placeholder {
      // color:red;
    }
  }
`;
