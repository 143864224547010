import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Calender } from "../../../../components/assets";
import RectangleColorButton from "../../../../components/Buttons/RectangleColorButton";
import ButtonLoader from "../../../../components/button-loader/ButtonLoader";
import { getYYMMDD } from "../../../../utils/functions/function";
import DatePicker from "react-datepicker";
import { editBonus, getSingleBonus } from "../../../../api/auth";
import { Context } from "../../../../context/Store";
import { useNavigate } from "react-router-dom";
import { editFarmerBonus, getSingleFarmerBonus } from "../../../../api/society";

function ViewEditSocBonus(prop) {
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [startDate, setStartdate] = useState(false);
  const [endDate, setEndDate] = useState(false);

  const [occasion, setOccasion] = useState("");
  const [bonusAmount, setBonusAmount] = useState("");

  // get accesstoken from store
  const {
    state: { user_data, route_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const bonusId = user_data.bonus_id;
  const navigate = useNavigate();

  //get Bonus data
  useEffect(() => {
    getSingleFarmerBonus(config, bonusId).then((res) => {
      const data = res.app_data.data;
      if (res.app_data.StatusCode == 6000) {
        setOccasion(data.bonus_description);
        setBonusAmount(data.bonus_amount);
        setEndDate(data.end_date);
        setStartdate(data.start_date);
      } else {
      }
    });
  }, []);

  //edit bonus
  const formData = new FormData();
  formData.append("bonus_description", occasion);
  formData.append("start_date", getYYMMDD(startDate));
  formData.append("end_date", getYYMMDD(endDate));
  formData.append("bonus_amount", bonusAmount);

  const editBonusData = (e) => {
    e.preventDefault();
    editFarmerBonus(bonusId, formData, config).then((res) => {
      const data = res.app_data.data;
      if (res.app_data.StatusCode == 6000) {
        navigate("/bonus");
      } else {
      }
    });
  };
  return (
    <Container>
      <TopBox>
        <Heading> Bonus</Heading>
        View / Edit
      </TopBox>
      <BottomBox onSubmit={(e) => editBonusData(e)}>
        <GeneralBox>
          <TitleBox>
            <Title>General</Title>
            <HrLine></HrLine>
          </TitleBox>

          <DualBox>
            <SingleBox>
              <Label>Particular / occasion</Label>
              <InputBox>
                <input
                  type="text"
                  placeholder="Place Value"
                  value={occasion}
                  onChange={(e) => setOccasion(e.target.value)}
                  required
                />
              </InputBox>
            </SingleBox>
            <SingleBox>
              {/* <Label>Time period</Label> */}
              <CoverInput>
                <CoverLabel>
                  <Label>From Date</Label>
                  <Input>
                    <DatePicker
                      value={
                        startDate === "" ? "DD/MM/YYYY" : getYYMMDD(startDate)
                      }
                      onChange={(date) => setStartdate(date)}
                      required
                    />
                    <ImageContainer>
                      <img src={Calender} alt="" />
                    </ImageContainer>
                  </Input>
                </CoverLabel>
                <CoverLabel>
                  <Label>To Date</Label>
                  <Input>
                    <DatePicker
                      value={endDate === "" ? "DD/MM/YYYY" : getYYMMDD(endDate)}
                      onChange={(date) => setEndDate(date)}
                      required
                    />
                    <ImageContainer>
                      <img src={Calender} alt="" />
                    </ImageContainer>
                  </Input>
                </CoverLabel>
              </CoverInput>
            </SingleBox>
          </DualBox>
        </GeneralBox>

        <InfoBox>
          <TitleBox>
            <Title>Bonus Info</Title>
            <HrLine></HrLine>
          </TitleBox>

          <InfoBottomBox>
            <ContentBox>
              <UnitBox>
                <label>Bonus Amount</label>
                <InputBox>
                  <input
                    type="text"
                    placeholder="00"
                    value={bonusAmount}
                    onChange={(e) => setBonusAmount(e.target.value)}
                    required
                  />
                  <span>per liter</span>
                </InputBox>
              </UnitBox>
            </ContentBox>

            {/* <CheckBox>
              <input type="checkbox" placeholder="00" />
              <label>Send message for all farmers</label>
            </CheckBox> */}
          </InfoBottomBox>

          <ButtonBox>
            {isButtonLoading ? (
              <RectangleColorButton label={<ButtonLoader />} />
            ) : (
              <Button type="submit" value="Submit" />
            )}
          </ButtonBox>
        </InfoBox>
      </BottomBox>
    </Container>
  );
}

export default ViewEditSocBonus;

const Container = styled.div`
  width: 60%;
  margin: 0 auto;
  margin-top: 60px;
`;

const TopBox = styled.div`
  margin-bottom: 20px;
  color: #8a8a8a;
  font-family: "inter_regular";
`;

const Heading = styled.h2`
  color: #0a0a0a;
  font-size: 26px;
`;

const HeadTag = styled.p`
  margin-top: 6px;
  color: #747474;
  font-size: 16px;
  font-family: "inter_light";
`;

const BottomBox = styled.form`
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

const Title = styled.pre`
  font-size: 18px;
  margin-right: 10px;
`;
const HrLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eee;
`;
const GeneralBox = styled.div`
  width: 100%;
  margin-bottom: 26px;
`;

const InfoBottomBox = styled.div``;

const SingleBox = styled.div`
  width: 100%;
  input {
    background-color: transparent;
    width: 100%;
    font-size: 18px;
  }
`;

const InputBox = styled.div`
  background-color: #f4f5f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #dfe8ed;
  position: relative;
  input {
    font-family: "inter_light";
  }
`;

const TrioBox = styled.div``;

const DualBox = styled.div`
  display: flex;
  justify-content: space-between;

  ${SingleBox} {
    width: 49%;
  }
  /* input {
        padding: 18px 24px;
        border: 1px solid #dfe8ed;
        border-radius: 4px;
        width: 100%;
        margin: 10px 0;
        font-size: 18px;
    } */
`;
const Label = styled.h4`
  color: #747474;
  font-family: "inter_light";
  font-weight: unset;
  font-size: 18px;
`;

const InfoBox = styled.div`
  width: 100%;
`;

const ToggleBox = styled.div`
  width: 40%;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 18px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: -4px;
    bottom: -4px;
    background-color: white;
    border: 2px solid #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
    &::before {
      border: 2px solid #2196f3;
    }
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

const ToggleTitle = styled.div``;
const ContentBox = styled.div`
  margin-bottom: 48px;
`;
const SearchResults = styled.div`
  height: 300px;
  overflow: scroll;
  background-color: #f2f2f2;
  position: absolute;
  border-radius: 0 0 20px 20px;
  top: 60px;
  padding: 10px;
  left: 0;
  width: 100%;
  z-index: 10;
`;
const Result = styled.div`
  background-color: #e5e5e5;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 10px;
`;
const UnitBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  label {
    color: #747474;
    font-family: "inter_light";
    font-weight: unset;
    font-size: 18px;
  }

  ${InputBox} {
    width: 50%;
    margin: 0;
    input {
      font-size: 18px;
      background-color: transparent;
    }

    span {
      font-family: "inter_light";
      font-size: 16px;
    }
  }
`;

const CheckBox = styled.div`
  input {
    margin-right: 8px;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;
const Input = styled.div`
  width: 100%;
  position: relative;
  background-color: #f4f5f8;
  padding: 18px 24px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #dfe8ed;
`;
const ImageContainer = styled.div`
  margin-left: 5px;
  width: 18px;
  position: absolute;
  right: 10px;
  top: 20px;
  img {
    display: block;
    width: 100%;
  }
`;
const CoverInput = styled.div`
  display: flex;
  align-items: center;
`;
const CoverLabel = styled.div`
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
`;
const ErrorMessage = styled.span`
  color: red;
  margin-bottom: 5px;
  text-align: right;
  width: 100%;
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
`;
const Button = styled.input`
  width: 160px;
  cursor: pointer;
  height: 48px;
  /* cursor: pointer; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  padding: 6px 12px;
  background: #2382d4;
  color: #fff;
`;
