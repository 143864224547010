import React from "react";
import { styled } from "styled-components";
import { curve } from "../assets";
import { Link } from "react-router-dom";

function Card({ title, icon, number, label, path }) {
  return (
    <Container to={path}>
      <Icon>
        <img src={icon} alt="icon" />
      </Icon>
      <Title>{title}</Title>
      <Cover>
        <h1>{number}</h1>
        <h3>{label} </h3>
      </Cover>
      <Curve>
        <img src={curve} alt="image" />
      </Curve>
    </Container>
  );
}

export default Card;

const Container = styled(Link)`
  position: relative;
  background-color: #fff;
  padding: 20px 30px;
  display: block;
  width: 100%;
  text-decoration: none;
  /* height: 250px; */
  margin-right: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  z-index: 0;
  &:last-child {
    margin-right: 0;
  }
  h1 {
    color: #2382d4;
    font-size: 40px;
    margin-right: 10px;
  }
  h3 {
    color: #2382d4;
    font-family: "inter_regular";
    font-size: 22px;
  }
`;
const Cover = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const Icon = styled.div`
  width: 55px;
  margin-bottom: 30px;
  img {
    width: 100%;
    display: block;
  }
`;
const Title = styled.h4`
  color: #747474;
  font-family: "inter_regular";
  font-weight: unset;
  font-size: 20px;
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  img {
    width: 100%;
    display: block;
  }
`;
