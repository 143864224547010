import React from "react";
import { styled } from "styled-components";
import { NotificationIcon } from "../../../components/assets/icons";
import { truck, mail, paymentIcon } from "../../../components/assets";

function Notifications() {
    const rows = [
        {
            serialNumber: 1,
            column1: truck,
            column2: "The cargo has received in the Kottayam",
        },
        {
            serialNumber: 2,
            column1: mail,
            column2: "The Mundakayam Branch has requested for paymentIcon",
        },
        {
            serialNumber: 3,
            column1: paymentIcon,
            column2: "The paymentIcon Received from Kattapana Branch",
        },
        {
            serialNumber: 4,
            column1: truck,
            column2: "The cargo has received in the Kottayam",
        },
        {
            serialNumber: 5,
            column1: mail,
            column2: "The Mundakayam Branch has requested for paymentIcon",
        },
        {
            serialNumber: 6,
            column1: paymentIcon,
            column2: "The paymentIcon Received from Kattapana Branch",
        },
        {
            serialNumber: 7,
            column1: truck,
            column2: "The cargo has received in the Kottayam",
        },
        {
            serialNumber: 8,
            column1: mail,
            column2: "The Mundakayam Branch has requested for paymentIcon",
        },
        {
            serialNumber: 9,
            column1: paymentIcon,
            column2: "The paymentIcon Received from Kattapana Branch",
        },
        
    ];

    return (
        <Container>
            <TopBox>
                <Title>Notifications</Title>
            </TopBox>
            <BottomBox>
                {rows.map((row) => (
                    <QuantityContainer>
                        <NameBox>
                            <ImageContainer>
                                <img src={row?.column1} alt="Image" />
                            </ImageContainer>
                            <ContentContainer>
                                <TextBox>{row?.column2}</TextBox>
                            </ContentContainer>
                        </NameBox>
                        <IconContainer>
                            <img src={NotificationIcon} alt="Icon" />
                        </IconContainer>
                    </QuantityContainer>

                ))}
            </BottomBox>
        </Container>
    );
}

export default Notifications;

const Container = styled.div`
    padding: 20px;
    border-radius: 8px;
`;
const TopBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Title = styled.h2`
    color: #0a0a0a;
    font-size: 26px;
`;
const BottomBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    background-color: #fff;
    border: 1px solid #dfe8ed;
    border-radius: 8px;
    max-height: calc(100vh - 180px);
    overflow: scroll;
`;

const QuantityContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 97px;
`;
const NameBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;
`;

const IconContainer = styled.div``;

const ContentContainer = styled.div``;
const TextBox = styled.div``;
