import { useContext } from "react";
import api from "..";
import { Context } from "../../context/Store";
import { getYYMMDD } from "../../utils/functions/function";

export const LoginFunction = (username, password) => {
    return api
        .post("/accounts/plant-login/", {
            username: username,
            password: password,
        })
        .then((response) => {
            return response; // Return the response to the outer function's promise chain
        });
};

export const TotalCounts = (config) => {
    return api.get("/accounts/plant-counts/", config).then((res) => {
        return res;
    });
};

// --------------creation apis-------POST--------
export const CreatingRoute = (config, formData) => {
    return api.post("accounts/create-route/", formData, config).then((res) => {
        return res;
    });
};
export const CreatingSociety = (config, formData) => {
    return api
        .post("accounts/create-society/", formData, config)
        .then((res) => {
            return res;
        });
};
export const CreatingFarmer = (config, formData) => {
    return api.post("accounts/create-farmer/", formData, config).then((res) => {
        return res;
    });
};

//---------------------Route view---------GET------------

export const RouteView = (config, searchedItems) => {
    const apiUrl = `accounts/route-view/`;
    return api
        .get(apiUrl, { params: { q: searchedItems } }, config)
        .then((res) => {
            return res;
        });
};
//-------------------Route single page------GET------------

export const RouteSingleView = (config, id) => {
    return api.get(`/accounts/route/${id}/`, config).then((res) => {
        return res;
    });
};

//-------------------Route edit----------POST--------

export const RouteSingleEdit = (config, formData, id) => {
    return api
        .post(`accounts/route-edit/${id}/`, formData, config)
        .then((res) => {
            return res;
        });
};

//-------------------Route societis ------GET------------

export const RouteSocieties = (config, id, params) => {
    const apiUrl =
        params === "all"
            ? `accounts/society-list-view/${id}/`
            : `accounts/society-list-view/${id}/?society_type=${params}`;

    return api.get(apiUrl, config).then((res) => {
        return res;
    });
};

//-------------------Route farmers ------GET------------

// export const RouteFarmers = (config, id, currentPage) => {
//   const apiUrl = `accounts/farmer-list-view/${id}/`;

//   return api.get(apiUrl, config).then((res) => {
//     return res;
//   });
// };
export const RouteFarmers = (config, id, currentPage) => {
    const apiUrl = `accounts/farmer-list-view/${id}`;
    return api
        .get(apiUrl, { params: { page: currentPage } }, config)
        .then((res) => {
            return res;
        });
};

// -------------------Routes farmer single page--------GET----------
export const RouteFarmerSingle = (config, id) => {
    const apiUrl = `activities/farmer-milk-status/${id}/`;

    return api.get(apiUrl, config).then((res) => {
        return res;
    });
};
//-------------------Societies List in farmer creation--------GET----------

export const SocietiesList = (config, id) => {
    const apiUrl = `accounts/society-list-view/${id}/`;

    return api.get(apiUrl, config).then((res) => {
        return res;
    });
};

// ------------list all societies------------GET-----------
// export const SocietiesListView = (config, params, page) => {
//   const apiUrl =
//     params === "all"
//       ? `accounts/societies/?page=${page}`
//       : `accounts/societies/?society_type=${params}/?page=${page}`;

//   return api.get(apiUrl, config).then((res) => {
//     return res;
//   });
// };

export const SocietiesListView = (config, searchedItem, society_type, page) => {
    let apiUrl = `accounts/societies/`;
    const params = { society_type: society_type, page: page };

    if (searchedItem === "list-type-all") {
        apiUrl += `?list_by=all`;
    } else if (searchedItem) {
        // Include `q` only if `searchedItem` is provided and not equal to "list-type-all"
        params.q = searchedItem;
    }

    return api.get(apiUrl, { params: params }, config).then((res) => {
        return res;
    });
};
// ------------- list all farmers--------GET-----
export const FarmersView = (config, id, params) => {
    const apiUrl = `accounts/farmer-list-view/`;

    return api.get(apiUrl, config).then((res) => {
        return res;
    });
};

// -----------------society single page ----------GET-------------
export const SocietySingleView = (config, id) => {
    const apiUrl = `accounts/society-single/${id}`;

    return api.get(apiUrl, config).then((res) => {
        return res;
    });
};

// -------------add society bonus----POST------------POST--------
export const AddSocietyBonus = (config, formData) => {
    return api
        .post(`activities/add-society-bonus/`, formData, config)
        .then((res) => {
            return res;
        });
};
// ---------------------get Single bonus---------------GET----------

export const getSingleBonus = (config, bonusId) => {
    return api
        .get(`activities/single-society-bonuses/${bonusId}`, config)
        .then((res) => {
            return res;
        });
};
// ---------------------get bonus---------------GET----------
export const GetBonusDatas = (config, id) => {
    const apiUrl = `activities/society-bonuses/`;

    return api.get(apiUrl, config).then((res) => {
        return res;
    });
};
// ---------------------Edit bonus---------------GET----------

export const editBonus = (config, formData, bonusId) => {
    return api
        .post(`activities/edit-society-bonus/${bonusId}/`, formData, config)
        .then((res) => {
            return res;
        });
};
// -------------add incentives ---------------POST---------
export const AddPlantIncentives = (config, formData) => {
    return api
        .post(`activities/add-society-incentive/`, formData, config)
        .then((res) => {
            return res;
        });
};

// -----------------get incentives---------------GET----------
export const GetIncentives = (config, id) => {
    const apiUrl = `/activities/society-incentives/`;

    return api.get(apiUrl, config).then((res) => {
        return res;
    });
};
// -----------------get Single incentives---------------GET----------
export const getSingleIncentive = (config, incentiveId) => {
    const apiUrl = `activities/single-society-incentives/${incentiveId}`;

    return api.get(apiUrl, config).then((res) => {
        return res;
    });
};
// ---------------------Edit incentive---------------GET----------

export const editIncentive = (config, formData, incentiveId) => {
    return api
        .post(
            `activities/edit-society-incentive/${incentiveId}/`,
            formData,
            config
        )
        .then((res) => {
            return res;
        });
};
// -----------------get farmer list-------------GET------------
// export const getAllFarmers = (config, params, type) => {
//   const apiUrl =
//     type === "soiciety_farmers"
//       ? `accounts/farmers-list/?society_id=${params}`
//       : `accounts/farmers-list/?page=${params}`;

//   return api.get(apiUrl, config).then((res) => {
//     return res;
//   });
// };

export const getAllFarmers = (config, params, currentPage, searchedItem) => {
    const apiUrl = `accounts/farmers-list/`;
    return api
        .get(
            apiUrl,
            {
                params: {
                    society_id: params,
                    page: currentPage,
                    q: searchedItem,
                },
            },
            config
        )
        .then((res) => {
            return res;
        });
};

// -----------------Arrivals----------------POST---------
export const AddArrivals = (config, formData) => {
    return api
        .post(`activities/plant-milk-receive-creation/`, formData, config)
        .then((res) => {
            return res;
        });
};
export const AddArrivalsApi = (config, formData) => {
    return api
        .post(`activities/create-farmer-milk/`, formData, config)
        .then((res) => {
            return res;
        });
};

// -----------------Society edit----------------POST---------
export const EditSociety = (config, formData, id) => {
    return api
        .post(`accounts/society-edit/${id}/`, formData, config)
        .then((res) => {
            return res;
        });
};

// -----------------Farmer edit----------------POST---------
export const EditFarmer = (config, formData, id) => {
    return api
        .post(`accounts/farmer-edit/${id}/`, formData, config)
        .then((res) => {
            return res;
        });
};

// -----------------get local sales-------------GET------------
export const getLocalSales = (config, id) => {
    const apiUrl = `activities/view-plant-local-sales/${id}`;

    return api.get(apiUrl, config).then((res) => {
        return res;
    });
};

// -----------------Create Loan----------------POST---------
export const CreateLoan = (config, loanFormdata, id) => {
    return api
        .post(`activities/create-society-loan/`, loanFormdata, config)
        .then((res) => {
            return res;
        });
};

// -----------------get loans------------GET------------
export const ViewLoans = (config) => {
    const apiUrl = `activities/view-loan/`;

    return api.get(apiUrl, config).then((res) => {
        return res;
    });
};

// -----------------View Loan------------GET------------
export const ViewLoanData = (config, Id) => {
    const apiUrl = `activities/view-society-loan/${Id}/`;

    return api.get(apiUrl, config).then((res) => {
        return res;
    });
};

// --------------------milk status--------------------
export const getMilkStatus = (config, param) => {
    const apiUrl = `activities/plant-milk-status/?time=${param}`;
    return api.get(apiUrl, config).then((res) => {
        return res;
    });
};
export const plantQuantityGraphData = (config, filterValues) => {
    const apiUrl = `activities/plant-milk-quantity-graph/?days=${filterValues}`;
    return api.get(apiUrl, config).then((res) => {
        return res;
    });
};

export const plantQualityGraphData = (config, QualityfilterValues) => {
    const apiUrl = `activities/plant-milk-quality-graph/?days=${QualityfilterValues}`;
    return api.get(apiUrl, config).then((res) => {
        return res;
    });
};

// -----------------Print Milk status----------------POST---------
export const printMilkstatus = (config) => {
    return api
        .post(`activities/print-current-screen/`, {}, config)
        .then((res) => {
            return res;
        });
};

// -----------------Update Milk Rate----------------POST---------
export const updateMilkRate = (config, milkrateData) => {
    return api
        .post(`activities/update-milk-rate/`, milkrateData, config)
        .then((res) => {
            return res;
        });
};

//-----------Delet Route------------POST------------
export const deleteRoute = (config, id) => {
    return api.post(`accounts/route-delete/${id}/`, {}, config).then((res) => {
        return res;
    });
};

//-----------Delet Farmer------------POST------------
export const deleteFarmer = (id, config) => {
    return api.post(`accounts/farmer-delete/${id}/`, {}, config).then((res) => {
        return res;
    });
};

// -----------------get loans------------GET------------
export const plantArrivals = (config, id, startDate, endDate) => {
    const apiUrl = `activities/plant-arrivals/`;

    return api
        .get(
            apiUrl,
            {
                params: {
                    society_id: id,
                    from_date: getYYMMDD(startDate),
                    to_date: getYYMMDD(endDate),
                },
            },
            config
        )
        .then((res) => {
            return res;
        });
};

export const arrivalsSociety = (config, id, startDate, endDate) => {
    const apiUrl = `activities/list-society-arrivals/`;

    return api
        .get(
            apiUrl,
            {
                params: {
                    society_id: id,
                    from_date: getYYMMDD(startDate),
                    to_date: getYYMMDD(endDate),
                },
            },
            config
        )
        .then((res) => {
            return res;
        });
};
export const milkStatusSociety = (
  config, 
  currentPage,
  startDate, 
  endDate, 
  isLastTenDays, 
  isLastMonth, 
  phaseOfDay, 
  milkQuantity
) => {
    const apiUrl = `activities/society-milk-value-plant/`;

    return api
        .get(
            apiUrl,
            {
                params: {
                    page: currentPage,
                    start_date: startDate,
                    end_date: endDate,
                    is_last_ten_days: isLastTenDays,
                    is_last_month: isLastMonth,
                    phase_of_day: phaseOfDay,
                    milk_quantity: milkQuantity,
                },
            },
            config
        )
        .then((res) => {
            return res;
        });
};
