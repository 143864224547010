import React, { useContext } from "react";
import { Context } from "../../context/Store";
import { Navigate, useNavigate } from "react-router-dom";

const ServiceRoute = ({ urls, children }) => {
  const {
    state: { user_data },
  } = useContext(Context);

  const privileges = user_data.privileges;

  if (privileges[urls]) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default ServiceRoute;
