import React from "react";
import { no_data } from "../../../components/assets";
import { styled } from "styled-components";

function Nodata() {
  return (
    <Container>
      <img src={no_data} alt="no-data" />
    </Container>
  );
}

export default Nodata;

const Container = styled.div`
  width: 40%;
  margin: 0 auto;
  img {
    width: 100%;
    display: block;
  }
`;
