import React from "react";
import { comingsoon } from "../../../components/assets";
import { styled } from "styled-components";

function ComingSoon() {
  return (
    <ComingSoonDiv>
      <img src={comingsoon} alt="img" />
    </ComingSoonDiv>
  );
}

export default ComingSoon;

const ComingSoonDiv = styled.div`
  width: 50%;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
  }
`;
