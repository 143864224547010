import React from 'react'
import { styled } from 'styled-components';

function RectangleButton({ label }) {
    return (
        <Cover>
            <Label >{label}</Label>
        </Cover>
      )
}

export default RectangleButton;

const Label = styled.label``;

const Cover = styled.div`
    width: 160px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2382D4;
    border-radius: 4px;
    padding: 6px 12px;
    background: #fff;

    ${Label}{
        font-size: 14px;
        color: #2382D4;
        font-family:'inter_light'
    }
`;
