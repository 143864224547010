import React, { useContext, useEffect, useRef } from "react";
import { Sidebar } from "../../includes";
import { styled } from "styled-components";
import { Card, LineChartGraph } from "../../../components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  clr,
  curve,
  dropdown,
  fat,
  filter,
  milk,
  print,
  snf,
} from "../../../components/assets";
import BarChartGraph from "../../../components/chart/BarChartGrapfh";
import { Context } from "../../../context/Store";
import {
  getMilkStatus,
  plantQualityGraphData,
  plantQuantityGraphData,
  printMilkstatus,
} from "../../../api/auth";
import { useState } from "react";
import Loader from "../../../components/loader/Loader";

function MilkStatus() {
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "milk-status",
      },
    });
  }, []);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [statusData, setStatusData] = useState({});
  const [graphData, setGraphData] = useState({});
  const [qualityGraphData, setQualityGraphdata] = useState();
  const [isDropDown, setDropDown] = useState("");
  const [filterData, setFilterData] = useState("This Week");
  const [filteValues, setFilterValues] = useState("");
  const [qulaityFilter, setQualityFilter] = useState("This Week");
  const [formattedDate, setFormatedDate] = useState("");
  const [QualityfilterValues, setQulaityFilterValues] = useState("");
  const [filterItem, setFilter] = useState("all");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleFiter = (item) => {
    setFilterData(item.title);
    setDropDown(null);
  };
  const handleQualityFiter = (item) => {
    setQualityFilter(item.title);
    setDropDown(null);
  };
  const handleDropDown = (item) => {
    if (isDropDown) {
      setDropDown("");
    } else {
      setDropDown(item);
    }
  };
  const filter_keys = [
    {
      id: 1,
      title: "This Month",
      value: "30",
    },
    {
      id: 2,
      title: "This Week",
      value: "10",
    },
  ];
  const quantity = "quantity";
  const quality = "quality";
  const param = filterItem;

  useEffect(() => {
    getMilkStatus(config, param).then((res) => {
      setStatusData(res.app_data);
      setIsLoading(false);
    });
  }, [filterItem]);
  useEffect(() => {
    plantQuantityGraphData(config, filteValues).then((res) => {
      setGraphData(res.app_data.data);
    });
  }, [filteValues]);

  useEffect(() => {
    plantQualityGraphData(config, QualityfilterValues).then((res) => {
      setQualityGraphdata(res.app_data.data);
    });
  }, [QualityfilterValues]);

  const filter_tabs = [
    {
      id: 1,
      title: "All",
      value: "all",
    },
    {
      id: 1,
      title: "AM",
      value: "am",
    },
    {
      id: 1,
      title: "PM",
      value: "pm",
    },
  ];

  const handlePrintMilkStatus = () => {
    setIsButtonLoading(true);
    printMilkstatus(config).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        setIsButtonLoading(false);
      } else {
        setIsButtonLoading(false);
      }
    });
  };
  const elementRef = useRef(null);
  const captureScreenshot = () => {
    if (elementRef.current) {
      html2canvas(elementRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        convertToPDF(imgData);
      });
    }
  };

  const convertToPDF = (imgData) => {
    const pdf = new jsPDF();
    pdf.addImage(imgData, "PNG", 10, 10, 190, 150);
    pdf.save("Total milk status.pdf");
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Container>
      <TopBar>
        <Title>Milk status</Title>
        <FilterSection>
          <Date>{statusData?.current_date}</Date>
          <Time>
            {filter_tabs.map((item) => (
              <TimeTab
                key={item.value}
                onClick={() => setFilter(item.value)}
                className={filterItem === item.value && "active"}
              >
                {item.title}
              </TimeTab>
            ))}
          </Time>
          <Switch onClick={() => captureScreenshot()}>
            <Icon>
              <img src={print} alt="image" />
            </Icon>
            <SwitchTitle>Print</SwitchTitle>
          </Switch>
          {/* <Switch>
            <Icon>
              <img src={filter} alt="image" />
            </Icon>
            <SwitchTitle>Filter</SwitchTitle>
          </Switch> */}
        </FilterSection>
      </TopBar>
      <CoverBottom ref={elementRef}>
        <BottomBar>
          <CoverCard>
            <Card
              title="Total milk received"
              icon={milk}
              number={statusData?.total_milk_recieved}
              label="Litres"
              path={`/milk-status/detail`}
            />
            <Card
              title="Spoiled milk"
              icon={milk}
              number={
                statusData?.spoiled_milk == null ? 0 : statusData?.spoiled_milk
              }
              label="Litres"
            />
          </CoverCard>
          <TotalValue>
            <TotalCard>
              <Icon>
                <img src={clr} alt="image" />
              </Icon>
              <Span>Average CLR value</Span>
              <Count>{statusData?.avg_total_clr_value}</Count>
              <Curve>
                <img src={curve} alt="image" />
              </Curve>
            </TotalCard>
            <TotalCard>
              <Icon>
                <img src={fat} alt="image" />
              </Icon>
              <Span>Average FAT value</Span>
              <Count>{statusData?.avg_total_fat_value}</Count>
              <Curve>
                <img src={curve} alt="image" />
              </Curve>
            </TotalCard>
            <TotalCard>
              <Icon>
                <img src={snf} alt="image" />
              </Icon>
              <Span>Average SNF value</Span>
              <Count>{statusData?.avg_total_snf_value}</Count>
              <Curve>
                <img src={curve} alt="image" />
              </Curve>
            </TotalCard>
          </TotalValue>
        </BottomBar>
        <GraphContainer>
          <Cover>
            <TitleCover>
              <TitleGraph>Quantity Analysis</TitleGraph>
              <Drop onClick={() => handleDropDown(quantity)}>
                {filterData}
                <Icon>
                  <img src={dropdown} alt="icon" />
                </Icon>
                {isDropDown == "quantity" && (
                  <DropDown>
                    {filter_keys.map((item, index) => (
                      <Fil
                        key={item.value}
                        onClick={() => {
                          handleFiter(item);
                          setFilterValues(item.value);
                        }}
                      >
                        {item.title}
                      </Fil>
                    ))}
                  </DropDown>
                )}
              </Drop>
            </TitleCover>

            <LineChartGraph graphData={graphData} />
          </Cover>
          <Cover>
            <TitleCover>
              <TitleGraph>Quality Analysis</TitleGraph>
              <Drop onClick={() => handleDropDown(quality)}>
                {qulaityFilter}
                <Icon>
                  <img src={dropdown} alt="icon" />
                </Icon>
                {isDropDown == "quality" && (
                  <DropDown>
                    {filter_keys.map((item, index) => (
                      <Fil
                        key={item.value}
                        onClick={() => {
                          handleQualityFiter(item);
                          setQulaityFilterValues(item.value);
                        }}
                      >
                        {item.title}
                      </Fil>
                    ))}
                  </DropDown>
                )}
              </Drop>
            </TitleCover>
            <BarChartGraph qualityGraphData={qualityGraphData} />
          </Cover>
        </GraphContainer>
      </CoverBottom>
    </Container>
  );
}

export default MilkStatus;

const CoverBottom = styled.div``;
const Container = styled.div`
  padding: 20px;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
`;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DropDown = styled.div`
  background-color: #e0f3ff;
  border-radius: 5px;
  position: absolute;
  width: 120px;
  top: 30px;
`;
const Fil = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid #c4c4c4;
  cursor: pointer;
  font-size: 13px;
  &:last-child {
    border-bottom: 1px solid transparent;
  }
  transition: 0.4s ease;
  &:hover {
    background-color: #2382d4;
    color: #fff;
    transition: 0.4s ease;
  }
`;
const Title = styled.h2`
  color: #0a0a0a;
  /* margin-bottom: 20px; */
  font-size: 26px;
`;
const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Date = styled.div`
  color: #747474;
  font-family: "inter_regular";
  margin-right: 20px;
`;
const Time = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TimeTab = styled.div`
  color: #747474;
  cursor: pointer;
  border: 1px solid #7fadd4;
  padding: 6px 16px;
  margin-right: 3px;
  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
    margin-right: 20px;
  }
  &.active {
    background: #2382d4;
    color: #fff;
  }
`;
const Switch = styled.div`
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  border: 1px solid #7fadd4;
  margin-right: 10px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`;
const SwitchTitle = styled.div`
  color: #2382d4;
`;
const BottomBar = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 20px;
  @media (min-width: 640px)and (max-width:1080px) {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:20px;
  }
`;
const CoverCard = styled.div`
  display: flex;
  width: 70%;
`;
const TotalValue = styled.div`
  width: 30%;
`;
const TotalCard = styled.div`
  display: flex;
  border: 1px solid #dfe8ed;
  z-index: 1;
  border-radius: 8px;
  padding: 10px 20px;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  margin-left: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const Icon = styled.div`
  img {
    display: block;
    width: 100%;
  }
`;
const Span = styled.div`
  color: #0a0a0a;
`;
const Curve = styled.div`
  position: absolute;
  width: 20%;
  right: 0;
  z-index: -1;
  bottom: 0;
  img {
    display: block;
    width: 100%;
  }
`;
const Count = styled.h1`
  color: #2382d4;
`;
const GraphContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
`;
const Cover = styled.div`
  background-color: #fff;
  margin-right: 20px;
  padding: 20px;
  width: 49%;
  &:last-child {
    margin-right: 0;
  }
`;
const TitleCover = styled.h3`
  color: #0a0a0a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
`;
const TitleGraph = styled.div``;
const Drop = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  cursor: pointer;
  color: #0a0a0a;
  font-family: "inter_regular";
  font-size: 14px;
`;
