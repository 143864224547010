import React, { useRef, useState } from "react";
import styled from "styled-components";
import ButtonLoader from "../../../../components/button-loader/ButtonLoader";
import api from "../../../../api";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { getDDMMYY } from "../../../../utils/functions/function";

const RenewModal = ({ isOpen, onClose, renewalId, itemData }) => {
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [data, setData] = useState(itemData);
    const [isEmiEnabled, setIsEmiEnabled] = useState(itemData?.is_emi);


    const handleEmiToggle = (e) => {
        setIsEmiEnabled(!isEmiEnabled);
        setData((prev) => ({
            ...prev,
            [e.target.name]: e.target.checked,
        }));
    };

    const handleWrapperClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
    const showSuccessModal = () => {
        Swal.fire({
            position: "center",
            icon: "success",
            title: "Insurance Created Successfully",
            showConfirmButton: false,
            timer: 1500,
        });
    };

    const handleChange = (e) => {
        setData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };
    const formRef = useRef(null);

    const handleClick = (e) => {
        const formData = new FormData(formRef.current);
        // formData.append("is_emi", formRef.current.is_emi.value == "on" ? true : false);
        formData.set(
            "is_emi",
            formRef.current.is_emi.value == "on" ? "True" : "False"
        );
        e.preventDefault();
        api.post(
            `activities/renew-plant-insurance/${renewalId}/`,
            formData,
            {}
        ).then((res) => {
            if (res.app_data.StatusCode == 6000) {
                showSuccessModal();
                setIsButtonLoading(false);
                onClose();

                formRef.current.market_value.value = "";
                formRef.current.premium_amount.value = "";
                formRef.current.claim_amount.value = "";
                formRef.current.expiry_date_of_insurance.value = "";
                formRef.current.no_of_emi.value = "";
            } else {
                setIsButtonLoading(false);
            }
        });
    };
    return (
        <ModalWrapper isOpen={isOpen} onClick={handleWrapperClick}>
            <ModalContent ref={formRef} onSubmit={(e) => handleClick(e)}>
                <h2>Renewal</h2>
                <ContentContainer>
                    <CoverButton>
                        <CoverinButton>
                            <Label> Market Value*</Label>
                            <Input
                                type="number"
                                placeholder="Amount"
                                name="market_value"
                                required
                                value={data?.market_value}
                                onChange={handleChange}
                            />
                        </CoverinButton>
                    </CoverButton>
                    <CoverButton>
                        <CoverinButton>
                            <Label> Premium Amount*</Label>
                            <Input
                                type="number"
                                placeholder="Amount"
                                name="premium_amount"
                                required
                                value={data?.premium_amount}
                                onChange={handleChange}
                            />
                        </CoverinButton>
                    </CoverButton>
                    <CoverButton>
                        <CoverinButton>
                            <Label> Claim Amount*</Label>
                            <Input
                                type="number"
                                placeholder="Amount"
                                name="claim_amount"
                                required
                                value={data?.claim_amount}
                                onChange={handleChange}
                            />
                        </CoverinButton>
                    </CoverButton>
                    <CoverinButton>
                        <Label>Expiry Date*</Label>
                        <Input type="date" name="expiry_date_of_insurance" 
                                required
                                value={data?.expiry_date_of_insurance}
                                onChange={handleChange}
                                />
                        {/* <DatePicker
                            selected={getDDMMYY(data?.expiry_date_of_insurance)}
                            onChange={handleChange}
                            required
                            dateFormat="dd/MM/yyyy"
                            // customInput={<CustomInput />}
                            // style={customStyles}
                            name="expiry_date_of_insurance"
                        /> */}
                    </CoverinButton>
                    <CoverButtonEmi>
                        <Emi>
                            <h6>Enable EMI Option</h6>
                            <ToggleSwitchWrapper>
                                <HiddenCheckbox
                                    name="is_emi"
                                    checked={isEmiEnabled}
                                    onChange={handleEmiToggle}
                                />
                                <Switch />
                            </ToggleSwitchWrapper>
                        </Emi>
                    </CoverButtonEmi>
                    <EmiEnabled enabled={isEmiEnabled}>
                        <InputLabelemi>No of EMI</InputLabelemi>
                        <Input
                            type="text"
                            name="no_of_emi"
                            value={data?.no_of_emi}
                            required={isEmiEnabled}
                            onChange={handleChange}
                        />
                    </EmiEnabled>
                </ContentContainer>
                <Buttons>
                    {isButtonLoading ? (
                        <Button>
                            <ButtonLoader />
                        </Button>
                    ) : (
                        <SubmitButton type="submit" value="Submit" />
                    )}
                </Buttons>
            </ModalContent>
        </ModalWrapper>
    );
};

export default RenewModal;
const ModalWrapper = styled.div`
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`;

const ModalContent = styled.form`
    position: absolute;
    top: 50%;
    left: 56%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 42px;
    border-radius: 15px;
    width: 681px;
    overflow-y: auto;
    padding: 40px 40px 20px;

    h2 {
        color: #0a0a0a;
        font-family: "inter_regular";
        /* margin-bottom: 30px; */
    }

    button {
        display: flex;
        width: 160px;
        padding: 6px 20px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        border-radius: 4px;
        background: #2382d4;
    }
`;

const ContentContainer = styled.div`
    height: calc(100vh - 200px);
    overflow: scroll;
    border: 1px solid #eee;
    border-radius: 8px;
    margin: 20px 0;
    padding: 20px;
`;
const CoverButton = styled.div``;
const CoverinButton = styled.div`
    width: 92%;
    margin-right: 20px;
    margin-bottom: 20px;
    &:nth-child(even) {
        margin-right: 0;
    }
    position: relative;
    &.add {
        width: 48%;
        margin-right: 0;
    }
`;
const Label = styled.h3`
    color: #747474;
    font-family: "inter_regular";
    margin-bottom: 10px;
`;
const Input = styled.input`
    background-color: #f4f5f8;
    border: 1px solid #dfe8ed;
    font-size: 18px;
    padding-left: 20px;
    border-radius: 4px;
    width: 108%;
    height: 66px;
    padding: 18px 23px;
    align-items: center;
    gap: 10px;
    &.auto {
        color: #adadad;
    }
`;
const Button = styled.div`
    width: 170px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2382d4;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
    &.cancel {
        background-color: #fff;
        border: 1px solid #2382d4;
        color: #2382d4;
        margin-right: 20px;
    }
`;
const SubmitButton = styled.input`
    width: 170px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2382d4;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.cancel {
        background-color: #fff;
        border: 1px solid #2382d4;
        color: #2382d4;
        margin-right: 20px;
    }
`;

const CoverButtonEmi = styled.div`
    display: flex;
    align-items: center; /* Align items vertically */
    gap: 168px;
    margin-top: 36px;
`;

const Buttons = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;

const EmiEnabled = styled.div`
    display: ${({ enabled }) => (enabled ? "flex" : "none")};
    align-items: center;
    margin: 30px 0; /* Adjust as needed */
    gap: 30px;
`;

const InputLabelemi = styled.h3`
    color: #747474;
    font-family: "inter_regular";
    margin-bottom: 10px;
    white-space: nowrap;
`;

const Emi = styled.div`
    display: flex;
    align-items: center;
    h6 {
        /* margin-bottom: 50px; */
        color: #747474;
        font-family: "inter_regular";
        font-size: 18px;
        margin-right: 16px;
    }
`;

const ToggleSwitchWrapper = styled.label`
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    display: none;
`;

const Switch = styled.span`
    position: absolute;
    cursor: pointer;
    background-color: #fff;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
    border: 1px solid #000;

    &:before {
        content: "";
        position: absolute;
        left: 1px;
        top: 1px;
        width: 21px;
        height: 21px;
        background-color: #000;
        border-radius: 50%;
        transition: transform 0.3s ease;
    }

    ${HiddenCheckbox}:checked + &::before {
        transform: translateX(25px);
        background-color: #000;
    }

    ${HiddenCheckbox}:checked + & {
        background-color: #fff;
    }
`;
