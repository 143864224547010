import React, { forwardRef, useRef } from "react";
import ReactToPrint from "react-to-print";
import "./TableStyles.css"; // Assume this file contains your styles
import { useLocation } from "react-router-dom";

const TableComponent = forwardRef(({ data }, ref) => {
  console.log("Table data:", data);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const startDate = queryParams.get("start_date");
  const endDate = queryParams.get("end_date");
  let counter = 1;
  return (
    <div ref={ref}>
      <div className="top-container">
        <div>
          <h4 className="">
            From : {startDate ? new Date(startDate).toLocaleDateString('en-GB') : new Date().toLocaleDateString('en-GB')}
          </h4>
          <h4>To : {endDate ? new Date(endDate).toLocaleDateString('en-GB') : new Date().toLocaleDateString('en-GB')}</h4>
        </div>
        <div className="center">
          <h1 className="heading">Jeevodaya Milk Project</h1>
          <h3>Procurement Cum Quality Register</h3>
        </div>
        <div>
          <h4>Office Phone : 9495220835</h4>
          <h4>Plant Phone : 9544678244</h4>
        </div>
      </div>
      <table className="custom-table">
        <thead>
          <tr>
            <th>Sl No.</th>
            <th>Society</th>
            <th>Date</th>
            <th>Time</th>
            <th>Can No</th>
            <th>Qty/kg</th>
            <th>CLR</th>
            <th>FAT</th>
            <th>SNF</th>
            <th>Bonus</th>
            <th>Net Rate</th>
            <th>Qty/Ltr</th>
            <th>Amount</th>
            <th>Damage</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((route, routeIndex) => (
            <React.Fragment key={routeIndex}>
              <tr className="route-row">
                <td colSpan="14">Route: {route.route}</td>
              </tr>
              {route?.data?.map((item,index) => (
                <tr key={item.id}>
                  <td>{counter++}</td>
                  <td>{item.society}</td>
                  <td>{item.date}</td>
                  <td>{item.time.toUpperCase()}</td>
                  <td>{item.can_no}</td>
                  <td>{item.quantity_in_kg}</td>
                  <td>{item.clr}</td>
                  <td>{item.fat}</td>
                  <td>{item.snf}</td>
                  <td>{item.bonus}</td>
                  <td>{item.net_rate}</td>
                  <td>{item.quantity_in_l}</td>
                  <td>{item.amount}</td>
                  <td>{item.damaged}</td>
                </tr>
              ))}
              <tr key={routeIndex}>
                  <td></td>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>{route.total_can_no}</td>
                  <td>{route.total_milk_kg}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{route.total_milk_in_litre}</td>
                  <td>{route.total_amount}</td>
                  <td>{route.total_damaged}</td>
                </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
});

const PrintTable = ({ data }) => {
  const tableRef = useRef();
  console.log("PrintTable data:", data);

  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <div className="button-container">
            <button>Print this table</button>
          </div>
        )}
        content={() => tableRef.current}
        pageStyle="@media print { table { width: 100%; border-collapse: collapse; } th, td { padding: 8px; text-align: left; border: 1px solid #ddd; } }"
      />
      {data && <TableComponent ref={tableRef} data={data} />}
    </div>
  );
};

export default PrintTable;
