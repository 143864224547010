import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import List from "../../../../components/List";
import { add, filterIcon } from "../../../../components/assets";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../../context/Store";
import { GetIncentives } from "../../../../api/auth";
import Nodata from "../../../includes/nodata/Nodata";
import SectionLoader from "../../../../components/sectionloader/SectionLoader";
import { getIncentivesData } from "../../../../api/society";
import Loader from "../../../../components/loader/Loader";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import api from "../../../../api";
import Swal from "sweetalert2";
import { getYYMMDD } from "../../../../utils/functions/function";

function Incentives() {
    const [getIncentives, setGetIncentives] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEnddate] = useState("");
    const CustomInput = ({ value, onClick }) => (
        <input
            type="text"
            value={value}
            onClick={onClick}
            style={{ width: "100px", height: "30px" }}
        />
    );
    const customStyles = {
        width: "100px",
        height: "30px",
        borderRadis: "8px",
        // Add any other custom styles here if needed
    };
    const navigate = useNavigate();
    // get accesstoken from store
    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);
    const token = user_data.access_token;
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    useEffect(() => {
        dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
                active_menu: "incentives",
                active_sub_menu: "",
            },
        });
    }, []);

    const handleIncentiveData = () => {
        api.get("activities/get-farmer-incentive/", {
            headers: {
                Authorization: "Bearer " + token,
            },
            params: {
                from_date: getYYMMDD(startDate),
                to_date: getYYMMDD(endDate),
            },
        }).then((res) => {
            if (res.app_data.StatusCode == 6000) {
                setGetIncentives(res.app_data.data);
            }
        });
    };
    useEffect(() => {
        handleIncentiveData();
        setTimeout(() => setIsLoading(false), 1100);
    }, [endDate]);

    const getIncentiveId = (row) => {
        dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
                incentive_id: row.id,
            },
        });
    };
    const confirmDelete = (e, item) => {
        // setRender(true);
        Swal.fire({
            title: "Are you sure to proceed?",
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                handleDelete(e, item);
            }
        });
    };
    const handleDelete = (e, item) => {
        e.preventDefault();

        api.post(`activities/delete-farmer-incentives/${item.id}/`, {}).then(
            (res) => {
                if (res.app_data.StatusCode == 6000) {
                    // setRender(false);
                    handleIncentiveData();
                } else {
                }
            }
        );
    };
    return (
        <Container>
            <TopBox>
                <Title>Incentives</Title>
                <NavButtons>
                    <TopRight>
                        <ButContainer>From</ButContainer>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            required
                            customInput={<CustomInput />}
                            style={customStyles}
                        />
                        <ButContainer>To</ButContainer>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEnddate(date)}
                            required
                            minDate={startDate}
                            customInput={<CustomInput />}
                            style={customStyles}
                        />
                    </TopRight>
                    <Button onClick={() => navigate("/add-incentive")}>
                        <img src={add} />
                        Create incentive
                    </Button>
                </NavButtons>
            </TopBox>
            <div className="parent-scroll">
                <ListContainer className="scroll">
                    <HeadRow>
                        <HeadColumnData>Sl. no</HeadColumnData>
                        <HeadColumnData>Particulars</HeadColumnData>
                        <HeadColumnData>From</HeadColumnData>
                        <HeadColumnData>To</HeadColumnData>
                        <HeadColumnData>Rates</HeadColumnData>
                        <HeadColumnData>Total expense</HeadColumnData>
                        <HeadColumnData>Action</HeadColumnData>
                    </HeadRow>
                    <BottomContainer>
                        {isLoading ? (
                            <Loader />
                        ) : getIncentives?.length == 0 ? (
                            <Nodata />
                        ) : (
                            getIncentives?.map((row, index) => (
                                <Row
                                    key={index}
                                    onClick={() => getIncentiveId(row)}
                                >
                                    <ColumnData className="bold">
                                        {index + 1}
                                    </ColumnData>
                                    <ColumnData className="bold color">
                                        <span
                                            onClick={() =>
                                                navigate("/view-edit-incentive")
                                            }
                                        >
                                            {row.incentive_description}
                                        </span>
                                    </ColumnData>
                                    <ColumnData>{row.start_date}</ColumnData>
                                    <ColumnData>{row.end_date}</ColumnData>
                                    <ColumnData>
                                        {" "}
                                        ₹{row.incentive_amount} per litre
                                    </ColumnData>
                                    <ColumnData className="bold">
                                        ₹{row.total_expense}{" "}
                                    </ColumnData>
                                    <ColumnData className="bold">
                                        <DeleteIcon
                                            onClick={(e) => {
                                                confirmDelete(e, row);
                                            }}
                                        >
                                            <i class="ri-delete-bin-6-line"></i>
                                        </DeleteIcon>
                                    </ColumnData>
                                </Row>
                            ))
                        )}
                    </BottomContainer>
                </ListContainer>
            </div>

            {/* <List 
                rowTitle={rowTitle}
                rowValue={rowValue}
            /> */}
        </Container>
    );
}

export default Incentives;

const Container = styled.div`
    padding: 20px;
`;
const TopRight = styled.div`
    display: flex;
    gap: 5px;
    display: flex;
`;
const ButContainer = styled.div`
    padding: 5px 8px;
    background: #2382d4;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
`;
const TopBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @media (max-width: 1080px) {
        flex-direction: column;
    }
`;
const Title = styled.h2`
    color: #0a0a0a;
    /* margin-bottom: 20px; */
    font-size: 26px;
    @media (max-width: 1080px) {
        margin-bottom: 20px;
    }
`;

const NavButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 50px;
    flex-wrap: wrap;
`;
const Button = styled.div`
    /* width:48px;
    height:26px; */
    background: #fff;
    color: #2382d4;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 1px solid #2382d4;
    border-radius: 4px;
    font-size: 14px;
    padding: 6px 16px;
    cursor: pointer;

    &.background {
        background-color: #2382d4;
        color: #fff;
        margin-left: 15px;
    }

    img {
        margin-right: 10px;
    }
`;

const BottomContainer = styled.div`
    max-height: calc(100vh - 250px);
    overflow-y: scroll;
`;

const ListContainer = styled.div`
    background-color: #fff;
    /* padding: 0 24px; */
    border: 1px solid #dfe8ed;
    border-radius: 8px;

    h3 {
        color: #747474;
        font-family: "inter_regular";
        font-size: 22px;
    }
`;

const HeadRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 20px;
    border-bottom: 1px solid #ccc;
`;
const Row = styled.div`
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    padding: 30px 20px;
    border-bottom: 1px solid #ccc;
`;

const HeadColumnData = styled.div`
    color: #747474;
    font-size: 18px;
    font-family: "inter_light";
    width: 15%;
    &:first-child {
        width: 7%;
    }
    &:last-child {
        text-align: right;
        width: 7%;
    }
`;
const ColumnData = styled.div`
    color: #0a0a0a;
    font-size: 18px;
    font-family: "inter_light";
    width: 15%;
    &.bold {
        font-family: "inter_regular";
    }
    &.color {
      span{
        color: #2382d4;
        cursor: pointer;
      }
    }
    &:first-child {
        width: 7%;
    }
    &:last-child {
        text-align: right;
        width: 7%;
    }
`;
const DeleteIcon = styled.div`
    color: #ff0000;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
    margin-right: 10px;
    border-radius: 5px;
    height: 29px;
`;
