import React, { useState } from "react";
import { styled } from "styled-components";
import { Route, Routes } from "react-router-dom";
import SocietyStatics from "../society-statics/SocietyStatics";
import { Header, Notifications, Sidebar } from "../../includes";
import MilkReceived from "../milk-received";
import SocietyLocalSales from "../local-sales";
import AddLocalSale from "../creations/AddLocalSale";
import SocietyMilkStatus from "../milk-status";
import SocietyFarmerList from "../farmer";
import SocietyFarmerSingle from "../farmer/farmer-single";
import FarmerPaymentList from "../farmer/farmer-payment";
import AddBonus from "../Payments/Bonus/AddBonus";
import Bonus from "../Payments/Bonus/index";
import AddIncentive from "../Payments/Incentives/AddIncentive";
import { Incentives, Loans } from "../Payments";
import AddLoan from "../Payments/Loans/AddLoan";
import ViewLoan from "../Payments/Loans/ViewLoan";
import CanDetails from "../canDetails";
import CreateFarmer from "../../plant/creation/CreateFarmer";
import MilkStatusDetail from "../milk-status/milk-status-detail/MilkStatusDetail";
import ViewEditSocBonus from "../Payments/Bonus/ViewEditSocBonus";
import ViewEditSocIncentive from "../Payments/Incentives/ViewEditSocIntentive";
import MilkPriceSheet from "../pricesheet/MilkPriceSheet";
import MilkStatusDetailFarmer from "../milk-status/milk-status-detail/MilkStatusDetailFarmer";
import ArrivalsList from "../arrivals/ArrivalsList";
import AddArrivals from "../milk-received";
import ProcessedPayment from "../Payments/Reports/ProcessedPayment";
import CompletedPayments from "../Payments/Reports/CompletedPayments";
import PaymentHistory from "../Payments/Reports/PaymentHistory";
import PaymentReport from "../Payments/Reports/PaymentReport";
import BankDetails from "../Payments/Reports/BankDetails";
import MilkReport from "../milk-report/MilkReport";
import MilkView from "../milk-report/MilkView";
import Insurance from "../insurance/Insurance";
import Payment from "../insurance/Payment";
import Cows from "../farmer/farmer-single/Cows";
import Notification from "../notification/Notification";
import EditArrivals from "../arrivals/EditArrivals";
import CurrentLoan from "../current-loan/CurrentLoan";
import ViewLoanDetails from "../Payments/Loans/ViewLoanDetails";
import MilkStatusSociety from "../milk-status/society-milk-status";

const SocietyDashboard = () => {
  const [isHovered, setHovered] = useState(false);

  return (
    <Container>
      <Left
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={isHovered && "active"}
      >
        <Hamburger onClick={() => setHovered(!isHovered)}>
          <i class="ri-menu-line"></i>
        </Hamburger>
        <Sidebar isHovered={isHovered} />
      </Left>
      <Header />
      <Right>
        <Routes>
          <Route path="/" element={<SocietyStatics />} />
          <Route path="/add-arrivals" element={<AddArrivals />} />
          <Route path="/local-sales" element={<SocietyLocalSales />} />
          <Route path="/add-local-sale" element={<AddLocalSale />} />
          {/* <Route path="/milk-status" element={<SocietyMilkStatus />} /> */}
          <Route path="/milk-status" element={<MilkStatusSociety />} />
          <Route path="/farmers" element={<SocietyFarmerList />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/farmers/:id" element={<SocietyFarmerSingle />} />
          <Route
            path="/farmers/:id/detail"
            element={<MilkStatusDetailFarmer />}
          />
          <Route path="/can-details" element={<CanDetails />} />
          <Route
            path="/farmers/:id/paymentlist"
            element={<FarmerPaymentList />}
          />
          <Route path="/create-farmer" element={<CreateFarmer />} />

          <Route path="/add-bonus" element={<AddBonus />} />
          <Route path="/current-loan" element={<CurrentLoan />} />
          <Route path="/milk-status/detail" element={<MilkStatusDetail />} />
          <Route path="/bonus" element={<Bonus />} />
          <Route path="/view-edit-bonus" element={<ViewEditSocBonus />} />
          <Route path="/add-incentive" element={<AddIncentive />} />
          <Route path="/incentives" element={<Incentives />} />
          <Route path="/sheet" element={<MilkPriceSheet />} />
          <Route path="/arrivals" element={<ArrivalsList />} />

          <Route path="/processed-payments" element={<ProcessedPayment />} />
          <Route
            path="/completed-payment-report"
            element={<CompletedPayments />}
          />
          <Route path="/payment-history" element={<PaymentHistory />} />
          <Route path="/payment-reports" element={<PaymentReport />} />
          <Route path="/bank-details" element={<BankDetails />} />
          <Route path="/milk-report" element={<MilkReport />} />
          <Route path="/milk-view" element={<MilkView />} />

          <Route
            path="/view-edit-incentive"
            element={<ViewEditSocIncentive />}
          />
          <Route path="/add-loan" element={<AddLoan />} />
          <Route path="/edit-arrivals/:id" element={<EditArrivals />} />
          {/* <Route path="/view-loan" element={<ViewLoan />} /> */}
          <Route path="/view-loan/:id" element={<ViewLoanDetails />} />
          <Route path="/loans" element={<Loans />} />
          <Route path="/insurance" element={<Insurance />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/cows/:id" element={<Cows />} />
          <Route path="/notification" element={<Notification />} />
        </Routes>
      </Right>
    </Container>
  );
};

export default SocietyDashboard;

const Container = styled.div`
  height: 100vh;
  background: #f4f5f8;
  display: flex;
`;
const Left = styled.div`
  width: 5%;
  height: 100%;
  position: relative;
  z-index: 100;
  transition: 0.4s ease;
  &.active {
    width: 16%;
    transition: 0.4s ease;
  }
  @media (max-width: 1080px) {
    width: 6%;
    &.active {
      width: 22%;
    }
  }
  @media (max-width: 768px) {
    width: 9%;
    &.active {
      width: 24%;
    }
  }
  @media (max-width: 640px) {
    width: 12%;
    &.active {
      width: 32%;
    }
  }
`;
const Right = styled.div`
  width: 100%;
  max-height: 100vh;
  overflow-x: hidden;
  margin-top: 80px;
  overflow-y: scroll;
  /* @media (max-width: 1080px) {
    min-width: 1280px;
    overflow-x: scroll;
  } */
`;

const Hamburger = styled.div`
  position: absolute;
  color: #585656;
  cursor: pointer;
  right: -32px;
  top: 20px;
  font-size: 28px;
`;