import React from "react";

// packages
import styled from "styled-components";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";

export default function BarChartGraph({ qualityGraphData }) {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

  const options = {
    plugins: {
      drawBorder: false,
      legend: {
        // display: false,
        display: true, // Set to true to display the legend
        labels: {
          usePointStyle: true, // Optional: Use point style in legend labels
        },
      },
      title: {
        display: false,
        text: "Average SNF, CLR, FAT",
      },
    },
    layout: {
      padding: 20,
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
  };
  const data = {
    labels: qualityGraphData?.label_data,
    datasets: [
      {
        label: "Average SNF",
        data: qualityGraphData?.snf_data,
        backgroundColor: ["#007ADC"],
        borderColor: ["#007ADC"],
        borderWidth: 1,
        barThickness: 30,
        pointStyle: "circle",
      },
      {
        label: "Average CLR",
        data: qualityGraphData?.clr_data,
        backgroundColor: ["#b7ebe2"],
        borderColor: ["#94d3ca"],
        borderWidth: 1,
        barThickness: 30,
        pointStyle: "circle",
      },
      {
        label: "Average FAT",
        data: qualityGraphData?.fat_data,
        backgroundColor: ["#d9a043"],
        borderColor: ["#d3d094"],
        borderWidth: 1,
        barThickness: 30,
        pointStyle: "circle",
      },
    ],
  };
  return (
    <GraphContainer>
      <Bar data={data} options={options} width={"100%"} height={"50px"} />
    </GraphContainer>
  );
}
const GraphContainer = styled.div`
  /* width: 750px; */
  /* canvas {
    height: 380px !important;
    margin: 0 auto;
  } */
`;
