import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import { Context } from "../../../context/Store";
import api from "../../../api";
const NewTagModal = ({ isOpen, onClose, cowId }) => {
  const [getData, setData] = useState([]);
  const modalRef = useRef(null);

  // get accesstoken from store
  const {
    state: { user_data, route_data },
  } = useContext(Context);
  const token = user_data.access_token;
  //tag history
  useEffect(() => {
    cowId &&
      api
        .get(`activities/tag-history/?cow=${cowId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.app_data.StatusCode === 6000) {
            setData(res.app_data.data);
          } else {
            setData([]);
          }
        });
  }, [cowId]);
  const renderTableContent = () => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Year</th>
            <th>Tag No</th>
          </tr>
        </thead>
        <tbody>
          {getData.map((row, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{row.is_latest ? "Current" : row.year}</td>
              <td>{row.tag_no}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <ModalWrapper isOpen={isOpen}>
      <ModalContent ref={modalRef}>
        <Close onClick={() => onClose(false)}>
          <i class="ri-close-line"></i>
        </Close>
        <h2>Tag No History</h2>
        {renderTableContent()}
      </ModalContent>
    </ModalWrapper>
  );
};

export default NewTagModal;
const ModalWrapper = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.form`
  position: absolute;
  top: 56%;
  left: 56%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 42px;
  border-radius: 15px;
  width: 40%;
  padding: 40px;
  @media (max-width: 1080px) {
    width: 80%;
  }
  h2 {
    color: #0a0a0a;
    font-family: "inter_regular";
    margin-bottom: 30px;
  }
  button {
    display: flex;
    width: 160px;
    padding: 6px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    align-self: stretch;
    border-radius: 4px;
    background: #2382d4;
  }
`;

const Close = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  color: grey;
  cursor: pointer;
`;
const Table = styled.table`
  border-collapse: collapse;
  width:100%; /* Set your desired width */
  height: 250px; /* Set your desired height */
  flex-shrink: 0;
  margin-bottom: 20px;
  color: #747474;
  font-family: "inter_regular";

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }
  @media (max-width: 1080px) {
    width: 520px;
  }
`;
