import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { action, curve, left_arrow, plus } from "../../../components/assets";
import { farmer_list, routs_societies } from "../../../utils/arrays";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../context/Store";
import CreateFarmer from "../creation/CreateFarmer";
import { getAllFarmers } from "../../../api/auth";
import Nodata from "../../includes/nodata/Nodata";
import Loader from "../../../components/loader/Loader";
import ReactPaginate from "react-paginate";

import { SearchInput } from "../../../components";

function PlantFarmers() {
  const [getFarmers, setGetFarmers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedItem, setSearchedItem] = useState("");

  const startIndex = (currentPage - 1) * 8;

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const [isAdd, setAdd] = useState(false);

  // get accesstoken from store
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "farmers",
      },
    });
  }, []);
  const params = "";
  useEffect(() => {
    getAllFarmers(config, params, currentPage, searchedItem)
      .then((res) => {
        if (res.app_data.StatusCode == 6000) {
          // setBonuSData(res.app_data.data);
          setGetFarmers(res.app_data.data);
          setIsLoading(false);
          setPagination(res.app_data.pagination_data);
        } else {
          setGetFarmers([]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [isAdd, currentPage, searchedItem]);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }
  return (
    <MainContainer>
      {isAdd ? (
        <>
          <CreateFarmer setAdd={setAdd} />
        </>
      ) : (
        <>
          <TopBar>
            <TopCover>
              <Text>
                <TopTitle>Farmers</TopTitle>
              </Text>
            </TopCover>
            <AddCover>
              <SearchInput state={searchedItem} setState={setSearchedItem} />
              <Add onClick={() => setAdd(true)}>
                <Icon>
                  <img src={plus} alt="icon" />
                </Icon>
                <span>Add New Farmers</span>
              </Add>
            </AddCover>
          </TopBar>
          <div className="parent-scroll">
            <SocietyList className="scroll">
              <ListLabels>
                <LabelName type="all">Sl No</LabelName>
                <LabelName type="id">Farmer ID</LabelName>
                <LabelName type="soc">Farmer name</LabelName>
                <LabelName type="soc">Society name </LabelName>
                <LabelName type="loc">Address</LabelName>
                <LabelName type="no">Society</LabelName>
                <LabelName type="milk">Average milk </LabelName>
              </ListLabels>
              {isLoading ? (
                <Loader />
              ) : getFarmers.length == 0 ? (
                <Nodata />
              ) : (
                getFarmers?.map((item, index) => (
                  <List key={index} to={`/plant-farmers/${item.id}`}>
                    <Item type="all">
                      <Serial>{startIndex + index + 1}</Serial>
                    </Item>
                    <Item type="id">
                      <Id>{item.farmer_id}</Id>
                    </Item>
                    <Item type="soc">
                      <CoverItem>
                        <Iconz>{item.name.charAt(0)}</Iconz>
                        <CoverName>
                          <Name>{item.name}</Name>
                          <Phone>{item.phone}</Phone>
                        </CoverName>
                      </CoverItem>
                    </Item>
                    <Item type="soc">
                      <span>{item.society}</span>
                    </Item>
                    <Item type="loc">
                      <span>
                        {item.address == "undefined" ? "--" : item.address}
                      </span>
                    </Item>
                    <CoverName>
                      <Item type="no">
                        <Span>{item.society}</Span>
                      </Item>
                      <Item type="milk">
                        <Span className="id">{item.society_id}</Span>
                      </Item>
                    </CoverName>
                    <Item type="milk">
                      <span className="avg">
                        {item.average_milk ? item.average_milk : 0}
                      </span>
                      liters
                    </Item>
                  </List>
                ))
              )}
            </SocietyList>
          </div>
        </>
      )}
      {!isAdd &&
        pagination &&
        pagination.total_pages > 1 &&
        getFarmers.length > 0 && (
          <PaginationContainer>
            <PaginationText>
              Showing Farmers : {pagination.first_item} - {pagination.last_item}{" "}
              of {pagination.total_items} Farmers
            </PaginationText>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pagination.total_pages}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          </PaginationContainer>
        )}
    </MainContainer>
  );
}

export default PlantFarmers;
const MainContainer = styled.div`
  padding: 20px;
  max-height: calc(100vh - 100px);
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const TopBar = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap:15px;
`;
const TopCover = styled.div`
  display: flex;
  margin-right: 30px;
`;
const Arrow = styled.div`
  border: 1px solid #2382d4;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
const Text = styled.div``;
const AddCover = styled.div`
  display: flex;
`;
const TopTitle = styled.h2`
  margin-bottom: 5px;
  &.create {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10px;
    color: #0a0a0a;
  }
`;
const RoutePath = styled.h4`
  color: #747474;
`;
const Curve = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  img {
    width: 100%;
    display: block;
  }
`;
const Add = styled.div`
  background-color: #fff;
  border: 1px solid #2382d4;
  color: #2382d4;
  /* width: 120px; */
  margin-left: 10px;
  height: 35px;
  display: flex;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  span{
    white-space:nowrap;
    @media (max-width: 1080px) {
     font-size:13px;
    }
  }
`;
const Icon = styled.div`
  margin-right: 10px;
  img {
    display: block;
  }
`;

const SocietyList = styled.div`
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 5px;
  max-height: calc(100vh - 240px);
  overflow: scroll;
`;
const Tab = styled.div`
  border-top: 1px solid #dfe8ed;
  border-left: 1px solid #dfe8ed;
  border-right: 1px solid #dfe8ed;
  border-bottom: 3px solid transparent;
  padding: 14px 20px;
  cursor: pointer;
  margin-right: 30px;
  color: #797d82;
  border-radius: 5px 5px 0 0;
  &.active {
    color: #2382d4;
    background-color: #e0f3ff;
    border-top: 1px solid #2382d4;
    border-left: 1px solid #2382d4;
    border-right: 1px solid #2382d4;
    border-bottom: 3px solid #2382d4;
  }
`;
const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Item = styled.div`
  /* width: 20%; */
  width: 140px;
  font-size: 16px;
  color: #2e3032;
  span {
    color: #2e3032;
    &.avg {
      color: #2382d4;
      margin-right: 5px;
    }
  }
  width: ${({ type }) =>
    type === "all"
      ? "45px"
      : type === "id"
      ? "90px"
      : type === "soc"
      ? "170px"
      : type === "loc"
      ? "200px"
      : type === "no"
      ? "150px"
      : type === "milk"
      ? "110px"
      : type === "action"
      ? "90px"
      : ""};
`;
const LabelName = styled.h4`
  color: #797d82;
  font-family: "inter_regular";
  /* width: 20%; */
  width: ${({ type }) =>
    type === "all"
      ? "45px"
      : type === "id"
      ? "90px"
      : type === "soc"
      ? "170px"
      : type === "loc"
      ? "200px"
      : type === "no"
      ? "150px"
      : type === "milk"
      ? "110px"
      : type === "action"
      ? "90px"
      : ""};
`;
const ListLabels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #dfe8ed;
`;
const List = styled(Link)`
  padding: 20px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfe8ed;
  align-items: center;
  cursor: pointer;
`;
const Serial = styled.h4`
  color: #2e3032;
`;
const Id = styled.h4`
  color: #2382d4;
`;
const CoverItem = styled.div`
  display: flex;
  align-items: center;
`;
const Iconz = styled.div`
  background-color: #e0f3ff;
  color: #2382d4;
  text-transform: capitalize;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "inter_medium";
  border-radius: 50%;
  margin-right: 10px;
`;
const CoverName = styled.div`
  width: 150px;
`;
const Name = styled.h4`
  color: #2e3032;
  text-transform: capitalize;
  font-size: 14px;
  margin-bottom: 5px;
`;
const Phone = styled.h4`
  font-size: 14px;
  color: #808080;
`;
const Span = styled.div`
  &.id {
    color: #2382d4;
  }
`;
const Icons = styled.div``;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 15px;
  @media all and (max-width: 1380px) {
    padding: 15px 0px;
    margin-right: 40px;
  }
  @media all and (max-width: 1280px) {
    margin-right: 20px;
    padding: 20px 0px;
  }
  @media all and (max-width: 980px) {
    margin-right: 10px;
  }
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 14px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
