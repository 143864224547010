import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import RectangleButton from "../../../../components/Buttons/RectangleButton";
import RectangleColorButton from "../../../../components/Buttons/RectangleColorButton";
import { DropDown } from "../../../../components/assets/icons";
import { Context } from "../../../../context/Store";

import "react-datepicker/dist/react-datepicker.css";
import {
  GetAllFarmers,
  GetAllFarmersforsearch,
  creatLoan,
} from "../../../../api/society";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../../../components/button-loader/ButtonLoader";

function AddLoan(prop) {
  const [isChecked, setIsChecked] = useState(false);
  const [isShow, setShow] = useState(false);
  const [query, setQuery] = useState("");
  const [searchResult, setSearchResult] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //loan
  const [societyID, setSocietyID] = useState();
  const [societyName, setSocietyName] = useState();
  const [particular, setParticular] = useState();
  const [loanAmount, setLoanAmount] = useState();
  const [noOfEmi, setNoOfEmi] = useState();
  const [loanData, setLoanData] = useState();

  const handleClick = () => {
    setIsChecked(!isChecked);
  };
  // get accesstoken from store
  const {
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const goBack = () => {
    navigate(-1);
  };
  const navigate = useNavigate();
  //creating loan formdata
  const loanFormdata = new FormData();
  loanFormdata.append("farmer_id", query);
  loanFormdata.append("particular", particular);
  loanFormdata.append("loan_amount", loanAmount);
  loanFormdata.append("no_emi", noOfEmi);

  const handleCreatingLoan = (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    if (!query) {
      setErrorMessage("Please enter farmer ID");
      setIsButtonLoading(false);
    } else if (!loanAmount) {
      setErrorMessage("Please enter loan amount");
      setIsButtonLoading(false);
    } else if (!noOfEmi) {
      setErrorMessage("Please enter tenure");
      setIsButtonLoading(false);
    } else if (!particular) {
      setErrorMessage("Please enter occasion");
      setIsButtonLoading(false);
    } else {
      creatLoan(config, loanFormdata).then((res) => {
        if (res.app_data.StatusCode == 6000) {
          setQuery("");
          setParticular("");
          setLoanAmount("");
          setNoOfEmi("");
          setSocietyName("");
          setErrorMessage("");
          setIsButtonLoading(false);
          navigate("/loans");
        } else {
          setErrorMessage("");
          setIsButtonLoading(false);
        }
      });
    }
  };
  const param = query;
  //get all farmers
  useEffect(() => {
    GetAllFarmersforsearch(config, param).then((res) => {
      const data = res.app_data.data;
      if (res.app_data.StatusCode == 6000) {
        setSearchResult(data);
      }
    });
  }, [query]);
  return (
    <Container>
      <TopBox>
        <TopBox>
          <Heading>Add Loan</Heading>
          <HeadTag>Loan / Add Loan</HeadTag>
        </TopBox>
      </TopBox>
      <BottomBox onSubmit={(e) => handleCreatingLoan(e)}>
        <GeneralBox>
          <TitleBox>
            <Title>General</Title>
            <HrLine></HrLine>
          </TitleBox>
          <TrioBox>
            <DualBox>
              <SingleBox>
                <Label>Farmer ID</Label>
                <InputBox>
                  {prop.head_tag == "Loan / Add Loan" ? (
                    <input
                      type="text"
                      placeholder="Place Value"
                      value={loanData?.society_id}
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder="Place Value"
                      value={query}
                      onChange={(e) => {
                        setQuery(e.target.value);
                        setShow(true);
                      }}
                      required
                    />
                  )}

                  {isShow && (
                    <SearchResults>
                      {searchResult?.map((item, index) => (
                        <Result
                          onClick={() => {
                            setSocietyName(item.name);
                            setQuery(item.farmer_id);
                            setSocietyID(item.id);
                            setShow(false);
                          }}
                          key={index}
                        >
                          {item.name}
                        </Result>
                      ))}
                    </SearchResults>
                  )}
                </InputBox>
              </SingleBox>
              <SingleBox>
                <Label>Farmer Name</Label>
                <InputBox>
                  <input
                    type="text"
                    placeholder="Place Value"
                    value={societyName}
                    onChange={(e) => setSocietyName(e.target.value)}
                    required
                  />
                </InputBox>
              </SingleBox>
            </DualBox>
            <SingleBox>
              <Label>Particular</Label>
              <InputBox>
                {prop.head_tag == "Loan / Add Loan" ? (
                  <input
                    type="text"
                    placeholder="Place Value"
                    value={loanData?.loan_for}
                    disabled
                  />
                ) : (
                  <input
                    type="text"
                    placeholder="Place Value"
                    value={particular}
                    onChange={(e) => setParticular(e.target.value)}
                    required
                  />
                )}
              </InputBox>
            </SingleBox>
          </TrioBox>
        </GeneralBox>

        <InfoBox>
          <TitleBox>
            <Title>Loan Info</Title>
            <HrLine></HrLine>
          </TitleBox>
          <InfoBottomBox>
            <ContentBox>
              <UnitBox>
                <label>Loan Amount</label>
                <InputBox>
                  {prop.head_tag == "Loan / Add Loan" ? (
                    <input
                      type="text"
                      placeholder="00"
                      value={loanData?.loan_amount}
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder="00"
                      value={loanAmount}
                      onChange={(e) => setLoanAmount(e.target.value)}
                      required
                    />
                  )}
                </InputBox>
              </UnitBox>

              <UnitBox>
                <label>No. of EMI</label>
                <InputBox>
                  <input
                    type="text"
                    placeholder="00"
                    value={noOfEmi}
                    onChange={(e) => setNoOfEmi(e.target.value)}
                    required
                  />
                  <span>
                    <img src={DropDown} />
                  </span>
                </InputBox>
              </UnitBox>
            </ContentBox>
          </InfoBottomBox>
          {/* <ErrorMessage>{errorMessage}</ErrorMessage> */}
          <ButtonBox>
            {/* <RectangleButton label="Cancel" /> */}
            <Button className="cancel" onClick={() => goBack()}>
              Cancel
            </Button>
            {isButtonLoading ? (
              <RectangleColorButton label={<ButtonLoader />} />
            ) : (
              <SubmitButton type="submit" value="Submit" />
            )}
          </ButtonBox>
        </InfoBox>
      </BottomBox>
    </Container>
  );
}

export default AddLoan;

const Container = styled.div`
  width: 70%;
  margin: 10px auto;
  height: calc(100vh - 100px);
  overflow-y: scroll;

  @media (max-width: 1080px) {
    width: 92%;
  }
`;

const TopBox = styled.div`
  margin-bottom: 20px;
`;

const Heading = styled.h2`
  color: #0a0a0a;
  font-size: 26px;
`;
const Button = styled.div`
  width: 170px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.cancel {
    background-color: #fff;
    border: 1px solid #2382d4;
    color: #2382d4;
    margin-right: 20px;
  }
`;
const HeadTag = styled.p`
  margin-top: 6px;
  color: #747474;
  font-size: 16px;
  font-family: "inter_light";
`;

const BottomBox = styled.form`
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

const Title = styled.pre`
  font-size: 18px;
  margin-right: 10px;
`;
const HrLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eee;
`;
const GeneralBox = styled.div`
  width: 100%;
  margin-bottom: 26px;
`;

const InfoBottomBox = styled.div``;

const SingleBox = styled.div`
  width: 100%;
  input {
    background-color: transparent;
    width: 100%;
    font-size: 18px;
  }
`;

const InputBox = styled.div`
  background-color: #f4f5f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #dfe8ed;
  position: relative;
  input {
    font-family: "inter_light";
  }
`;

const TrioBox = styled.div``;

const DualBox = styled.div`
  display: flex;
  justify-content: space-between;

  ${SingleBox} {
    width: 49%;
  }
  /* input {
        padding: 18px 24px;
        border: 1px solid #dfe8ed;
        border-radius: 4px;
        width: 100%;
        margin: 10px 0;
        font-size: 18px;
    } */
`;
const Label = styled.h4`
  color: #747474;
  font-family: "inter_light";
  font-weight: unset;
  font-size: 18px;
`;

const InfoBox = styled.div`
  width: 100%;
`;

const ToggleBox = styled.div`
  width: 40%;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 18px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: -4px;
    bottom: -4px;
    background-color: white;
    border: 2px solid #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
    &::before {
      border: 2px solid #2196f3;
    }
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

const ToggleTitle = styled.div``;
const ContentBox = styled.div`
  margin-bottom: 48px;
`;
const SearchResults = styled.div`
  height: 300px;
  overflow: scroll;
  background-color: #f2f2f2;
  position: absolute;
  border-radius: 0 0 20px 20px;
  top: 60px;
  padding: 10px;
  left: 0;
  width: 100%;
  z-index: 10;
`;
const Result = styled.div`
  background-color: #e5e5e5;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 10px;
`;
const UnitBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  label {
    color: #747474;
    font-family: "inter_light";
    font-weight: unset;
    font-size: 18px;
  }

  ${InputBox} {
    width: 50%;
    margin: 0;
    input {
      font-size: 18px;
      background-color: transparent;
    }

    span {
      font-family: "inter_light";
      font-size: 16px;
    }
  }
`;

const ErrorMessage = styled.span`
  color: red;
  margin-bottom: 5px;
  text-align: right;
  width: 100%;
  display: inline-block;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;
const Input = styled.div`
  width: 100%;
  position: relative;
  background-color: #f4f5f8;
  padding: 18px 24px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #dfe8ed;
`;
const ImageContainer = styled.div`
  margin-left: 5px;
  width: 18px;
  position: absolute;
  right: 10px;
  top: 20px;
  img {
    display: block;
    width: 100%;
  }
`;
const CoverInput = styled.div`
  display: flex;
  align-items: center;
`;
const CoverLabel = styled.div`
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
`;
const SubmitButton = styled.input`
  width: 160px;
  height: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2382d4;
  border-radius: 4px;
  padding: 6px 12px;
  background: #2382d4;
  color: #fff;

  ${Label} {
    font-size: 14px;
    font-family: "inter_light";
  }
`;