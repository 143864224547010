import React from "react";
import { no_data, nodatafound } from "../../../components/assets";
import { styled } from "styled-components";

function NodataFound() {
  return (
    <Container>
      <img src={nodatafound} alt="no-data" />
    </Container>
  );
}

export default NodataFound;

const Container = styled.div`
  width: 30%;
  margin: 0 auto;
  padding-top: 130px;
  img {
    width: 100%;
    display: block;
  }
`;
