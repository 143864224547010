import React from "react";
import { styled } from "styled-components";
import { SearchIcon } from "../assets/icons";

const SearchInput = ({ state, setState }) => {
  return (
    <Cover>
      <Left>
        <i class="ri-search-line"></i>
      </Left>
      <input
        type="text"
        value={state}
        placeholder="search"
        onChange={(e) => setState(e.target.value)}
      />
    </Cover>
  );
};

export default SearchInput;

const Cover = styled.div`
  width: 250px;
  border: 1px solid #2382d4;
  border-radius: 8px;
  height: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  input {
    width: 100%;
    height: 100%;
    padding-left: 6px;
    &::placeholder {
      color: #2382d4;
      font-family: "inter_light";
      font-size: 14px;
    }
    border-radius: 8px;
  }
  @media (max-width:680px) {
    width: 153px;
  }
  
`;
const Left = styled.div`
  width: 18px;
  display: flex;
  margin-left: 10px;
  color: #2382d4;
  img {
    width: 100%;
    display: block;
  }
`;
