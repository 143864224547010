import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { curve } from "../assets";
import api from "../../api";
import { Fade } from "react-reveal";

function InputCard({ title, icon, value, label, setState, type }) {
  const [convertedAmount, setConvertedAmount] = useState("");
  useEffect(() => {
    type == "convert" &&
      api
        .get("activities/convert-milk-kg-liter/", {
          params: {
            quantity: value,
          },
        })
        .then((res) => {
          if (res.app_data.StatusCode == 6000) {
            setConvertedAmount(res.app_data.data.quantity_in_liter);
          } else {
            setConvertedAmount("");
          }
        });
  }, [value]);

  return (
    <Container>
      <HeadContainer>
        {icon && (
          <Icon>
            <img src={icon} alt="icon" />
          </Icon>
        )}
        <Title>{title}</Title>
      </HeadContainer>
      <Cover>
        <input
          type="number"
          placeholder="00"
          value={value}
          onChange={(e) => {
            title !== "FAT value" ? setState(e.target.value.slice(0,5)) : setState(e.target.value.slice(0,3))}}
          required={title !== "Damaged milk"}
        />
        {/* <h1>{number}</h1> */}
        {label && <h3>{label} </h3>}
      </Cover>

      {convertedAmount && (
        <Fade>
          <Con>{convertedAmount}L </Con>
        </Fade>
      )}
      <Curve>
        <img src={curve} alt="image" />
      </Curve>
    </Container>
  );
}

export default InputCard;

const Container = styled.div`
  position: relative;
  background-color: #fff;
  padding: 24px;
  width: 100%;
  /* height: 250px; */
  margin-right: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  z-index: 1;
  &:last-child {
    margin-right: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input {
    color: #0a0a0a;
    font-size: 40px;
    margin-right: 10px;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #dfe8ed;
    background-color: #fff0;
  }
  h3 {
    color: #747474;
    font-family: "inter_light";
    font-size: 22px;
  }
`;
const HeadContainer = styled.div`
  /* background-image: url(); */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
`;
const Cover = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const Icon = styled.div`
  /* width: 55px; */
  margin-right: 10px;
  img {
    width: 100%;
    display: block;
  }
`;
const Title = styled.h4`
  color: #747474;
  font-family: "inter_light";
  font-weight: unset;
  font-size: 20px;
`;
const Curve = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  transform: scaleY(-1);
  img {
    width: 100%;
    display: block;
  }
`;
const Con = styled.div`
  border: 1px solid #3b3b3b4a;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 8px;
  color: #000000ab;
  box-shadow: 1px 2px 8px #00000040;
`;
