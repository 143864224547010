import api from "..";

export const AddProductApi = (formData, config) => {
  return api.post(`product/add-product/`, formData, config).then((res) => {
    return res;
  });
};
export const EditProductApi = (product_id, formData, config) => {
  return api.post(`product/edit-product/${product_id}`, formData, config).then((res) => {
    return res;
  });
};

export const getProductList = (config) => {
  return api.get("product/products/", config).then((res) => {
    return res;
  });
};

export const getSingleProductList = (config, productId) => {
  return api.get(`product/product/${productId}/`, config).then((res) => {
    return res;
  });
};

export const addToCart = (config, formData) => {
  return api.post(`product/add_cart/`, formData, config).then((res) => {
    return res;
  });
};

export const cartList = (config) => {
  return api.get("product/cart/", config).then((res) => {
    return res;
  });
};

export const deleteFromCart = (cartId, formDataProduct, config) => {
  return api
    .post(`product/delete-from-cart/${cartId}/`, formDataProduct, config)
    .then((res) => {
      return res;
    });
};
export const deleteProduct = (prodId, config) => {
  return api.post(`product/delete-product/${prodId}/`, config).then((res) => {
    return res;
  });
};
export const checkoutConfirmOrder = (useridFormData, config) => {
  return api.post(`product/checkout/`, useridFormData, config).then((res) => {
    return res;
  });
};
