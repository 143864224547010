import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Context } from "../../../context/Store";
import { getYYMMDD } from "../../../utils/functions/function";
import api from "../../../api";
import PrintTable from "./PrintTable";

const PrintMilkReport = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const startDate = queryParams.get("start_date");
  const endDate = queryParams.get("end_date");
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [error, setError] = useState(null);

  const {
    state: { user_data },
  } = useContext(Context);

  const accessToken = user_data.access_token;

  useEffect(() => {
    const fetchData = async (retryCount = 0) => {
      setIsPrintLoading(true);
      setError(null);

      try {
        const response = await api.get(
          `activities/print-plant-milk-report/?page_size=40&start_date=${getYYMMDD(
            startDate
          )}&end_date=${getYYMMDD(endDate)}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        console.log("Response data:", response.app_data.data);
        setDatas(response.app_data.data);
        // Check if the data exists and is an array with elements
        // if (await response?.data?.app_data) {
        //   setDatas(response.data.app_data.data);
        // } else {
        //   console.error("No valid data found");
        //   setError("No data found for the selected date range.");
        // }
      } catch (error) {
        if (retryCount < 2) {
          console.warn(`Retrying request... Attempt ${retryCount + 1}`);
          fetchData(retryCount + 1);
        } else {
          console.error("Failed to fetch data", error);
          setError(
            "Failed to fetch data. The server is taking too long to respond or is currently unavailable. Please try again later."
          );
        }
      } finally {
        setIsPrintLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, accessToken]);
  console.log(datas,'____new___data');

  return (
    <div>
      {isPrintLoading ? (
        <h1>Loading.....</h1>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <PrintTable data={datas} />
      )}
    </div>
  );
};

export default PrintMilkReport;
