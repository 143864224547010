import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import RectangleColorButton from "../Buttons/RectangleColorButton";
import { Calender } from "../assets";
import {
    AddPlantIncentives,
    AddSocietyBonus,
    CreateLoan,
    ViewLoanData,
    updateMilkRate,
} from "../../api/auth";
import { Context } from "../../context/Store";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import { getDDMMYY, getYYMMDD } from "../../utils/functions/function";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { getFarmerLoan, getSingleLoanData } from "../../api/society";
import ButtonLoader from "../button-loader/ButtonLoader";

function CreateCard(prop) {
    const [isChecked, setIsChecked] = useState(false);
    const [isShow, setShow] = useState(false);
    const [query, setQuery] = useState("");
    const [searchResult, setSearchResult] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    //   bonus
    const [bonusDescription, setBonusDescription] = useState("");
    const [startDate, setStartdate] = useState();
    const [endDate, setEndDate] = useState();
    const [jeevaBonusAmount, setJeevaAmount] = useState("");
    const [prvtSocAmount, setPrivateSocAmount] = useState("");
    const [farmsBonusAmount, setFarmsBonusAmount] = useState("");
    const [generalAmount, setGeneralAmount] = useState("");

    //loan
    const [societyID, setSocietyID] = useState("");
    const [societyName, setSocietyName] = useState("");
    const [particular, setParticular] = useState("");
    const [loanAmount, setLoanAmount] = useState("");
    const [noOfEmi, setNoOfEmi] = useState("");
    const [loanData, setLoanData] = useState("");

    //milkrate
    const [currentdate, setCurrentDate] = useState();

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handleClick = () => {
        setIsChecked(!isChecked);
    };
    const navigate = useNavigate();
    // get accesstoken from store
    const {
        state: { user_data, route_data },
    } = useContext(Context);
    const token = user_data.access_token;
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    // goback function
    const goBack = () => {
        navigate(-1);
    };

    const role = user_data.roles;
    const path = window.location.pathname;
    //create bonus formdata
    const formData = new FormData();
    if (prop.category === "incentive") {
        formData.append("incentive_description", bonusDescription);
        formData.append("start_date", getYYMMDD(startDate));
        formData.append("end_date", getYYMMDD(endDate));
        formData.append("jeeva_incentive_amount", jeevaBonusAmount);
        formData.append(
            "private_society_incentive_amount",
            parseInt(prvtSocAmount)
        );
        formData.append("farms_incentive_amount", parseInt(farmsBonusAmount));
    } else {
        formData.append("bonus_description", bonusDescription);
        formData.append("start_date", getYYMMDD(startDate));
        formData.append("end_date", getYYMMDD(endDate));
    }
    if (isChecked) {
        formData.append("general_amount", parseInt(generalAmount));
    } else {
        formData.append("jeeva_bonus_amount", parseInt(jeevaBonusAmount));
        formData.append(
            "private_society_bonus_amount",
            parseInt(prvtSocAmount)
        );
        formData.append("farms_bonus_amount", parseInt(farmsBonusAmount));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsButtonLoading(true);
        if (!bonusDescription) {
            setErrorMessage("Please enter occasions");
            setIsButtonLoading(false);
        } else if (!startDate) {
            setErrorMessage("Please enter start date");
            setIsButtonLoading(false);
        } else if (!endDate) {
            setErrorMessage("Please enter end date");
            setIsButtonLoading(false);
        } else if (isChecked && !generalAmount) {
            setErrorMessage("Please enter bonus amount");
            setIsButtonLoading(false);
        } else if (!isChecked && !jeevaBonusAmount) {
            setErrorMessage("Please enter bonus for jeeva societies");
            setIsButtonLoading(false);
        } else if (!isChecked && !prvtSocAmount) {
            setErrorMessage("Please enter bonus for private societies");
            setIsButtonLoading(false);
        } else if (!isChecked && !farmsBonusAmount) {
            setErrorMessage("Please enter bonus for farms");
            setIsButtonLoading(false);
        } else {
            AddSocietyBonus(config, formData).then((res) => {
                if (res.app_data.StatusCode == 6000) {
                    setErrorMessage("");
                    setIsButtonLoading(false);
                    navigate("/bonus");
                } else {
                    setErrorMessage("");
                    setIsButtonLoading(false);
                }
            });
        }
    };

    //create incentives
    const handleIncentives = (e) => {
        e.preventDefault();
        setIsButtonLoading(true);
        if (!bonusDescription) {
            setErrorMessage("Please enter occasions");
            setIsButtonLoading(false);
        } else if (!startDate) {
            setErrorMessage("Please enter date of incentive");
            setIsButtonLoading(false);
        } else if (isChecked && !generalAmount) {
            setErrorMessage("Please enter bonus amount");
            setIsButtonLoading(false);
        } else if (!isChecked && !jeevaBonusAmount) {
            setErrorMessage("Please enter bonus for jeeva societies");
            setIsButtonLoading(false);
        } else if (!isChecked && !prvtSocAmount) {
            setErrorMessage("Please enter bonus for private societies");
            setIsButtonLoading(false);
        } else if (!isChecked && !farmsBonusAmount) {
            setErrorMessage("Please enter bonus for farms");
            setIsButtonLoading(false);
        } else {
            AddPlantIncentives(config, formData).then((res) => {
                if (res.app_data.StatusCode == 6000) {
                    setErrorMessage("");
                    setIsButtonLoading(false);
                    navigate("/incentives");
                } else {
                    setErrorMessage("");
                    setIsButtonLoading(false);
                }
            });
        }
    };
    useEffect(() => {
        api.get(`accounts/societies/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                q: query,
            },
        })
            .then((res) => {
                if (res.app_data.StatusCode === 6000) {
                    setSearchResult(res.app_data.data);
                } else {
                    setSearchResult([]);
                }
            })
            .catch((error) => {
                // setError(true)
            });
    }, [query]);

    //creating loan formdata
    const loanFormdata = new FormData();
    loanFormdata.append("society_id", query);
    loanFormdata.append("particular", particular);
    loanFormdata.append("loan_amount", loanAmount);
    loanFormdata.append("no_emi", noOfEmi);

    const handleCreatingLoan = (e) => {
        e.preventDefault();
        setIsButtonLoading(true);
        if (!query) {
            setErrorMessage("Please enter society ID");
            setIsButtonLoading(false);
        } else if (!particular) {
            setErrorMessage("Please enter particular/occasion");
            setIsButtonLoading(false);
        } else if (!loanAmount) {
            setErrorMessage("Please enter loan amount");
            setIsButtonLoading(false);
        } else if (!noOfEmi) {
            setErrorMessage("Please enter no. of EMI");
            setIsButtonLoading(false);
        } else {
            CreateLoan(config, loanFormdata).then((res) => {
                if (res.app_data.StatusCode == 6000) {
                    setErrorMessage("");
                    setIsButtonLoading(false);
                    navigate("/loans");
                } else {
                    setErrorMessage("");
                    setIsButtonLoading(false);
                }
            });
        }
    };
    const Id = user_data.loan_id;
    //view loan
    useEffect(() => {
        role == "Society" &&
            Id &&
            getSingleLoanData(config, Id).then((res) => {
                if (res.app_data.StatusCode == 6000) {
                    setLoanData(res.app_data.data);
                }
            });
    }, []);

    //milkrate
    const milkrateData = new FormData();
    milkrateData.append("affective_on", getYYMMDD(currentdate));
    if (isChecked) {
        milkrateData.append("general_rate", parseInt(generalAmount));
    } else {
        milkrateData.append("jeeva_rate", parseInt(jeevaBonusAmount));
        milkrateData.append("private_rate", parseInt(prvtSocAmount));
        milkrateData.append("farms_rate", parseInt(farmsBonusAmount));
    }
    const handleMilkrate = (e) => {
        e.preventDefault();
        setIsButtonLoading(true);
        if (!currentdate) {
            setErrorMessage("Please enter rate applying date");
            setIsButtonLoading(false);
        } else if (isChecked && !generalAmount) {
            setErrorMessage("Please enter milk rate");
            setIsButtonLoading(false);
        } else if (!isChecked && !jeevaBonusAmount) {
            setErrorMessage("Please enter milk rate for jeeva societies");
            setIsButtonLoading(false);
        } else if (!isChecked && !prvtSocAmount) {
            setErrorMessage("Please enter milk rate for private societies");
            setIsButtonLoading(false);
        } else if (!isChecked && !farmsBonusAmount) {
            setErrorMessage("Please enter milk rate for farms");
            setIsButtonLoading(false);
        } else {
            updateMilkRate(config, milkrateData).then((res) => {
                if (res.StatusCode == 6000) {
                    setCurrentDate();
                    setJeevaAmount("");
                    setPrivateSocAmount("");
                    setFarmsBonusAmount("");
                    setGeneralAmount("");
                    setIsButtonLoading(false);
                    setErrorMessage("");
                }
            });
        }
    };
    return (
        <Container>
            <TopBox>
                <Heading>
                    {prop.title}{" "}
                    {prop.title === "Loan No:" && loanData?.loan_id}
                </Heading>
                {prop.head_tag && <HeadTag>{prop.head_tag}</HeadTag>}
            </TopBox>
            <BottomBox
                onSubmit={(e) =>
                    prop.category === "incentive"
                        ? handleIncentives(e)
                        : prop.category === "loan"
                        ? handleCreatingLoan(e)
                        : prop.category === "rate"
                        ? handleMilkrate(e)
                        : handleSubmit(e)
                }
            >
                <GeneralBox>
                    <TitleBox>
                        <Title>General</Title>
                        <HrLine></HrLine>
                    </TitleBox>
                    {prop.category === "rate" ? (
                        <SingleBox>
                            <Label>Rate applying date</Label>
                            <InputBox>
                                <DatePicker
                                    minDate={new Date()}
                                    value={
                                        currentdate === ""
                                            ? "DD/MM/YYYY"
                                            : getDDMMYY(currentdate)
                                    }
                                    onChange={(date) => setCurrentDate(date)}
                                    required
                                />
                            </InputBox>
                        </SingleBox>
                    ) : prop.category === "bonus" ? (
                        <DualBox>
                            <SingleBox>
                                <Label>Particular / occasion</Label>
                                <InputBox>
                                    <input
                                        type="text"
                                        placeholder="Enter occasion"
                                        value={bonusDescription}
                                        onChange={(e) =>
                                            setBonusDescription(e.target.value)
                                        }
                                        required
                                    />
                                </InputBox>
                            </SingleBox>
                            <SingleBox>
                                {/* <Label>Time period</Label> */}
                                <CoverInput>
                                    <CoverLabel>
                                        <Label>From Date</Label>
                                        <Input>
                                            <DatePicker
                                                value={
                                                    startDate === ""
                                                        ? "DD/MM/YYYY"
                                                        : getYYMMDD(startDate)
                                                }
                                                onChange={(date) =>
                                                    setStartdate(date)
                                                }
                                                required
                                            />
                                            <ImageContainer>
                                                <img src={Calender} alt="" />
                                            </ImageContainer>
                                        </Input>
                                    </CoverLabel>
                                    <CoverLabel>
                                        <Label>To Date</Label>
                                        <Input>
                                            <DatePicker
                                                value={
                                                    endDate === ""
                                                        ? "DD/MM/YYYY"
                                                        : getYYMMDD(endDate)
                                                }
                                                onChange={(date) =>
                                                    setEndDate(date)
                                                }
                                                required
                                                minDate={startDate}
                                            />
                                            <ImageContainer>
                                                <img src={Calender} alt="" />
                                            </ImageContainer>
                                        </Input>
                                    </CoverLabel>
                                </CoverInput>
                            </SingleBox>
                        </DualBox>
                    ) : prop.category === "incentive" ? (
                        <>
                            <SingleBox>
                                <Label>Particular / occasion</Label>
                                <InputBox>
                                    <input
                                        type="text"
                                        placeholder="Enter occasion"
                                        value={bonusDescription}
                                        onChange={(e) =>
                                            setBonusDescription(e.target.value)
                                        }
                                        required
                                    />
                                </InputBox>
                            </SingleBox>
                            <SingleBox>
                                <CoverInput>
                                    <CoverLabel>
                                        <Label>From Date</Label>
                                        <Input>
                                            <DatePicker
                                                value={
                                                    startDate === ""
                                                        ? "DD/MM/YYYY"
                                                        : getYYMMDD(startDate)
                                                }
                                                onChange={(date) =>
                                                    setStartdate(date)
                                                }
                                                required
                                            />
                                            <ImageContainer>
                                                <img src={Calender} alt="" />
                                            </ImageContainer>
                                        </Input>
                                    </CoverLabel>
                                    <CoverLabel>
                                        <Label>To Date</Label>
                                        <Input>
                                            <DatePicker
                                                value={
                                                    endDate === ""
                                                        ? "DD/MM/YYYY"
                                                        : getYYMMDD(endDate)
                                                }
                                                onChange={(date) =>
                                                    setEndDate(date)
                                                }
                                                required
                                                minDate={startDate}
                                            />
                                            <ImageContainer>
                                                <img src={Calender} alt="" />
                                            </ImageContainer>
                                        </Input>
                                    </CoverLabel>
                                </CoverInput>
                            </SingleBox>
                        </>
                    ) : //  <DualBox></DualBox>
                    prop.category === "loan" ? (
                        <TrioBox>
                            <DualBox>
                                <SingleBox>
                                    <Label>Society ID</Label>
                                    <InputBox>
                                        {path == "/view-loan" ? (
                                            <input
                                                type="text"
                                                placeholder="Place Value"
                                                value={loanData?.loan_id}
                                                disabled
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                placeholder="Enter Society Id"
                                                value={query}
                                                onChange={(e) => {
                                                    setQuery(e.target.value);
                                                    setShow(true);
                                                }}
                                                required
                                            />
                                        )}

                                        {isShow && (
                                            <SearchResults>
                                                {searchResult?.length == 0
                                                    ? "No Societies"
                                                    : searchResult?.map(
                                                          (item, index) => (
                                                              <Result
                                                                  onClick={() => {
                                                                      setSocietyName(
                                                                          item.name
                                                                      );
                                                                      setQuery(
                                                                          item.code
                                                                      );
                                                                      setSocietyID(
                                                                          item.id
                                                                      );
                                                                      setShow(
                                                                          false
                                                                      );
                                                                  }}
                                                                  key={index}
                                                              >
                                                                  {item.name}{" "}
                                                              </Result>
                                                          )
                                                      )}
                                            </SearchResults>
                                        )}
                                    </InputBox>
                                </SingleBox>
                                <SingleBox>
                                    <Label>Society Name</Label>
                                    <InputBox>
                                        {path == "/view-loan" ? (
                                            <input
                                                type="text"
                                                value={loanData?.farmer}
                                                disabled
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                placeholder="Enter Society Name"
                                                value={societyName}
                                                onChange={(e) =>
                                                    setSocietyName(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                        )}
                                    </InputBox>
                                </SingleBox>
                            </DualBox>
                            <SingleBox>
                                <Label>Particular</Label>
                                <InputBox>
                                    {path == "/view-loan" ? (
                                        <input
                                            type="text"
                                            placeholder="Place Value"
                                            value={loanData?.loan_for}
                                            disabled
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            placeholder="Enter Particular"
                                            value={particular}
                                            onChange={(e) =>
                                                setParticular(e.target.value)
                                            }
                                            required
                                        />
                                    )}
                                </InputBox>
                            </SingleBox>
                        </TrioBox>
                    ) : null}
                </GeneralBox>

                <InfoBox>
                    <TitleBox>
                        <Title>{prop.info_title}</Title>
                        <HrLine></HrLine>
                    </TitleBox>
                    {prop.category === "loan" ? (
                        <InfoBottomBox>
                            <ContentBox>
                                <UnitBox>
                                    {prop.same_rate_tag && (
                                        <label>{prop.same_rate_tag}</label>
                                    )}
                                    <InputBox>
                                        {path == "/view-loan" ? (
                                            <input
                                                type="text"
                                                placeholder="00"
                                                value={loanData?.loan_amount}
                                                disabled
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                placeholder="00"
                                                value={loanAmount}
                                                onChange={(e) =>
                                                    setLoanAmount(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                        )}
                                    </InputBox>
                                </UnitBox>
                                {prop.values ? (
                                    <>
                                        <UnitBox>
                                            <label>EMI Plan</label>
                                            <InputBox>
                                                <input
                                                    type="text"
                                                    placeholder="00"
                                                    value={loanData?.tenure}
                                                    disabled
                                                />
                                            </InputBox>
                                        </UnitBox>
                                        {/* <UnitBox>
                      <label>Remaining Amount</label>
                      <InputBox>
                        <input
                          type="text"
                          placeholder="00"
                          value={loanData?.remaining_amount}
                          disabled
                        />
                      </InputBox>
                    </UnitBox> */}
                                    </>
                                ) : (
                                    <UnitBox>
                                        <label>No. of EMI</label>
                                        <InputBox>
                                            <input
                                                type="text"
                                                placeholder="00"
                                                value={noOfEmi}
                                                onChange={(e) =>
                                                    setNoOfEmi(e.target.value)
                                                }
                                                required
                                            />
                                        </InputBox>
                                    </UnitBox>
                                )}
                            </ContentBox>
                        </InfoBottomBox>
                    ) : (
                        <InfoBottomBox>
                            <ToggleBox>
                                <ToggleTitle>
                                    Same {prop.category} for all
                                </ToggleTitle>
                                <label class="switch">
                                    <input
                                        type="checkbox"
                                        onClick={() => handleClick()}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </ToggleBox>
                            {isChecked ? (
                                <ContentBox>
                                    <UnitBox>
                                        {prop.same_rate_tag && (
                                            <label>{prop.same_rate_tag}</label>
                                        )}
                                        <InputBox>
                                            <input
                                                type="text"
                                                placeholder="00"
                                                value={generalAmount}
                                                onChange={(e) =>
                                                    setGeneralAmount(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <span>per liter</span>
                                        </InputBox>
                                    </UnitBox>
                                </ContentBox>
                            ) : (
                                <ContentBox>
                                    <UnitBox>
                                        <label>Jeeva Societies</label>
                                        <InputBox>
                                            <input
                                                type="number"
                                                placeholder="00"
                                                value={jeevaBonusAmount}
                                                onChange={(e) =>
                                                    setJeevaAmount(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <span>per liter</span>
                                        </InputBox>
                                    </UnitBox>
                                    <UnitBox>
                                        <label>Private Societies</label>
                                        <InputBox>
                                            <input
                                                type="number"
                                                placeholder="00"
                                                value={prvtSocAmount}
                                                onChange={(e) =>
                                                    setPrivateSocAmount(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <span>per liter</span>
                                        </InputBox>
                                    </UnitBox>
                                    <UnitBox>
                                        <label>Farms</label>
                                        <InputBox>
                                            <input
                                                type="number"
                                                placeholder="00"
                                                value={farmsBonusAmount}
                                                onChange={(e) =>
                                                    setFarmsBonusAmount(
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <span>per liter</span>
                                        </InputBox>
                                    </UnitBox>
                                </ContentBox>
                            )}
                            {/* {prop.notifyCheckBox ? (
                                <CheckBox>
                                    <input type="checkbox" placeholder="00" />
                                    <label>Send message for all farmers</label>
                                </CheckBox>
                            ) : null} */}
                        </InfoBottomBox>
                    )}
                    {/* <ErrorMessage>{errorMessage}</ErrorMessage> */}
                    {prop.values ? null : (
                        <ButtonBox>
                            {/* <RectangleButton label="Cancel" /> */}
                            <Buttons
                                className="cancel"
                                onClick={() => goBack()}
                            >
                                Cancel
                            </Buttons>
                            {isButtonLoading ? (
                                <RectangleColorButton
                                    label={<ButtonLoader />}
                                />
                            ) : (
                                <Button
                                    type="submit"
                                    value={
                                        prop.category === "rate"
                                            ? "Update"
                                            : "Create"
                                    }
                                />
                            )}
                        </ButtonBox>
                    )}
                </InfoBox>
            </BottomBox>
        </Container>
    );
}

export default CreateCard;

const Container = styled.div``;

const TopBox = styled.div`
    margin-bottom: 20px;
`;

const Heading = styled.h2`
    color: #0a0a0a;
    font-size: 26px;
`;

const HeadTag = styled.p`
    margin-top: 6px;
    color: #747474;
    font-size: 16px;
    font-family: "inter_light";
`;

const BottomBox = styled.form`
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
`;

const TitleBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
`;

const Title = styled.pre`
    font-size: 18px;
    margin-right: 10px;
`;
const HrLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: #eee;
`;
const GeneralBox = styled.div`
    width: 100%;
    margin-bottom: 26px;
`;

const InfoBottomBox = styled.div``;

const SingleBox = styled.div`
    width: 100%;
    input {
        background-color: transparent;
        width: 100%;
        font-size: 18px;
        @media (max-width: 820px) {
            font-size: 12px;
        }
    }
`;

const InputBox = styled.div`
    background-color: #f4f5f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 24px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #dfe8ed;
    position: relative;
    input {
        font-family: "inter_light";
    }
`;

const TrioBox = styled.div``;

const DualBox = styled.div`
    display: flex;
    justify-content: space-between;

    ${SingleBox} {
        width: 49%;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    /* input {
        padding: 18px 24px;
        border: 1px solid #dfe8ed;
        border-radius: 4px;
        width: 100%;
        margin: 10px 0;
        font-size: 18px;
    } */
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
const Label = styled.h4`
    color: #747474;
    font-family: "inter_light";
    font-weight: unset;
    font-size: 18px;
`;

const InfoBox = styled.div`
    width: 100%;
`;

const ToggleBox = styled.div`
    width: 40%;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .switch {
        position: relative;
        display: inline-block;
        width: 36px;
        height: 18px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 22px;
        left: -4px;
        bottom: -4px;
        background-color: white;
        border: 2px solid #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: #2196f3;
        &::before {
            border: 2px solid #2196f3;
        }
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
    @media (max-width: 620px) {
        width: 70%;
    }
`;

const ToggleTitle = styled.div``;
const ContentBox = styled.div`
    margin-bottom: 48px;
`;
const SearchResults = styled.div`
    height: 300px;
    overflow: scroll;
    background-color: #f2f2f2;
    position: absolute;
    border-radius: 0 0 20px 20px;
    top: 60px;
    padding: 10px;
    left: 0;
    width: 100%;
    z-index: 10;
`;
const Result = styled.div`
    background-color: #e5e5e5;
    padding: 20px;
    cursor: pointer;
    margin-bottom: 10px;
`;
const UnitBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    label {
        color: #747474;
        font-family: "inter_light";
        font-weight: unset;
        font-size: 18px;
    }

    ${InputBox} {
        width: 50%;
        margin: 0;
        @media (max-width: 768px) {
            width: 100%;
        }
        input {
            font-size: 18px;
            background-color: transparent;
        }

        span {
            font-family: "inter_light";
            font-size: 16px;
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
    }
`;

const CheckBox = styled.div`
    input {
        margin-right: 8px;
    }
`;
const Buttons = styled.div`
    width: 170px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2382d4;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.cancel {
        background-color: #fff;
        border: 1px solid #2382d4;
        color: #2382d4;
        margin-right: 20px;
    }
`;
const ButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    margin-top: 15px;
`;
const Input = styled.div`
    width: 100%;
    position: relative;
    background-color: #f4f5f8;
    padding: 18px 24px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #dfe8ed;
`;
const ImageContainer = styled.div`
    margin-left: 5px;
    width: 18px;
    position: absolute;
    right: 10px;
    top: 20px;
    img {
        display: block;
        width: 100%;
    }
`;
const CoverInput = styled.div`
    display: flex;
    align-items: center;
`;
const CoverLabel = styled.div`
    width: 50%;
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
`;
const ErrorMessage = styled.span`
    color: red;
    margin-bottom: 5px;
    text-align: right;
    width: 100%;
    display: inline-block;
    &::first-letter {
        text-transform: uppercase;
    }
`;
const Button = styled.input`
    width: 160px;
    height: 48px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2382d4;
    border-radius: 4px;
    padding: 6px 12px;
    background: #2382d4;
    color: #fff;
`;
