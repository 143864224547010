import React, { useContext, useState } from "react";
import { styled } from "styled-components";
import { CreatingRoute } from "../../../api/auth";
import { Context } from "../../../context/Store";
import ButtonLoader from "../../../components/button-loader/ButtonLoader";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function CreateRoute({ setCreate }) {
  // states
  const [name, setName] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [driverName, setDriverName] = useState("");
  const [coDriverName, setCoDriverName] = useState("");
  const [driverNumber, setDriverNumber] = useState("");
  const [coDriverNumber, setCoDriverNumber] = useState("");
  const [registerNum, setRegisterNumber] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  // get accesstoken from store
  const {
    state: { user_data },
  } = useContext(Context);
  const token = user_data.access_token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const path = window.location.pathname;
  const showSuccessModal = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Route Created Successfully",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  //payload
  const formData = new FormData();
  formData.append("rout_name", name);
  formData.append("vehicle_name", vehicle);
  formData.append("vehicle_number", vehicle);
  // formData.append("register_number", registerNum);
  formData.append("driver_name", driverName);
  formData.append("driver_phone", driverNumber);
  formData.append("co_driver_name", coDriverName);
  formData.append("co_driver_phone", coDriverNumber);

  //creating route
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsButtonLoading(true);
    if (!name) {
      setErrorMessage("Please enter route name");
      setIsButtonLoading(false);
    } else if (!vehicle) {
      setErrorMessage("Please enter vehicle name / number");
      setIsButtonLoading(false);
    } else if (!driverName) {
      setErrorMessage("Please enter driver name");
      setIsButtonLoading(false);
    } else if (!driverNumber) {
      setErrorMessage("Please enter driver number");
      setIsButtonLoading(false);
    } else if (!coDriverName) {
      setErrorMessage("Please enter co-driver name");
      setIsButtonLoading(false);
    } else if (!coDriverNumber) {
      setErrorMessage("Please enter co-driver number");
      setIsButtonLoading(false);
    } else {
    CreatingRoute(config, formData).then((res) => {
      if (res.app_data.StatusCode == 6000) {
        setIsButtonLoading(false);
        if (path !== "/create-route") {
          setCreate(false);
        }
        handleClear();
        showSuccessModal();
        navigate('/routes')
      } else {
        setErrorMessage(
          res.data?.data ? res.data.data.message : res.data.message
        );
        setIsButtonLoading(false);
      }
    });
    }
  };
  const handleClear = () => {
    setName("");
    setCoDriverName("");
    setCoDriverNumber("");
    setDriverName("");
    setDriverNumber("");
    setRegisterNumber("");
    setErrorMessage("");
    setVehicle("");
  };
  return (
    <>
      <Title>Create Route</Title>
      <RouteInfo>
        <CoverInfo>
          <Form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <General>General</General>
            <CoverForm>
              <CoverIn>
                <Label>Name*</Label>
                <Input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </CoverIn>
            </CoverForm>
            <General className="veh">Vehicle & driver details</General>
              <CoverIn>
                <Label>Vehicle name / Number</Label>
                <Input
                  type="text"
                  value={vehicle}
                  onChange={(e) => setVehicle(e.target.value)}
                />
              </CoverIn>
              {/* <CoverIn>
                <Label>Reg number</Label>
                <Input
                  type="text"
                  value={registerNum}
                  onChange={(e) => setRegisterNumber(e.target.value)}
                />
              </CoverIn> */}
            <CoverForm>
              <CoverIn>
                <Label>Driver name</Label>
                <Input
                  type="text"
                  value={driverName}
                  onChange={(e) => setDriverName(e.target.value)}
                />
              </CoverIn>
              <CoverIn>
                <Label>Phone Number</Label>
                <Input
                  type="number"
                  className="auto"
                  value={driverNumber}
                  onChange={(e) => setDriverNumber(e.target.value.slice(0, 10))}
                />
              </CoverIn>
            </CoverForm>
            <CoverForm>
              <CoverIn>
                <Label>Co-driver name</Label>
                <Input
                  type="text"
                  value={coDriverName}
                  onChange={(e) => setCoDriverName(e.target.value)}
                />
              </CoverIn>
              <CoverIn>
                <Label>Phone Number</Label>
                <Input
                  type="number"
                  className="auto"
                  value={coDriverNumber}
                  onChange={(e) => setCoDriverNumber(e.target.value.slice(0, 10))}
                />
              </CoverIn>
            </CoverForm>
            {/* <ErrorMessage>{errorMessage}</ErrorMessage> */}
            <Buttons>
              {/* <Button className="cancel">Cancel</Button> */}
              <Button className="cancel" onClick={() => goBack()}>
                Cancel
              </Button>
              {isButtonLoading ? (
                <Button>
                  <ButtonLoader />
                </Button>
              ) : (
                <SubmitButton type="submit" value="Create" />
              )}
            </Buttons>
          </Form>
        </CoverInfo>
      </RouteInfo>
    </>
  );
}

export default CreateRoute;

const RouteInfo = styled.div`
  background-color: #fff;
  margin-top: 20px;
  border: 1px solid #dfe8ed;
  border-radius: 8px;
  padding: 30px;
  width: 70%;
  margin: 0 auto;
  @media (max-width: 1080px) {
    width: 93%;
  }
`;
const TextAea = styled.textarea`
  width: 100%;
  padding-top: 20px;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  height: 150px;
  font-size: 18px;
  padding-left: 20px;
  border-radius: 5px;
  outline: none;
`;
const Cal = styled.div`
  position: absolute;
  right: 25px;
  cursor: pointer;
  bottom: 15px;
`;
const Button = styled.div`
  width: 170px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.cancel {
    background-color: #fff;
    border: 1px solid #2382d4;
    color: #2382d4;
    margin-right: 20px;
  }
`;
const SubmitButton = styled.input`
  width: 170px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2382d4;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.cancel {
    background-color: #fff;
    border: 1px solid #2382d4;
    color: #2382d4;
    margin-right: 20px;
  }
`;
const Form = styled.form``;
const ErrorMessage = styled.span`
  color: red;
  margin-bottom: 5px;
  text-align: right;
  width: 100%;
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
`;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const CoverInfo = styled.div``;
const General = styled.h3`
  color: #0a0a0a;
  position: relative;
  overflow: hidden;
  font-size: 22px;
  margin-bottom: 30px;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 100px;
    background-color: #ebebeb;
    bottom: 10px;
  }
  &.veh {
    margin-top: 30px;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 260px;
      bottom: 10px;
      background-color: #ebebeb;
    }
  }
`;
const CoverForm = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;
const CoverIn = styled.div`
  width: 50%;
  margin-right: 20px;
  margin-bottom: 30px;
  &:nth-child(even) {
    margin-right: 0;
  }
  position: relative;
  &.add {
    width: 100%;
  }
`;
const Label = styled.h3`
  color: #747474;
  font-family: "inter_regular";
  margin-bottom: 10px;
`;
const Input = styled.input`
  width: 100%;
  background-color: #f4f5f8;
  border: 1px solid #dfe8ed;
  height: 60px;
  font-size: 18px;
  padding-left: 20px;
  border-radius: 5px;
  &.auto {
    color: #adadad;
  }
`;
const Title = styled.h2`
  color: #0a0a0a;
  margin-bottom: 20px;
  font-size: 26px;
  width: 70%;
  margin: 0 auto;
  padding: 10px 0;
  @media (max-width: 1080px) {
    width: 93%;
  }
`;
